import React, { useState, useEffect } from "react";
import Global from '../../APIConfig';
import  axios from "axios";
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { Bar,Line,Pie } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ListLoader from "../Loaders/ListLoader";
Chart.register(...registerables)

function TopShippingChart(){
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [services, setservices] = useState('');
    const [shipment, setshipment] = useState('');
    
    useEffect(()=>{
        Get30DaysTop5City();
    },[]);
    
    const Get30DaysTop5City=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysTopService',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setservices(data.services);
                setshipment(data.shipments);
                
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const state = {
        labels:services,
        datasets: [
          {
            label: services,
            backgroundColor: [
                '#dc3545',
                '#fd7e14',
                '#6610f2'
              ],
            borderColor:[
                '#dc3545',
                '#fd7e14',
                '#6610f2'
              ],
            borderWidth: 2,
            data:shipment,
          },
        ],
      }
    return (
        <div>
            {Loading==true ? <ListLoader/> :
          <Pie
          data={state}
          options={{
            title: {
              display: true,
              text: 'Class strength',
              fontSize: 20,
            },
            legend: {
              display: true,
              position: 'right',
            },
          }}
        />
          }
        </div>
      )
}

export default TopShippingChart;
  
