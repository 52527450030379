import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import FinanceLeftPanel from "../include/FinanceLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import Select from 'react-select';
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ClientSalesReport(){
    let navigate = useNavigate();
    

    useEffect(()=>{
    },[]);

   
    return(
        <>
        <FinanceLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            <h2>Coming soon</h2>
            <Footer/>
        </main>
        </>

    );
}
export default ClientSalesReport;