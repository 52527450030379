import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate } from 'react-router-dom';

function AgentAdd(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [Type, setType] = useState('');
    const [Invoice, setInvoice] = useState('');
    const [CreditLimit, setCreditLimit] = useState('0.00');
    const [Name, setName] = useState('');
    const [Company, setCompany] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Username, setUsername] = useState('');
    const [Zip, setZip] = useState('');
    const [Country, setCountry] = useState('');
    const [City, setCity] = useState('');
    const [Hub, setHub] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPass, setConfirmPass] = useState('');
    const [Address, setAddress] = useState('');
    const [PanNo, setPanNo] = useState('');
    const [PanImage, setPanImage] = useState('');
    const [AcName, setAcName] = useState('');
    const [AcNO, setAcNO] = useState('');
    const [Ifsc, setIfsc] = useState('');
    const [PassbookImage, setPassbookImage] = useState('');
    const [KycType, setKycType] = useState('');
    const [IdNo, setIdNo] = useState('');
    const [IdDoc, setIdDoc] = useState('');
    const [Countrylist, setCountrylist] = useState([]);
    const [Citylist, setCitylist] = useState([]);
    const [Hublist, setHublist] = useState([]);
    const [loading, setloading] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState('');

    useEffect(()=>{
        GetCountrylist();
        GetdefaultCountry();
    },[]);
    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const GetdefaultCountry=()=>
    {
        axios({
            method: 'post',
            url: Global.baseUrl+'defaultcountryname',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDefaultCountry(data);
                setCountry(data);
                GetCityList(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const handleCountryChange = (val) => {
        setCountry(val);
        GetCityList(val);
    };
    const handleCityChange = (val) => {
        setCity(val);
        GetHubList(val);
    };
    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const handleSubmit=()=>{
        if(Type==""){
            toast.warning('Agent Type is Required');
        }
        else if(Invoice==""){
            toast.warning('Agent Invoice is Required');
        }
        else if(Name==""){
            toast.warning('Agent Name is Required');
        }
        else if(Company==""){
            toast.warning('Agent Company Name is Required');
        }
        else if(Phone==""){
            toast.warning('Agent Phone NO. is Required');
        }
        else if(Email==""){
            toast.warning('Agent Email is Required');
        }
        else if(Zip==""){
            toast.warning('Postal Code is Required');
        }
        else if(Country==""){
            toast.warning('Please Select Country');
        }
        else if(City==""){
            toast.warning('Please Select City');
        }
        else if(Hub==""){
            toast.warning('Please Select Hub');
        }
        else if(Password==""){
            toast.warning('Password is Required');
        }
        else if(ConfirmPass==""){
            toast.warning('Confirm Password is Required');
        }
        else if(Password!=ConfirmPass){
            toast.warning('Password and Confirm Password Must Be Same');
        }
        else if(Address==""){
            toast.warning('Address is Required');
        }
        else{
            setloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'AgentAdd',
                headers:headers2,
                data: {
                    Type:Type,
                    Invoice:Invoice,
                    CreditLimit:CreditLimit,
                    Name:Name,
                    Company:Company,
                    Phone:Phone,
                    Email:Email,
                    Username:Username,
                    Zip:Zip,
                    Country:Country,
                    City:City,
                    Hub:Hub,
                    Password:Password,
                    ConfirmPass:ConfirmPass,
                    Address:Address,
                    PanNo:PanNo,
                    PanImage:PanImage,
                    AcName:AcName,
                    AcNO:AcNO,
                    Ifsc:Ifsc,
                    PassbookImage:PassbookImage,
                    KycType:KycType,
                    IdNo:IdNo,
                    IdDoc:IdDoc
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/AgentList/All');
                      }, 1000);
                }else{
                    toast.warning(response.data.message);
                }
                setloading(false);
              })
              .catch(function (error) {
                setloading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    // logout();
                    // navigate('/');
                    // window.location.reload();
                }
              });
        }
    }
    return(
        <>
            <UserLeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                <section className="left_sub_menu">
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                            <i className="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Agent</li>
                                    </ol>
                                </nav>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                        <i className="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div className="ps-2 ">
                                        <h2 className="h4">Add Agent</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                            <h6>Add Agent</h6>
                                <div className="row">
                                    <div className="col-12 mb-3 mt-4">
                                        <strong>Agent Basic Details</strong>
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">Agent Type: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>setType(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    <option value="Y">Credit Agent</option>
                                                    <option value="N">Non Credit Agent</option>
                                                    <option value="U">Contract Agent</option>
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">Agent Invoice: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>setInvoice(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    <option value="1 Month">1 Month</option>
                                                    <option value="15 days">15 Days</option>
                                                    <option value="7 days">7 Days</option>
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Credit Limit</label>
                                                <input type="text" className="form-control" onChange={(e)=>setCreditLimit(e.target.value)} placeholder="Please Enter Agent Credit Limit" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Name: <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Please Enter Agent Name" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Company Name: <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>setCompany(e.target.value)} placeholder="Please Enter Agent Company Name" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Phone: <span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" onChange={(e)=>setPhone(e.target.value)} placeholder="Please Enter Agent Phone No." />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Email: <span className="text-danger">*</span></label>
                                                <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Please Enter Agent Email" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Postal Code: <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>setZip(e.target.value)} placeholder="Please Enter Agent Postal Code" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">Country: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>handleCountryChange(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    {Countrylist.map((list)=>{
                                                        return (
                                                            <option value={list.country} selected={list.country == defaultCountry}>{list.country}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">City: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>handleCityChange(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    {Citylist.map((list)=>{
                                                        return (
                                                            <option value={list.value}>{list.label}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">Hub: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>setHub(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    {Hublist.map((list)=>{
                                                        return (
                                                            <option value={list.value}>{list.label}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Please enter password" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" onChange={(e)=>setConfirmPass(e.target.value)} placeholder="Please confirm Password" />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label for="" className="form-label">Address: <span className="text-danger">*</span></label>
                                                <textarea className="form-control" rows={5} onChange={(e)=>setAddress(e.target.value)} placeholder="Please Enter Agent Address" ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="col-12 mb-3 mt-4">
                                        <strong>Agent KYC Details</strong>
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">PAN No:</label>
                                                <input type="text" className="form-control" onChange={(e)=>setPanNo(e.target.value)} placeholder="Please Enter PAN No" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">PAN Card Image:</label>
                                                <input type="file" className="form-control" accept="image/png,image/jpg,image/jpeg" onChange={(e)=>setPanNo(e.target.files[0])} placeholder="Please Enter Card Image" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Account Holder Name:</label>
                                                <input type="text" className="form-control" onChange={(e)=>setAcName(e.target.value)} placeholder="Please Enter Account Holder Name" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Account No:</label>
                                                <input type="text" className="form-control" onChange={(e)=>setAcNO(e.target.value)} placeholder="Please Enter Account No" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">IFSC Code:</label>
                                                <input type="text" className="form-control" onChange={(e)=>setIfsc(e.target.value)} placeholder="Please Enter IFSC Code" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Bank Passbook Image:</label>
                                                <input type="file" className="form-control" accept="image/png,image/jpg,image/jpeg" onChange={(e)=>setPassbookImage(e.target.files[0])} placeholder="Please Enter Bank Passbook Image" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">KYC With:</label></div>
                                                <select className="form-select" onChange={(e)=>setKycType(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    <option value="Driving License">Driving License</option>
                                                    <option value="Passport">Passport</option>
                                                    <option value="Aadhar Card">Aadhar Card</option>
                                                    <option value="Voter ID">Voter ID</option>
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">ID Proof No:</label>
                                                <input type="text" className="form-control" onChange={(e)=>setIdNo(e.target.value)} placeholder="Please Enter ID Proof No" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">ID Proof Document: </label>
                                                <input type="file" className="form-control" accept="image/png,image/jpg,image/jpeg" onChange={(e)=>setIdDoc(e.target.files[0])} placeholder="Please Enter ID Proof Document" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Submit</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
                <Footer/>
            </main>
        </>
    )
}
export default AgentAdd;