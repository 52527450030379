import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function HubList(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [list, setList] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [SearchType, setSearchType] = useState('Any');
    const [SearchVal, setSearchVal] = useState('');
    useEffect(()=>{
        GetHubList();
    },[]);
    const GetHubList=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHub',
            headers:headers1,
            data: {
                SearchType:SearchType,
                SearchVal:SearchVal
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
            }
            else
            {
                setList();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    const SearchCust=(e)=>{
        setSearchVal(e);
        GetHubList();
    }
    const handleStatus=(index)=>{
        const newList = [...list];
        let newValue="";
        if(newList[index]['status']=='Y'){
            newValue='N';
        }else{
            newValue='Y';
        }
        newList[index]['status'] = newValue;
        setList(newList);

        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateHubStatus',
            headers:headers1,
            data: {
                id:newList[index]['id'],
                value:newValue
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success(response.data.message);
            }
            else
            {
                toast.error(response.data.message);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }
    const handleDelete=(id)=>{
        if(window.confirm("Do You Really Want To Delete")){
            axios({
                method: 'post',
                url: Global.baseUrl+'DeleteHub',
                headers:headers1,
                data: {
                    id:id,
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                }
                else
                {
                    toast.error(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    // logout();
                    // navigate('/');
                    // window.location.reload();
                }
                // console.log(error);
              });
        }
        GetHubList();
    }
    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>:
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Hub</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button">
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">All Hubs</h2>
                        </div>
                    </div>
                </div>
                
                <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="dropdown">
                        <button class="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div class="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <Link class="dropdown-item d-flex align-items-center" to={`/HubAdd`}>
                                <i class="bx bx-cube text-primary-400 me-2 fs-5"></i>
                                Add New Hub
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md"></div>
                <div class="col-12 col-md-4">
                    <div class="table-settings shipment_list" id="">
                        <div class="row align-items-start g-2" >
                            <div class="col col-md ">
                                <div class="input-group ">
                                <select className="form-select" onChange={(e)=>setSearchType(e.target.value)}>
                                      <option value='Any'>Any</option>
                                      <option value='Name'>Name</option>
                                      <option value='Email'>Email</option>
                                      <option value='Phone'>Mobile Number</option>
                                    </select>
                                    <input type="text" class="form-control w-50" placeholder="Search Hub" onChange={(e)=>SearchCust(e.target.value)} />
                                    <span class="input-group-text">
                                        <i class="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="data_table">
                <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="border-gray-200">Hub Account #</th>
                                <th class="border-gray-200">Hub name</th>
                                <th class="border-gray-200">User Name</th>
                                <th class="border-gray-200">Mobile No.</th>
                                <th class="border-gray-200">Email</th>
                                <th class="border-gray-200">Status</th>
                                <th class="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {Subloading==true && <ListLoader/>}
                        {list!=undefined && list.length>0 && Subloading==false ?
                        <tbody>
                            {list.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                <Link to={`/EditHub/${item.unique_id}`} class="fw-bold">{item.unique_id}</Link>
                                                <CopyToClipboard text={item.unique_id} onCopy={()=>{toast.success(item.unique_id+' Copied To Clipboard')}}>
                                                    <i className="bx bx-copy"></i>
                                                </CopyToClipboard>
                                            </div>
                                        </td>
                                        <td><span class="fw-normal text-capitalize">{item.hub_name}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.name}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.mobile}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.email}</span></td>
                                        <td>
                                            <div class="form-check form-switch  mb-2">
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={item.status=='Y'?true:false} onChange={()=>handleStatus(index)} />
                                            </div>
                                        </td>
                                        <td>
                                            <a href={`EditHub/${item.unique_id}`} class="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Edit"><i class="bx bx-edit"></i></a>
                                            <button onClick={()=>handleDelete(item.id)} type="button" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Delete"><i className="bx bx-x"></i></button>
                                        </td>
                                    </tr>
                                );
                            })}                     
                        </tbody>
                        :
                        <tbody><tr><td colSpan={8} className="text-center text-danger"> Record Not Found </td></tr></tbody>
                        }
                    </table>
                    
                </div>
            </section>
            </>
            }
            <Footer/>
        </main>
        </>

    );
}
export default HubList;