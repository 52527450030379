import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/MasterLeftPanel';
import { useNavigate } from 'react-router-dom';
function SystemSettingDashBoard() {
    let navigate = useNavigate();

    useEffect(() => {
      }, []);
  return (
    <>
        <LeftPanel/>
        <main className='home-section'>
        <Header/>
        <div className="overlay"></div>
        <div className='col-12 mt-4'><h2>System Setting Dashboard</h2></div>
        <div class="row dash_state_box mt-4">
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Settings">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Setting</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-package"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ServiceAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Service</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ServiceList/All">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Price Master</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-dollar"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ServiceList/International">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">International Service</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-dollar"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ServiceList/Domestic">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Domestic Service</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-dollar"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ZoneAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Zone</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/zoneList">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Zone List</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map-alt"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ZoneSetList/Domestic">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Domestic Zone</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map-alt"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ZoneSetList/International">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">International Zone</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map-alt"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ZoneSetDomestic">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Assign City</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map-alt"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ZoneSetInternational">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Assign Country </h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map-alt"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/RouteAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Route</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Routelist">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Route List</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-flag"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/CountryAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Location</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Countrylist">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Country</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/WarehouseScanner/Pickup">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Pincode</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Pincodelist">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Pincode List</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-map-pin"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <Footer/>
            </main>
    </>
  );
}

export default SystemSettingDashBoard;