import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function ZoneSetInternational(){
    const navigate = useNavigate();
    const [countrylist, setCountrylist] = useState([]);
    const [zonelist, setZonelist] = useState([]);
    const [countrysearch, setCountrysearch] = useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        GetCountrylist();
        GetZonelist();
    },[]);

    const GetCountrylist=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetCountrylistbysearchvalue',
            headers:headers1,
            data: {
                // countryname: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
            else
            {
                setCountrylist();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

    const GetZonelist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetZonelistbyzonetype',
            headers:headers1,
            data: {
                type:'International'
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setZonelist(data);
            }
            else
            {
                setZonelist();
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const handlezoneset=(countryid,zonevalue)=>{
        // setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'SetZoneInternational',
            headers:headers1,
            data: {
                countryid: countryid,
                zonevalue: zonevalue
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success(response.data.message);
            }
            else
            {
                toast.warn(response.data.message);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
   
    const handleChange = (event) => {
        if(event.target.value!=""){
        setCountrysearch(event.target.value);
        const filtered = countrylist.filter(item =>
            item.country.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setCountrylist(filtered);
        }else{
            GetCountrylist();
        }
      };



    return(
        <>
        <MasterLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Zone Master</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Assign Country to a International Zone </h2>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row py-3">
                <div class="col col-md ">
                    <div class="input-group input-group-lg ">
                        <input type="text" class="form-control w-50" placeholder="Search Country by name" onChange={(e)=>handleChange(e)} />
                        <span class="input-group-text">
                            <i class="bx bx-search"></i>
                        </span>
                    </div>
                </div>
            </div>


            <div class="card">
              <div class="card-body">
                <div class="row zone_selection_bx">
                {Subloading==true && <ListLoader/>}
                    {countrylist != undefined && Subloading==false ?
                    <>
                        {countrylist.map((cl)=>{
                        return(
                            <div class="col-12 col-md-4">
                                <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <label for="" class="form-label">Country</label>
                                            <h6 class="mt-2">{cl.country}</h6>
                                        </div>
                                        <div class="col-12 col-md">
                                            <label for="" class="form-label">Assign Zone</label>
                                            <select class="form-select" onChange={(e)=>handlezoneset(cl.id,e.target.value)}>
                                                <option>-- Select Zone --</option>
                                                {zonelist!= undefined &&
                                                    zonelist.map((zl)=>{
                                                        return(
                                                            <option value={zl.id} selected={zl.id == cl.country_zone_id? "selected":""}>{zl.name}</option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        );
                        })}
                    </>
                    :
                    ""
                    }
                </div>  
              </div>
            </div>
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default ZoneSetInternational;