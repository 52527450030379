import { useState,useEffect } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Global from '../../APIConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from '../../AuthUser';
import SpinnerLoader from '../Loaders/Spinner';

function Login() {
  let navigate = useNavigate();
  const{setToken,getToken}=AuthUser();
  const [logo, setLogo] = useState('');
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [loading, setloading] = useState(false);

  useEffect(()=>{
    systemdefaultdata();
    const balls = document.querySelectorAll('.ball');

    balls.forEach(ball => {
      const randomDuration = Math.random() * 10 + 50; // Random duration between 5 and 15 seconds
      const randomDirectionX = Math.random() * 2 - 1; // Random direction between -1 and 1
      const randomDirectionY = Math.random() * 2 - 1; // Random direction between -1 and 1
      const scaleMatch = ball.style.transform.match(/scale\((.*?)\)/);
      const scale = scaleMatch ? scaleMatch[1] : 1; // Default scale to 1 if not found

      ball.style.setProperty('--animation-duration', `${randomDuration}s`);
      ball.style.setProperty('--directionX', randomDirectionX);
      ball.style.setProperty('--directionY', randomDirectionY);
      ball.style.setProperty('--scale', scale);
    });
  },[]);

  const systemdefaultdata = () =>
  {
    axios({
      method: 'post',
      url: Global.baseUrl+'systemdefaultlogo',
      data: {
      }
    })
    .then(function (response) {
      if(response.data.status == 200)
      {
        setLogo(response.data.value);
      }
      else
      {
        setloading(false);
        setLogo('');
      }
    })
    .catch(function (error) {
      setloading(false);
      console.log(error);
    });
  }
  
  const handleSubmit = () => {
    if (Email=="" || Email==undefined) {
      toast.error("Please Enter A Valid Email Address");
    }
    else if (Password=="" || Password==undefined) {
      toast.error("Please Enter A Valid Password");
    }
    else
    {
      setloading(true);
      axios({
        method: 'post',
        url: Global.baseUrl+'login',
        data: {
          email: Email,
          password: Password
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
          if(response.data.user!=undefined){
            let res = setToken(response.data.user,response.data.authorization.token);
            if(res==1){
              setloading(false);
              toast.success(response.data.message);
              localStorage.setItem('selectedoption', JSON.stringify('Master Dashbaord'));
              navigate('/Admin-Dash');
              window.location.reload();
            }else{
              setloading(false);
              toast.error("Something Went Wrong!!");
            }
          }else{
            setloading(false);
            toast.error(response.data.message);
          }
        }
        else
        {
          setloading(false);
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setloading(false);
        console.log(error);
      });
    }
  };
  

  return (
    <>
    <section className="section-balls">
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "28%", "transform": "scale(0.384616)", "width": "0.503075px", "height": "0.503075px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "55%", "top": "76%", "transform": "scale(0.547928)","width": "2.61835px", "height": "2.61835px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "84%", "top": "99%", "transform": "scale(0.900603)" ,"width": "0.0499738px","height": "0.0499738px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "5%", "top": "1%", "transform": "scale(0.146395)" ,"width": "2.35144px", "height": "2.35144px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "85%", "transform": "scale(0.334319)" ,"width": "9.06381px", "height": "9.06381px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "86%", "top": "52%", "transform": "scale(0.155746)" ,"width": "3.9894px", "height": "3.9894px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "65%", "top": "27%", "transform": "scale(0.86821)" ,"width": "5.35571px", "height": "5.35571px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "91%", "transform": "scale(0.953563)" ,"width": "9.97993px", "height": "9.97993px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "66%", "top": "38%", "transform": "scale(0.644339)" ,"width": "0.0201365px", "height": "0.0201365px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "64%", "top": "94%", "transform": "scale(0.583572)" ,"width": "2.17545px", "height": "2.17545px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "65%", "top": "87%", "transform": "scale(0.983493)" ,"width": "3.17685px", "height": "3.17685px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "18%", "top": "6%", "transform": "scale(0.719261)" ,"width": "9.21264px", "height": "9.21264px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "81%", "top": "61%", "transform": "scale(0.112961)" ,"width": "3.48616px", "height": "3.48616px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "23%", "top": "38%", "transform": "scale(0.0822419)" ,"width": "3.97773px", "height": "3.97773px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "60%", "top": "29%", "transform": "scale(0.973699)" ,"width": "9.32737px", "height": "9.32737px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "51%", "transform": "scale(0.672809)" ,"width": "9.91568px", "height": "9.91568px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "34%", "top": "61%", "transform": "scale(0.388619)" ,"width": "1.06271px", "height": "1.06271px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "73%", "top": "8%", "transform": "scale(0.326254)" ,"width": "4.81457px", "height": "4.81457px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "97%", "top": "32%", "transform": "scale(0.831007)" ,"width": "0.166249px", "height": "0.166249px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "41%", "top": "52%", "transform": "scale(0.618639)" ,"width": "3.96113px", "height": "3.96113px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "41%", "top": "26%", "transform": "scale(0.357081)" ,"width": "9.74586px", "height": "9.74586px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "18%", "top": "79%", "transform": "scale(0.901469)" ,"width": "6.06944px", "height": "6.06944px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "88%", "top": "56%", "transform": "scale(0.486343)" ,"width": "8.82203px", "height": "8.82203px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "95%", "transform": "scale(0.827023)" ,"width": "3.97533px", "height": "3.97533px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "98%", "top": "86%", "transform": "scale(0.711744)" ,"width": "1.0264px", "height": "1.0264px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "78%", "top": "75%", "transform": "scale(0.566407)" ,"width": "9.63597px", "height": "9.63597px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "65%", "transform": "scale(0.506611)" ,"width": "2.78299px", "height": "2.78299px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "11%", "top": "99%", "transform": "scale(0.970328)" ,"width": "6.045px", "height": "6.045px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "89%", "top": "69%", "transform": "scale(0.101904)" ,"width": "3.97999px", "height": "3.97999px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "84%", "top": "86%", "transform": "scale(0.801288)" ,"width": "2.53842px", "height": "2.53842px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "28%", "transform": "scale(0.384616)" ,"width": "0.503075px", "height": "0.503075px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "55%", "top": "76%", "transform": "scale(0.547928)","width": "2.61835px", "height": "2.61835px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "84%", "top": "99%", "transform": "scale(0.900603)" ,"width": "0.0499738px","height": "0.0499738px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "5%", "top": "1%", "transform": "scale(0.146395)" ,"width": "12.35144px", "height": "12.35144px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "85%", "transform": "scale(0.334319)" ,"width": "9.06381px", "height": "9.06381px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "86%", "top": "52%", "transform": "scale(0.155746)" ,"width": "3.9894px", "height": "3.9894px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "65%", "top": "27%", "transform": "scale(0.86821)" ,"width": "5.35571px", "height": "5.35571px",}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "91%", "transform": "scale(0.953563)" ,"width": "9.97993px", "height": "9.97993px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "66%", "top": "38%", "transform": "scale(0.644339)" ,"width": "0.0201365px", "height": "0.0201365px",}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "64%", "top": "94%", "transform": "scale(0.583572)" ,"width": "2.17545px", "height": "2.17545px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "65%", "top": "87%", "transform": "scale(0.983493)" ,"width": "3.17685px", "height": "3.17685px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "18%", "top": "6%", "transform": "scale(0.719261)" ,"width": "19.21264px", "height": "19.21264px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "81%", "top": "61%", "transform": "scale(0.112961)" ,"width": "3.48616px", "height": "3.48616px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "23%", "top": "38%", "transform": "scale(0.0822419)" ,"width": "3.97773px", "height": "3.97773px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "60%", "top": "29%", "transform": "scale(0.973699)" ,"width": "9.32737px", "height": "9.32737px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "51%", "transform": "scale(0.672809)" ,"width": "9.91568px", "height": "9.91568px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "34%", "top": "61%", "transform": "scale(0.388619)" ,"width": "1.06271px", "height": "1.06271px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "73%", "top": "8%", "transform": "scale(0.326254)" ,"width": "4.81457px", "height": "4.81457px",}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "97%", "top": "32%", "transform": "scale(0.831007)" ,"width": "0.166249px", "height": "0.166249px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "41%", "top": "52%", "transform": "scale(0.618639)" ,"width": "13.96113px", "height": "13.96113px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "41%", "top": "26%", "transform": "scale(0.357081)" ,"width": "9.74586px", "height": "9.74586px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "18%", "top": "79%", "transform": "scale(0.901469)" ,"width": "6.06944px", "height": "6.06944px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "88%", "top": "56%", "transform": "scale(0.486343)" ,"width": "8.82203px", "height": "8.82203px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "95%", "transform": "scale(0.827023)" ,"width": "6.97533px", "height": "6.97533px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "98%", "top": "86%", "transform": "scale(0.711744)" ,"width": "1.0264px", "height": "1.0264px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "78%", "top": "75%", "transform": "scale(0.566407)" ,"width": "9.63597px", "height": "9.63597px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "65%", "transform": "scale(0.506611)" ,"width": "2.78299px", "height": "2.78299px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "11%", "top": "99%", "transform": "scale(0.970328)" ,"width": "6.045px", "height": "6.045px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "89%", "top": "69%", "transform": "scale(0.101904)" ,"width": "3.97999px", "height": "3.97999px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "84%", "top": "86%", "transform": "scale(0.801288)" ,"width": "2.53842px", "height": "2.53842px",}}></div>
    </section>
    <section className=" register_box_00 login-22-bodycolor">
    <ToastContainer/>
      {loading==true && <SpinnerLoader/> }
      <nav className="navbar navbar-expand-sm pt-4">
          <div className="container">
            <a className="navbar-brand" href="javascript:void(0)"><img src={`${Global.logoUrl}${logo}`} className="img-fluid" /></a><button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="mynavbar">
                <ul className="navbar-nav me-auto"></ul>
                
            </div>
          </div>
      </nav>
      <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card shadow-sm">
                  <div className="card-body login-box">
                    <h4>Login</h4>
                    <small>Hey, Enter your details to sign into your account</small>
                    <form autocomplete="off">
                        <div className="row mt-4">
                          <div className="col-12 mb-3">
                              <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-outlined">email</i></span>
                                <input type="text" className="form-control form-control-lg" placeholder="Enter your email address" name="email" autocomplete="new-email" onChange={(e)=>setEmail(e.target.value)} />
                              </div>
                          </div>
                          <div className="col-12 mb-3">
                              <div className="input-group">
                                <span className="input-group-text"><i className="material-icons-outlined">vpn_key</i></span>
                                <input type="password" className="form-control form-control-lg" placeholder="Enter your password here" name="password" autocomplete="new-password" onChange={(e)=>setPassword(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className="d-grid py-4">
                          <button type="button" className="btn btn-primary btn-lg btn-block" onClick={handleSubmit}>Sign in</button>
                        </div>
                    </form>
                  </div>
              </div>
              <div>
                  <ul>
                    <li><a href="terms-condition" target="_blank">Terms &amp; Condition</a></li>
                    <li><a href="privacy-policy" target="_blank">Privacy Policy</a></li>
                    <li><a href="cancellation-policy" target="_blank">Cancellation/Refund Policy</a></li>
                    <li><a href="contact-us" target="_blank">Support</a></li>
                  </ul>
              </div>
            </div>
        </div>
      </div>
    </section>
</>
  );
}

export default Login;