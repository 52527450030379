import ContentLoader, { Facebook } from 'react-content-loader'

const MainLoader = () => {
    return (
    <ContentLoader 
    speed={2}
    foregroundColor={'#999'}
    viewBox="0 0 510 200">
        <rect x="10" y="10" rx="4" ry="4" width="500" height="20" />
        <rect x="10" y="50" rx="5" ry="5" width="100" height="30" />
        <rect x="115" y="50" rx="5" ry="5" width="100" height="30" />
        <rect x="360" y="90" rx="5" ry="5" width="150" height="20" />
        <rect x="10" y="120" rx="4" ry="4" width="500" height="20" />
        <rect x="10" y="150" rx="3" ry="3" width="500" height="20" />
        <rect x="10" y="180" rx="3" ry="3" width="500" height="20" />
    </ContentLoader>
    );
  };

  export default MainLoader;