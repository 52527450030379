import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import AuthUser from "../../AuthUser";
import ListLoader from "../Loaders/ListLoader";
import CopyToClipboard from 'react-copy-to-clipboard';


function CustomerList(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const {type} = useParams();
    const [list, setList] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [SearchType, setSearchType] = useState('Any');
    const [SearchVal, setSearchVal] = useState('');
    useEffect(()=>{
        GetCustomerList();
    },[type]);
    const GetCustomerList=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchCustomers',
            headers:headers1,
            data: {
                Ctype:type,
                SearchType:SearchType,
                SearchVal:SearchVal
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
            }
            else
            {
                setList();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    const SearchCust=(e)=>{
        setSearchVal(e);
        GetCustomerList();
    }
    const getActive=(val)=>{
        if(val==type){
            return "active";
        }else{
            return "";
        }
    }
    const handleStatus=(index)=>{
        setSubloading(true);
        const newList = [...list];
        let newValue="";
        if(newList[index]['status']=='Y'){
            newValue='N';
        }else{
            newValue='Y';
        }
        newList[index]['status'] = newValue;
        setList(newList);

        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateCustomersStatus',
            headers:headers1,
            data: {
                id:newList[index]['id'],
                value:newValue
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                setSubloading(false);
                toast.success(response.data.message);
            }
            else
            {
                setSubloading(false);
                toast.error(response.data.message);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }
    const handleDeveloperOnoff=(index)=>{
        setSubloading(true);
        const newList = [...list];
        let newValue="";
        if(newList[index]['developer_onoff']=='Y'){
            newValue='N';
        }else{
            newValue='Y';
        }
        newList[index]['developer_onoff'] = newValue;
        setList(newList);

        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateCustomersDeveloperonoff',
            headers:headers1,
            data: {
                id:newList[index]['id'],
                value:newValue
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                setSubloading(false);
                toast.success(response.data.message);
            }
            else
            {
                setSubloading(false);
                toast.error(response.data.message);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }
    const handleDelete=(id)=>{
        if(window.confirm("Do You Really Want To Delete")){
            setSubloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'DeleteCustomers',
                headers:headers1,
                data: {
                    id:id,
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    setSubloading(false);
                    toast.success(response.data.message);
                }
                else
                {
                    setSubloading(false);
                    toast.error(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setSubloading(false);
                // console.log(error);
              });
        }
        GetCustomerList();
    }
    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
        {Loading==true?<MainLoader/>:
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <Link to={`/Admin-Dash`}>
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Customers</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={()=>navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">All Customers</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="dropdown">
                        <button className="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <Link className="dropdown-item d-flex align-items-center" to={`/CustomerAdd`}>
                                <i className="bx bx-cube text-primary-400 me-2 fs-5"></i>
                                Add New Customer
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md">
                    <div className="custom_nav_data_table">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <Link className={`nav-link ${getActive('All')}`} to="/CustomerList/All">All</Link>
                            </li>
                            <li className="nav-item">
                              <Link className={`nav-link ${getActive('C')}`} to="/CustomerList/C">Credit Customers</Link>
                            </li>
                            <li className="nav-item">
                              <Link className={`nav-link ${getActive('NC')}`} to="/CustomerList/NC">Non Credit Customres</Link>
                            </li>
                            <li className="nav-item">
                              <Link className={`nav-link ${getActive('U')}`} to="/CustomerList/U">Contract Customers</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="table-settings shipment_list" id="">
                        <div className="row align-items-start g-2" >
                            <div className="col col-md ">
                                <div className="input-group ">
                                    <select className="form-select" onChange={(e)=>setSearchType(e.target.value)}>
                                      <option value='Any'>Any</option>
                                      <option value='Name'>Name</option>
                                      <option value='Email'>Email</option>
                                      <option value='Phone'>Phone Number</option>
                                    </select>
                                    <input type="text" className="form-control w-50" placeholder="Search Customers" onChange={(e)=>SearchCust(e.target.value)} />
                                    <span className="input-group-text">
                                        <i className="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            

            

            

            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    
                    <table className="table table-hover">
                        <thead>
                        
                            <tr>
                                <th className="border-gray-200" style={{"width":"20px"}}>
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="" />
                                </th>						
                                <th className="border-gray-200">Customer Account #</th>
                                <th className="border-gray-200">Company name</th>
                                <th className="border-gray-200">User Name</th>
                                <th className="border-gray-200">Phone</th>
                                <th className="border-gray-200">Email</th>
                                <th className="border-gray-200">Type</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Developer</th>
                                <th className="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {Subloading==true && <ListLoader/>}
                        {list!=undefined && Subloading==false ?
                        <tbody>
                            {list.map((cust,index)=>{
                                return(
                                <tr key={index}>
                                    <td style={{"width":"20px"}}>
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="" />
                                    </td>
                                    <td>
                                        <div>
                                            <Link to={`/CustomerDetail/${cust.uniqueid}`} className="fw-bold">{cust.uniqueid}</Link>
                                            <CopyToClipboard text={cust.uniqueid} onCopy={()=>{toast.success(cust.uniqueid+' Copied To Clipboard')}}>
                                                <i className="bx bx-copy"></i>
                                            </CopyToClipboard>
                                        </div>
                                    </td>
                                    <td><span className="fw-normal text-capitalize">{cust.company}</span></td>
                                    <td><span className="fw-normal text-capitalize">{cust.name}</span></td>
                                    <td><span className="fw-normal text-capitalize">{cust.phone}</span></td>
                                    <td><span className="fw-normal text-capitalize">{cust.email}</span></td>
                                    <td>
                                        <span className="fw-normal">
                                            {cust.cust_credit!='N'?
                                            <span className="badge bg-success">Credit</span>
                                            :
                                            <span className="badge bg-danger">Non Credit</span>
                                            }
                                        </span>
                                    </td>
                                    <td>
                                        <div className="form-check form-switch  mb-2">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={cust.status=='Y'?true:false} onChange={()=>handleStatus(index)} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check form-switch  mb-2">
                                        <input className="form-check-input" type="checkbox" role="switch" checked={cust.developer_onoff=='Y'?true:false} onChange={()=>handleDeveloperOnoff(index)} />
                                        </div>
                                    </td>
                                    <td>
                                        <Link to={`/CustomerDetail/${cust.uniqueid}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="View Profile"><i className="bx bx-user"></i></Link>
                                        <Link to={`/CustomerEdit/${cust.uniqueid}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Edit"><i className="bx bx-edit"></i></Link>
                                        <button onClick={()=>handleDelete(cust.id)} type="button" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Delete"><i className="bx bx-x"></i></button>
                                        <Link to="#" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Settings"><i className="bx bx-revision"></i></Link>
                                    </td>
                                </tr>
                            )})}                 
                        </tbody>
                        :
                        <tbody><tr><td colSpan={8}>Record Not Found</td></tr></tbody>
                        }
                    </table>
                    
                    
                </div>
            </section>
            </>
        }
            <Footer/>
        </main>
        </>

    );
}
export default CustomerList;