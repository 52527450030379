import Spinner from 'react-bootstrap/Spinner';

function ListLoader() {
  return (
    <div className='LoaderContainer2'>
        <Spinner animation="border" variant="primary" size='lg'/>
    </div>
  );
}

export default ListLoader;