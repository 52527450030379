import React,{useState} from "react";  
import Global from '../../APIConfig';
import { useNavigate,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";


function FinanceLeftPanel()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[show,SetShow]=useState('close');
    const items = JSON.parse(localStorage.getItem('user'));
    const user_privilage_string = items.user_privilage_array;
    const user_privilage_array = user_privilage_string.split(',').map(Number);
    const userlogout=()=>{
        logout();
        navigate('/');
    }
    return (
        <div className={`sidebar ${show}`}>
            <div class="logo-details">
                <div class="head_icon" onClick={()=>{SetShow(show=='close'?'show':'close')}}>
                    <i class="bx bx-menu"></i>
                </div>
                <span class="logo_name">Sky Ship</span>
            </div>
            <ul class="nav-links">
                <li>
                    <div class="iocn-link">
                        <a href="#">
                            <i class="bx bx-grid-alt"></i>
                            <span class="link_name">Dashboard</span>
                        </a>
                        <i class="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="Admin-Dash">Dashboard</a></li>
                        <li><a href="/Admin-Dash">Master Dashboard</a></li>
                    </ul>
                </li>
                {items.usertype =='AD' || user_privilage_array.includes(20) ?
                <li>
                    <div class="iocn-link" bis_skin_checked="1">
                        <a href="#">
                        <i class="bx bx-receipt"></i>
                        <span class="link_name">Reports</span>
                        </a>
                        <i class="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Reports</a></li>
                        <li><a href="/ShipmentReport">Shipment Reports</a></li>
                        <li><a href="PaymentReport">Payment Report</a></li>
                        <li><a href="ClientSalesReport">Sales By Client Report</a></li>
                        <li><a href="HubReport">Hub Report</a></li>
                        <li><a href="AgentReport">Agent Report</a></li>
                        <li><a href="DriverReport">Driver Report</a></li>
                        <li><a href="CODReport">COD Report</a></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(20) ?
                <li>
                    <div class="iocn-link" bis_skin_checked="1">
                        <a href="#">
                        <i class="bx bx-file"></i>
                        <span class="link_name">Account Management </span>
                        </a>
                        <i class="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Account Management </a></li>
                        <li><a href="/ShipmentReport">Customer Service Charged</a></li>
                        <li><a href="customer_invoice.php">Customer Cod</a></li>
                        <li><a href="customer_invoice.php">Agent A/C Management</a></li>
                        <li><a href="customer_invoice.php">Hub A/C Management</a></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(20) ?
                <li>
                    <div class="iocn-link" bis_skin_checked="1">
                        <a href="#">
                        <i class="bx bx-credit-card"></i>
                        <span class="link_name">Payment Detail </span>
                        </a>
                        <i class="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Payment Detail </a></li>
                        <li><a href="customer_invoice.php">Payment Detail</a></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(20) ?
                <li>
                    <div class="iocn-link" bis_skin_checked="1">
                        <a href="#">
                        <i class="bx bx-id-card"></i>
                        <span class="link_name">Invoice Detail </span>
                        </a>
                        <i class="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Invoice Detail </a></li>
                        <li><a href="customer_invoice.php">Quick Invoice</a></li>
                        <li><a href="customer_invoice.php">Customer Invoice</a></li>
                        <li><a href="customer_invoice.php">Agent Invoice</a></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(20) ?
                <li>
                    <div class="iocn-link" bis_skin_checked="1">
                        <a href="#">
                        <i class="fa fa-cubes" aria-hidden="true"></i>
                        <span class="link_name">Stock Management </span>
                        </a>
                        <i class="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Stock Management </a></li>
                        <li><a href="/Stocklist">Stock Management</a></li>
                    </ul>
                </li>
                :
                ""
                }
                <li>
                <div className="profile-details">
                    <div className="profile-content">
                    <img src={`${Global.logoUrl}${items.profile_image}`} alt="profileImg" />
                    </div>
                    <div className="name-job">
                    <div className="profile_name">{items.name}</div>
                    <div className="job">{items.company}</div>
                    </div>
                    <i className="bx bx-log-out" onClick={()=>userlogout()}></i>
                </div>
                </li>
            </ul>
        </div>
    );

}
export default FinanceLeftPanel;