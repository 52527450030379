import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { Link, useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import ListLoader from "../Loaders/ListLoader";

function DrsStatusUpdate(){
    let navigate = useNavigate();
    const { drsid } =useParams();
    const [drslist, setDrslist] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true); 
    const [BtnLoading, setBtnLoading] = useState(false);

    useEffect(()=>{
        GetDrsList();
    },[]);
    const GetDrsList=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'DrslistBydrsuniqueid',
            headers:headers1,
            data: {
                drs_unique_id: drsid
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var drsdata=response.data.value;
                setDrslist(drsdata);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const Submitdrs = ()=>{
        if (drslist.some(drs => drs.isChecked)) 
        { 
            setBtnLoading(true);
            axios({
            method: 'post',
            url: Global.baseUrl+'Submitdrs',
            headers:headers2,
            data: {
                id:1,
                drsData:drslist
            }
            })
            .then(function (response) {
                if(response.data.status == 200){
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/DrsList/Running');
                      }, 1000);
                }
                setBtnLoading(false);
            })
            .catch(function (error) {
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        } else {
            toast.warn("Please Select Atleast One Shipment!");
        }
    }
    const handleChange=(e)=>{
        const { value, checked } = e.target;
        if(value === 'allSelect')
        {
            let tempShipment = drslist.map((ship) => 
                { return {...ship, isChecked: checked};
            });
            setDrslist(tempShipment);
        }
        else
        {
            let tempShipment = drslist.map((ship) => 
            ship.slip_no === value ?
            {...ship, isChecked: checked}: ship );
            setDrslist(tempShipment);
        }
    }
    // const updateElement = (index) => {
    //     const newList = [...drslist];
    //     let newValue="";
    //     if(newList[index]['checked']=='Y'){
    //         newValue='N';
    //     }else{
    //         newValue='Y';
    //     }
    //     newList[index]['checked'] = newValue;
    //     setDrslist(newList);
    // };
    const updateStatus = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['drs_status'] = newValue;
        setDrslist(newList);
    };
    const updateReson = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['resion'] = newValue;
        setDrslist(newList);
    };
    const updateDate = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['delay_date'] = newValue;
        setDrslist(newList);
    };
    const updateReciever = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['delevered_to'] = newValue;
        setDrslist(newList);
    };
    const updateNumber = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['delevered_no'] = newValue;
        setDrslist(newList);
    };
    const updateReciverImage=(index,event) => {
        const newList = [...drslist];
        newList[index]['receiver_image'] = event.target.files[0];
        setDrslist(newList);
    }
    const updateSignatureImage=(index,event) => {
        const newList = [...drslist];
        newList[index]['signature_img'] = event.target.files[0];
        setDrslist(newList);
    }

    const updateStartkliometer = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['start_km'] = newValue;
        setDrslist(newList);
    };

    const updateEndkliometer = (index, newValue) => {
        const newList = [...drslist];
        newList[index]['end_km'] = newValue;
        setDrslist(newList);
    };

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Delivery Run Sheet</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">DRS #{drsid}</h2>
                        </div>
                    </div>
                </div>
                
                {/* <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="table-settings shipment_list" id="">
                        <div className="row align-items-start g-2" >
                            <div className="col col-md ">
                                <div className="input-group ">
                                    <select className="form-select">
                                      <option>Any</option>
                                      <option>AWB #</option>
                                    </select>
                                    <input type="text" className="form-control w-50" placeholder="Search " />
                                    <span className="input-group-text">
                                        <i className="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                    <input className="form-check-input" type="checkbox" onChange={handleChange} value="allSelect" checked={drslist !=undefined ? drslist.filter((drs)=> drs?.isChecked !== true).length < 1: ''} />
                                </th>						
                                <th className="border-gray-200">Select To Pickup Report</th>
                                <th className="border-gray-200">AWB#</th>
                                <th className="border-gray-200">Product Type</th>
                                <th className="border-gray-200">Address</th>
                                <th className="border-gray-200">Mode</th>
                                <th className="border-gray-200">Sender</th>
                                <th className="border-gray-200">Receiver</th>
                                <th className="border-gray-200">Kilo Meters</th>
                                <th className="border-gray-200">Received from</th>
                                <th className="border-gray-200">Receiver Image</th>
                                <th className="border-gray-200">Signature Image</th>
                            </tr>
                        </thead>
                        {Loading==true?<ListLoader/>:
                        <tbody>
                        {drslist.length>0 ?
                            <>
                            {drslist.map((drs, index)=>{ 
                                return (
                                        <tr key={index} style={drs.delivered =='Y'? {"pointer-events":"none","opacity": '0.7'}:{}}>
                                            <td style={{"width": "20px"}}>
                                                <input className="form-check-input" type="checkbox" value={drs.slip_no} checked={drs?.isChecked || false} onChange={handleChange}/>
                                            </td>
                                            <td className="">
                                                <div className="card">
                                                <div className="card-body">
                                                    <div className="form-check">
                                                        <input type="radio" name={`status_${index}`} checked={drs.drs_status=='D'? true : false} value="D" onChange={(e)=>updateStatus(index,e.target.value)}  className="form-check-input" id="" />Delivered
                                                        <label className="form-check-label" for="radio1"></label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name={`status_${index}`} checked={drs.drs_status=='DL'? true : false} value="DL" onChange={(e)=>updateStatus(index,e.target.value)}  id=""  />Reschedule
                                                        <label className="form-check-label" for="radio2"></label>
                                                    </div>
                                                    <hr />
                                                    {drs.drs_status=='DL' &&
                                                        <div>
                                                            <div>
                                                                <label for="email" className="form-label">Reschedule Reason:</label>
                                                                <select className="form-select form-select-sm" onChange={(e)=>updateReson(index,e.target.value)}>
                                                                    <option value="Weather Condition">Weather Condition</option>
                                                                    <option value="Office Out Of Work">Office Out Of Work</option>
                                                                    <option value="Strick">Strick</option>
                                                                    <option value="Road Block">Road Block</option>
                                                                    <option value="UnPaid Transport Fees">UnPaid Transport Fees</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label for="email" className="form-label">Reschedule Date:</label>
                                                                <input type="date" className="form-control" value={drs.delay_date} onChange={(e)=>updateDate(index,e.target.value)} id="text" placeholder="Enter details namet" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                </div>
                                            </td>
                                            <td><span className="fw-normal text-capitalize"><Link target="_blank" to={`/ShipmentDetails/${drs.slip_no}`}>{drs.slip_no}</Link></span></td>
                                            <td><span className="fw-normal text-capitalize">{drs.nrd}</span></td>
                                            <td><span className="fw-normal text-capitalize">{drs.sender_address}, {drs.city}, {drs.country}</span></td>
                                            <td><span className="fw-normal text-capitalize">
                                                {drs.booking_mode === 'COD' ?
                                                    <span>
                                                        <span className="badge bg-secondary">{drs.booking_mode}</span>
                                                        <br />
                                                        {drs.total_cod_amt}
                                                    </span>
                                                    :
                                                    <span className="badge bg-primary">{drs.booking_mode}</span>
                                                }
                                                </span>
                                            </td>
                                            <td><span className="fw-normal text-capitalize">{drs.sender_name}</span></td>
                                            <td><span className="fw-normal text-capitalize">{drs.reciever_name}</span></td>
                                            <td>
                                                <span className="fw-normal text-capitalize">
                                                    <input type="number" className="form-control" value={drs.start_km} onChange={(e)=>updateStartkliometer(index,e.target.value)} placeholder="Enter Start Kilo Meter" />
                                                </span>
                                                <span className="fw-normal text-capitalize">
                                                    <input type="number" className="form-control" value={drs.end_km} onChange={(e)=>updateEndkliometer(index,e.target.value)} placeholder="Enter End Kilo Meter" />
                                                </span>
                                            </td>
                                            <td>
                                                {drs.drs_status=='D' &&
                                                <>
                                                    <span className="fw-normal text-capitalize">
                                                    <input type="text" className="form-control" value={drs.delevered_to} onChange={(e)=>updateReciever(index,e.target.value)} placeholder="Enter receiver name" />
                                                    </span>
                                                    <span className="fw-normal text-capitalize">
                                                    <input type="number" className="form-control" value={drs.delevered_no} onChange={(e)=>updateNumber(index,e.target.value)} placeholder="Enter receiver number" />
                                                    </span>
                                                </>
                                                }
                                            </td>
                                            <td>
                                                {drs.drs_status=='D' &&
                                                    <span className="fw-normal text-capitalize">
                                                    <input type="file" className="form-control" id={`fileInput${index}`} onChange={(e)=>updateReciverImage(index,e)} placeholder="Select Receiver Image" />
                                                    </span>
                                                }
                                            </td>
                                            <td>
                                                {drs.drs_status=='D' &&
                                                    <span className="fw-normal text-capitalize">
                                                        <input type="file" className="form-control" onChange={(e)=>updateSignatureImage(index,e)} placeholder="Select Signature Image" />
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    );
                            }) }   
                        </>
                        :
                        <tr>
                            <td className="text-center text-danger">Record Not Found</td>
                        </tr>
                            }                     
                        </tbody>
                        }
                    </table>
                    
                </div>
            </section>
            {BtnLoading==false?
            <div className="d-grid col-md-2 mt-2">
                <button onClick={Submitdrs} className="btn btn-primary">Update DRS</button>
            </div>
            :
            <div className="d-grid col-md-2 mt-2">
                <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
            </div>
            }
            <Footer/>
        </main>
        </>

    );
}
export default DrsStatusUpdate;