import React, {useState, useEffect} from "react";
import Header from '../include/header';
import Footer from '../include/footer';
import LeftPanel from '../include/leftpanel';
import Global from "../../APIConfig";
import  axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function ScheduledPickupList(){
    let navigate = useNavigate();
    let {type} = useParams(); 
    const [list, setList] = useState([]);
    const [Loading, setLoading] = useState(false); 
    const [SubLoading, setSubLoading] = useState(false); 
    const{headers1,headers2,logout}=AuthUser();
    const [selectedfromdate, setSelectedfromdate] = useState('');
    const [selectedtodate, setSelectedtodate] = useState(getTodayDateString());
    const [selectedcustomer, setSelectedcustomer] = useState('');
    const [customerlist, setCustomerlist] = useState([]);

    useEffect(()=>{
        Getcustomerlist();
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const Getcustomerlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Warehousenamebyschedulepickup',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCustomerlist(data);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }

    const ShipmentDelete=(id)=>{
        if (window.confirm('Do You  want to delete ?')) {
            axios({
                method: 'post',
                url: Global.baseUrl+'SchedulepickupDelete',
                headers:headers1,
                data: {
                    sp_no:id
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                   toast.success(response.data.message);
                }
                else
                {
                    toast.warn(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
              Submitdata();
          } else {
            Submitdata();
          }
        
    }

    const Submitdata=()=>
        {
             axios({
                 method: 'post',
                 url: Global.baseUrl+'Schedulepickuplist',
                 headers:headers1,
                 data: {
                     Fromdate:selectedfromdate,
                     Todate:selectedtodate,
                     warehouseid:selectedcustomer,
                 }
                 })
                 .then(function (response) {
                 if(response.data.status == 200)
                 {
                     toast.success(response.data.message);
                     const data = response.data.value.data;
                     setList(data);
                 }
                 else{
                     toast.warn(response.data.message);
                     setList([]);
                 }
                 })
                 .catch(function (error) {
                 if(error.response.status==401){
                     toast.error(error.response.statusText);
                     logout();
                     navigate('/');
                     window.location.reload();
                 }
                 // console.log(error);
                 });
         }
return(
    <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Schedule Shipments</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">All Schedule Shipments</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">From Date: <span className="text-danger"></span></label></div>
                        <input type="date" className="form-control" id="text" value={selectedfromdate} placeholder="Enter city name" onChange={(e)=>setSelectedfromdate(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">To Date: <span className="text-danger"></span></label></div>
                        <input type="date" className="form-control" id="text" value={selectedtodate} placeholder="Enter city name" onChange={(e)=>setSelectedtodate(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Customer: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setSelectedcustomer(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            {customerlist.map((cust,i)=>{
                                return(
                                <option value={cust.warehouseid} key={i}>{cust.warehouseName}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="py-3">
                    <button onClick={()=>Submitdata()} className="btn btn-primary">Fetch Reports</button>
                    </div>
                </div>
              </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Schedule Unique ID</th>
                                <th className="border-gray-200">Warehoues Id</th>
                                <th className="border-gray-200">Name</th>
                                <th className="border-gray-200">Address</th>
                                <th className="border-gray-200">Pickup Date</th>
                                <th className="border-gray-200">Pickup Time</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {SubLoading==true? <tbody><tr><td colSpan={9}><ListLoader/></td></tr></tbody> : 
                            <tbody>
                                {list.length > 0 ? 
                                    <>
                                    {list.map((ship,index)=>{
                                        return(
                                    <tr key={index}>
                                        <td style={{"width": "20px"}}>
                                            <Link to={`/CustomerScheduledPickupList/${ship.sp_id}/${ship.cust_id}`} className="fw-bold">{ship.sp_id}</Link>
                                            <CopyToClipboard text={ship.sp_id} onCopy={()=>{toast.success(ship.sp_id+' Copied To Clipboard')}}><i className="bx bx-copy"></i></CopyToClipboard>
                                        </td>
                                        <td>
                                            <span className="fw-normal text-capitalize">{ship.warehouseid}</span>
                                        </td>
                                       <td>
                                        <span className="fw-normal text-capitalize">{ship.name}</span>
                                        </td>
                                        <td><span className="fw-normal text-capitalize">{ship.pickup_location}</span></td>
                                        <td><span className="fw-normal text-capitalize">{ship.pickup_date}</span></td>
                                        <td><span className="fw-normal text-capitalize">{ship.pickup_time}</span></td>
                                        <td></td>
                                        <td>
                                            <Link to={`/CustomerScheduledPickupList?sp_no=${ship.sp_id}/${ship.cust_id}`} target="_blank" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="List of Shipments"><i className="bx bx-barcode"></i></Link>
                                            <button className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Delete" onClick={()=>ShipmentDelete(ship.sp_id)}><i className="bx bx-x"></i></button>
                                        </td>
                                    </tr> 
                                    );
                                })}   
                                    </>
                                :
                                    <tr>
                                        <td colSpan="9" className="text-center">Record Not Found</td>
                                    </tr>
                                }                    
                            </tbody>
                        }
                    </table>
                    
                </div>
            </section>

            {/* <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item">
                            <a className="page-link" href="#">Previous</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">1</a>
                        </li>
                        <li className="page-item active">
                            <a className="page-link" href="#">2</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">3</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">4</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">5</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div> */}
        
            
            </>
            }
            <Footer/>
        </main>
    </>
);
}
export default ScheduledPickupList;