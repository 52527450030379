import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function ZoneSetList(){
    const navigate = useNavigate();
    const { type } = useParams();
    const [zonelist, setZonelist] = useState([]);
    const [cityZonelist, setCityZonelist] = useState([]);
    const [showpage, setShowpage] = useState('');
    const [showzonename, setShowzonename] = useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        GetZonelist();
    },[]);

    const GetZonelist=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetZonelistbyzonetype',
            headers:headers1,
            data: {
                type: type
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setZonelist(data);
                setShowpage(data[0].id)
                setShowzonename(data[0].name);
                Getcitylist(data[0].id);
            }
            else
            {
                setZonelist();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    const handlecityzone=(e)=>{
        setShowpage(e.target.value);
        setShowzonename(e.target.innerText);
        Getcitylist(e.target.value);
    }

    const Getcitylist=(val)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetselectedCitylistbyzoneid',
            headers:headers1,
            data: {
                type: type,
                zone_id: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCityZonelist(data);
            }
            else
            {
                setCityZonelist();
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    

    return(
        <>
        <MasterLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Zone Master</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">{type} Zone</h2>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-12 col-md">
                    <div class="custom_nav_data_table">
                        <ul class="nav nav-tabs">
                            {zonelist != undefined ?
                            <>
                            {zonelist.map((zl,i) =>{
                                return(
                                    <li class="nav-item" key={i}>
                                    <button class={showpage == zl.id? "nav-link active":"nav-link"} value={zl.id} onClick={handlecityzone}>{zl.name}</button>
                                    </li>
                                );
                            })}
                            </>
                            :
                            ""
                            }
                        </ul>
                    </div>
                </div>
            </div>


            <section class="mb-3 zone_citi_list">
                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="mb-3">{showzonename}</h5>
                        <ul class="city-list">
                        {Subloading==true && <ListLoader/>}
                            {cityZonelist != undefined && Subloading==false &&
                                <>
                            {cityZonelist.map((cl,i)=>{
                                return(
                                    <li>{cl.value}</li>
                                );
                            })}
                                </>
                            }
                        </ul>

                    </div>
                </div>
                
            </section>
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default ZoneSetList;