import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CopyToClipboard from 'react-copy-to-clipboard';

function RunningPickupList(){
    let navigate = useNavigate();
    const {type} = useParams();
    const [list, setList] = useState([]);
    const [runningpickup, setRunningpickup] = useState('');
    const [complatedpickup, setComplatedpickup] = useState('');
    const [searchtype, setSearchtype] = useState('Any');
    const [searchvalue, setSearchvalue] = useState('');
    const [showpage, setShowpage] = useState(type);
    const [Loading, setLoading] = useState(true); 
    const [SubLoading, setSubLoading] = useState(false);
    const{headers1,headers2,logout}=AuthUser();
    
    useEffect(()=>{
        GetRunningPickupList(type,'');
        GetTotalRunningPickup();
        GetTotalComplatedPickup();
    },[]);

    const userdata = JSON.parse(localStorage.getItem('user'));

    const GetRunningPickupList=(type,val)=>{
        setSubLoading(true);
        setShowpage(type);
        setSearchvalue(val);
        axios({
            method: 'post',
            url: Global.baseUrl+'RunningPickupList',
            headers:headers1,
            data: {
                usertype:userdata.usertype,
                cityid:userdata.branch_location,
                pagetype:type,
                searchtype: searchtype,
                searchvalue: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
                setLoading(false);
                setSubLoading(false);
            }
            else
            {
                setList();
                setLoading(false);
                setSubLoading(false);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    const GetTotalRunningPickup=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetTotalRunningPickup',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                setRunningpickup(response.data.value)
            }
            else
            {
                setRunningpickup(response.data.value);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const GetTotalComplatedPickup=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetTotalComplatedPickup',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                setComplatedpickup(response.data.value)
            }
            else
            {
                setComplatedpickup(response.data.value);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Pickup Run Sheet</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">All Pickup Run Sheet (PRS)</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="dropdown">
                        <button className="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <a className="dropdown-item d-flex align-items-center" href="/Generatepickup">
                                <i className="bx bx-upload text-primary-400 me-2 fs-5"></i>
                                Generate New PRS
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/Generatepickupschedulecustomer">
                                <i className="bx bx-upload text-primary-400 me-2 fs-5"></i>
                                Schedule New Customer PRS
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/Generatepickupscheduleagent">
                                <i className="bx bx-upload text-primary-400 me-2 fs-5"></i>
                                Schedule New Agent PRS
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
            <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <div className="card border-0 shadow">
                            <div className="card-body">
                                <div className="row d-block d-xl-flex align-items-center">
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                            <i className="bx bx-time"></i>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="d-none d-sm-block">
                                            <h2 className="h6 text-gray-400 mb-0"> Running PRS</h2>
                                            <h3 className="fw-extrabold mb-2"><a href="">{runningpickup}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="card border-0 shadow">
                            <div className="card-body">
                                <div className="row d-block d-xl-flex align-items-center">
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                            <i className="bx bx-check-double"></i>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="d-none d-sm-block">
                                            <h2 className="h6 text-gray-400 mb-0"> Completed PRS</h2>
                                            <h3 className="fw-extrabold mb-2"><a href="">{complatedpickup}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row">
                <div className="col-12 col-md">
                    <div className="custom_nav_data_table">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a href="/PickupList/Running" className={showpage =='Running'? "nav-link active":"nav-link"}>Running PRS</a>
                            </li>
                            <li className="nav-item">
                                <a href="/PickupList/Completed" className={showpage =='Completed'? "nav-link active":"nav-link"}>Completed PRS</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="prs_reschedule.php">Reschedule PRS</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="table-settings shipment_list" id="">
                        <div className="row align-items-start g-2" >
                            <div className="col col-md ">
                                <div className="input-group ">
                                    <select className="form-select" onChange={(e)=> setSearchtype(e.target.value)}>
                                      <option value="Any">Any</option>
                                      <option value="PRS">PRS #</option>
                                      <option value="AWB">AWB #</option>
                                    </select>
                                    <input type="text" className="form-control w-50" placeholder="Search " value={searchvalue} onChange={(e)=>GetRunningPickupList(showpage,e.target.value)} />
                                    <span className="input-group-text">
                                        <i className="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            

            

            

            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                {/* <th className="border-gray-200" style={{"width": "20px"}}>
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="" />
                                </th>						 */}
                                <th className="border-gray-200">PRS ID#</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Route code</th>
                                <th className="border-gray-200">City</th>
                                {/* <th className="border-gray-200">Assgied to Driver</th> */}
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {SubLoading==true? <tbody><tr><td colSpan={9}><ListLoader/></td></tr></tbody> : 
                            <tbody>
                                {list != undefined ? 
                                <>
                                {list.map((pickupdata,index)=>{
                                    return(
                                        <tr key={index}>
                                            {/* <td style={{"width": "20px"}}>
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="" />
                                            </td> */}
                                            <td>
                                                <div>
                                                    <Link to={`/PickupStatusUpdate/${pickupdata.drs_unique_id}`} className="fw-bold">{pickupdata.drs_unique_id}</Link>
                                                    <CopyToClipboard text={pickupdata.drs_unique_id} onCopy={()=>{toast.success(pickupdata.drs_unique_id+' Copied To Clipboard')}}>
                                                        <i className="bx bx-copy"></i>
                                                    </CopyToClipboard>
                                                </div>
                                            </td>
                                            <td><span className="fw-normal text-capitalize"><small>{pickupdata.drs_date}</small></span></td>
                                            <td><span className="fw-normal text-capitalize">{pickupdata.routecode}</span></td>
                                            <td><span className="fw-normal text-capitalize">{pickupdata.city}, {pickupdata.country}</span></td>
                                            {/* <td><span className="fw-normal text-capitalize">
                                                <select className="form-select">
                                                    <option value="">-- Please Select --</option>
                                                    <option selected>Vishal Sharma</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                </select>
                                            </span></td> */}
                                            <td>
                                                {showpage =='Running'?
                                                <span className="status pickup_awit"> <i className="fa fa-clock-o" aria-hidden="true"></i>   Proceed For Pickup {pickupdata.Deliveredcount}/{pickupdata.TotalPickupcount}</span>  
                                                :
                                                <span class="status completed"> <i class="bx bx-check-double"></i> Completed {pickupdata.Deliveredcount}/{pickupdata.TotalPickupcount}</span>
                                                }
                                            </td>
                                            <td>
                                                <Link to={`/PrsPrint/${pickupdata.drs_unique_id}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Print PRS"><i className="bx bx-printer"></i></Link>
                                                <Link to={`/PickupDetails/${pickupdata.drs_unique_id}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="View PRS"><i className="bx bx-file"></i></Link>
                                                {type =='Running' &&
                                                <Link to={`/PickupStatusUpdate/${pickupdata.drs_unique_id}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Update Status"><i className="bx bx-revision"></i></Link>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}   
                            </>
                                :
                                <tr>
                                    <td colSpan="7" className="text-center text-danger">Record Not Found</td>
                                </tr>
                                }                    
                            </tbody>
                        }
                    </table>
                    
                </div>
            </section>

            <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item">
                            <a className="page-link" href="#">Previous</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">1</a>
                        </li>
                        <li className="page-item active">
                            <a className="page-link" href="#">2</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">3</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">4</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">5</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div>
            </>
            }
            <Footer/>
        </main>
    </>
    );
}
export default RunningPickupList;