import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function CarrierSetting()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[Cariers,setCariers]=useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchCarierData();
    },[]);
    function FetchCarierData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchCarierData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCariers(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const handleLogo = (index, newValue) => {
        const newList = [...Cariers];
        newList[index]['company_logo'] = newValue;
        setCariers(newList);
    };
    const handleInput = (index, newValue,name) => {
        const newList = [...Cariers];
        newList[index][name] = newValue;
        setCariers(newList);
    };
    const handleSubmit=(index)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateCarrierData',
            headers:headers2,
            data: {
                "id":Cariers[index].id,
                "company_name":Cariers[index].company_name,
                "logo":Cariers[index].company_logo,
                "production_api_key":Cariers[index].production_api_key,
                "production_price_api_url":Cariers[index].production_price_api_url,
                "production_booking_api_url":Cariers[index].production_booking_api_url,
                "production_tacking_api_uri":Cariers[index].production_tacking_api_uri,
                "production_label_api_url":Cariers[index].production_label_api_url,
                "production_cancel_api_url":Cariers[index].production_cancel_api_url,
                "sendbox_api_key":Cariers[index].sendbox_api_key,
                "sendbox_price_api_url":Cariers[index].sendbox_price_api_url,
                "sendbox_booking_api_url":Cariers[index].sendbox_booking_api_url,
                "sendbox_tracking_api_url":Cariers[index].sendbox_tracking_api_url,
                "sendbox_label_api_url":Cariers[index].sendbox_label_api_url,
                "sendbox_cancel_api_url":Cariers[index].sendbox_cancel_api_url,
                "commission":Cariers[index].commission,
                "mode":Cariers[index].mode
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    };
    return(<div className="col-12 col-md">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>

                <div className="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div className="card-body">
                        <h6>Carrier Settings</h6>
                        <div className="row">
                            <div className="col-12 carrier_box mb-3">
                                {Cariers.length>0 ?
                                <div className="accordion" id="carrier_box">
                                    {Cariers.map((item, index)=>{
                                        return(
                                            <div className="accordion-item" key={index}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                    <img src="https://www.shutterstock.com/image-vector/dhl-logo-icon-art-design-600nw-2275004285.jpg" className="img-fluid" />
                                                    <span className="badge bg-success ms-3">{item.mode}</span>
                                                    </button>
                                                </h2>
                                                <div id={`collapse${index}`} className="accordion-collapse collapse show" aria-labelledby={`heading${index}`} data-bs-parent="#payment_accordion">
                                                    <div className="accordion-body">
                                                        <div className="row">
                                                            <div className="col-12 col-md-2">
                                                                <label for="email">Carrier Logo</label>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <input type="file" onChange={(e)=>handleLogo(index,e.target.files[0])} className="form-control" id="text" placeholder="Enter details name" />
                                                            </div>
                                                            <div className="col-12 col-md-2">
                                                                <label for="email">Carrier Name</label>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <input type="text" value={item.company_name} onChange={(e)=>handleInput(index,e.target.value,'company_name')} className="form-control" id="text" placeholder="Enter Carrier name" />
                                                            </div>
                                                            
                                                            <div className="col-12 col-md-2 mt-3">
                                                                <label for="email">Commission</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-3">
                                                                <input type="number" value={item.commission} onChange={(e)=>handleInput(index,e.target.value,'commission')} className="form-control" id="text" placeholder="Enter Carrier name" />
                                                            </div>

                                                            <div className="col-12 col-md-2 mt-3">
                                                                <label for="email">Active Mode:</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-3">
                                                                <select className="form-select form-select-sm" onChange={(e)=>handleInput(index,e.target.value,'mode')}>
                                                                    <option selected={item.mode=='Live'?true:false} value="Live">Live</option>
                                                                    <option selected={item.mode=='Sandbox'?true:false} value="Sandbox">Sandbox</option>
                                                                    <option selected={item.mode=='Off'?true:false} value="Off">Off</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-12 col-md-6">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        Live Mode
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="mb-3">
                                                                            <label for="email">API Key</label>
                                                                            <input className="form-control" value={item.production_api_key} onChange={(e)=>handleInput(index,e.target.value,'production_api_key')} type="text" placeholder="Enter your API Key" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Price API End Point</label>
                                                                            <input className="form-control" value={item.production_price_api_url} onChange={(e)=>handleInput(index,e.target.value,'production_price_api_url')} type="text" placeholder="Enter your price API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Booking API End Point</label>
                                                                            <input className="form-control" value={item.production_booking_api_url} onChange={(e)=>handleInput(index,e.target.value,'production_booking_api_url')} type="text" placeholder="Enter your booking API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Trackig API End Point</label>
                                                                            <input className="form-control" value={item.production_tacking_api_uri} onChange={(e)=>handleInput(index,e.target.value,'production_tacking_api_uri')} type="text" placeholder="Enter your trackig API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Label API End Point</label>
                                                                            <input className="form-control" value={item.production_label_api_url} onChange={(e)=>handleInput(index,e.target.value,'production_label_api_url')} type="text" placeholder="Enter your label API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Cencle API End Point</label>
                                                                            <input className="form-control" value={item.production_cancel_api_url} onChange={(e)=>handleInput(index,e.target.value,'production_cancel_api_url')} type="text" placeholder="Enter your cencle API end point url" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        Sandbox Mode
                                                                    </div>
                                                                    <div className="card-body">
                                                                    <div className="mb-3">
                                                                            <label for="email">API Key</label>
                                                                            <input className="form-control" value={item.sendbox_api_key} onChange={(e)=>handleInput(index,e.target.value,'sendbox_api_key')} type="text" placeholder="Enter your API Key" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Price API End Point</label>
                                                                            <input className="form-control" value={item.sendbox_price_api_url} onChange={(e)=>handleInput(index,e.target.value,'sendbox_price_api_url')} type="text" placeholder="Enter your price API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Booking API End Point</label>
                                                                            <input className="form-control" value={item.sendbox_booking_api_url} onChange={(e)=>handleInput(index,e.target.value,'sendbox_booking_api_url')} type="text" placeholder="Enter your booking API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Trackig API End Point</label>
                                                                            <input className="form-control" value={item.sendbox_tracking_api_url} onChange={(e)=>handleInput(index,e.target.value,'sendbox_tracking_api_url')} type="text" placeholder="Enter your trackig API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Label API End Point</label>
                                                                            <input className="form-control" value={item.sendbox_label_api_url} onChange={(e)=>handleInput(index,e.target.value,'sendbox_label_api_url')} type="text" placeholder="Enter your label API end point url" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label for="email">Cencle API End Point</label>
                                                                            <input className="form-control" value={item.sendbox_cancel_api_url} onChange={(e)=>handleInput(index,e.target.value,'sendbox_cancel_api_url')} type="text" placeholder="Enter your cencle API end point url" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {Subloading==false?
                                                        <div className="d-grid col-md-2 mt-2">
                                                            <button onClick={()=>handleSubmit(index)} className="btn btn-primary mt-3">Update Settings</button>
                                                        </div>
                                                        :
                                                        <div className="d-grid col-md-2 mt-2">
                                                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                :''
                                }
                            </div> 
                        </div>
                    </div>
                }  
                </div>
            </div>
    )
}
export default CarrierSetting;