import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';


function CustomerChangePassword(){
    let navigate = useNavigate();
    const {UniqueId} = useParams();
    const{headers1,headers2,logout}=AuthUser();
    const [Password, setPassword] = useState('');
    const [ConfirmPass, setConfirmPass] = useState('');
    const [loading, setloading] = useState(false);
    
    useEffect(()=>{
    },[]);
    const handleSubmit=()=>{
        if(Password==""){
            toast.warning('Password is Required');
        }
        else if(ConfirmPass==""){
            toast.warning('Confirm Password is Required');
        }
        else if(Password!=ConfirmPass){
            toast.warning('Password and Confirm Password Must Be Same');
        }
        else{
            setloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'CustomerChangePassword',
                headers:headers2,
                data: {
                    UniqueId:UniqueId,
                    Password:Password
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    setloading(false);
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/CustomerList/All');
                      }, 1000);
                }else{
                    setloading(false);
                    toast.warning(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    // logout();
                    // navigate('/');
                    // window.location.reload();
                }
                setloading(false);
              });
        }
    }
    return(
        <>
            <UserLeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                <section className="left_sub_menu">
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                            <i className="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Change Customer's Password</li>
                                    </ol>
                                </nav>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                        <i className="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div className="ps-2 ">
                                        <h2 className="h4">Change Customer's Password</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                            <h6>Change Password</h6>
                                <div className="row">
                                    <div className="col-12 mb-3 mt-4">
                                        <strong>Customer Basic Details</strong>
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Please enter password" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" onChange={(e)=>setConfirmPass(e.target.value)} placeholder="Please confirm Password" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                                {loading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Submit</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                                    
                            </div>
                        </div>

                    </div>
                </div>
            </section>
                <Footer/>
            </main>
        </>
    )
}
export default CustomerChangePassword;