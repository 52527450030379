import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";
import QRCode from 'qrcode.react';

function BagLabel() {
    let navigate = useNavigate();
    const { uniqueid } =useParams();
    const [data, setData] = useState([]);
    const { headers1, headers2, logout } = AuthUser();
    useEffect(() => {
        GetLabel();
    }, []);
    const GetLabel = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'BagLabel',
            headers: headers1,
            data: {
                uniqueid: uniqueid
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setData(data);
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
    }

    return (
            <div className="container">
                    <div className="pagebreak">
                        <div className="new_lable">
                            <div className="lable_head">{data.systemcompanyname}</div>
                            <div className="">
                                <div className="col-md-12 lable_shipment">
                                    <div className="row">
                                        <div className="col-md-7" style={{"border-right": "1px solid #cfcfcf", "margin-right": "0px"}}>
                                            <div className="row">
                                                <div className="col-md-12 labelcol"><strong>To</strong> : </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-12"><span className="small">{data.receiverstate},{data.receivercountry}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="row">
                                                <div className="col-md-12"><strong>Shipped From</strong> : </div>
                                                <div className="col-md-12 labelcol"><span className="small">{data.senderstate},{data.sendercountry}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lable_shipment">
                                <div className="row">
                                    <div className="col-md-6"> <strong>From :</strong> &ensp;&ensp; <span>{data.sendercity}</span> </div>
                                    <div className="col-md-6"> <strong>To :</strong> &ensp;&ensp;<span>{data.receivercity}</span> </div>
                                </div>

                                
                                <div className="row">
                                    <div className="col-md-12 labelcol" style={{"padding-left": "14px;"}}> <strong>Bag Weight:</strong><span>{data.totalWeight} {data.weightType}</span> </div>
                                </div>
                            </div>
                            <div className="lable_shipment">
                                <div className="lable_shipment">
                                    <div className="row">
                                        <div className="col-md-6"> <strong>Issued by</strong> : <span>Admin</span> </div>
                                        <div className="col-md-6"> <strong>Date</strong> : <span>{data.entrydate}</span> </div>
                                    </div>
                                </div>
                                <div className="lable_shipment">
                                    <div className="row">
                                        <div className="col-md-8 bar_code"> <Barcode value={data.uniqueid} /> </div>
                                        <div className="col-md-4"> <QRCode value={data.uniqueid} size={75} style={{"height":"70px","width":"65px","margin":"5px"}}/> </div>
                                    </div>
                                </div>
                                <div className="lable_shipment">
                                        <div className="col-md-12"><strong>No. Of Boxes</strong> : 1</div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
    );
}
export default BagLabel;