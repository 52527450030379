import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function SocialSetting()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[facebook,setfacebook]=useState('');
    const[facebook_status,setfacebook_status]=useState('');
    const[twitter,settwitter]=useState('');
    const[twitter_status,settwitter_status]=useState('');
    const[instagram,setinstagram]=useState('');
    const[instagram_status,setinstagram_status]=useState('');
    const[whatsapp,setwhatsapp]=useState('');
    const[whatsapp_status,setwhatsapp_status]=useState('');
    const[youtube,setyoutube]=useState('');
    const[youtube_status,setyoutube_status]=useState('');
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchSocialData();
    },[]);
    function FetchSocialData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchSocialData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setfacebook(data.facebook);
                setfacebook_status(data.facebook_status);
                settwitter(data.twitter);
                settwitter_status(data.twitter_status);
                setinstagram(data.instagram);
                setinstagram_status(data.instagram_status);
                setwhatsapp(data.whatsapp);
                setwhatsapp_status(data.whatsapp_status);
                setyoutube(data.youtube);
                setyoutube_status(data.youtube_status);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const handleSubmit=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateSocialData',
            headers:headers1,
            data: {
                "facebook":facebook,
                "facebook_status":facebook_status,
                "twitter":twitter,
                "twitter_status":twitter_status,
                "instagram":instagram,
                "instagram_status":instagram_status,
                "whatsapp":whatsapp,
                "whatsapp_status":whatsapp_status,
                "youtube":youtube,
                "youtube_status":youtube_status
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setSubloading(false);
            // console.log(error);
          });
    };
    
    return(
            <div className="col-12 col-md">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                <div className="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div className="card-body">
                    <h6>Social Settings</h6>
                        <section id="social_info">
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <div className="icon_social">
                                                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <label for="">Whatsapp</label>
                                                </div>
                                                <div className="">
                                                    <div className="form-switch">
                                                        <input className="form-check-input" type="checkbox" id="mySwitch" name="darkmode" checked={whatsapp_status=='Y'? true : false} onChange={()=>{whatsapp_status=='Y'? setwhatsapp_status('N'):setwhatsapp_status('Y')}} />
                                                        <label className="form-check-label" for="mySwitch"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input className="form-control" id="first_name" type="text" placeholder="Enter your Whatsapp mobile number" value={whatsapp} onChange={(e)=>setwhatsapp(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <div className="icon_social">
                                                <i className="fa fa-instagram" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <label for="">Instagram</label>
                                                </div>
                                                <div className="">
                                                    <div className="form-switch">
                                                        <input className="form-check-input" type="checkbox" id="mySwitch" name="darkmode" checked={instagram_status=='Y'? true : false} onChange={()=>{instagram_status=='Y'? setinstagram_status('N'):setinstagram_status('Y')}} />
                                                        <label className="form-check-label" for="mySwitch"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input className="form-control" id="first_name" type="text" placeholder="Enter your Instagram ID" value={instagram} onChange={(e)=>setinstagram(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <div className="icon_social">
                                                <i className="fa fa-facebook" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <label for="">Facebook</label>
                                                </div>
                                                <div className="">
                                                    <div className="form-switch">
                                                        <input className="form-check-input" type="checkbox" id="mySwitch" name="darkmode" checked={facebook_status=='Y'? true : false} onChange={()=>{facebook_status=='Y'? setfacebook_status('N'):setfacebook_status('Y')}} />
                                                        <label className="form-check-label" for="mySwitch"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input className="form-control" id="first_name" type="text" placeholder="Enter your Facebook ID" value={facebook} onChange={(e)=>setfacebook(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <div className="icon_social">
                                                <i className="fa fa-youtube" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <label for="">Youtube</label>
                                                </div>
                                                <div className="">
                                                    <div className="form-switch">
                                                        <input className="form-check-input" type="checkbox" id="mySwitch" name="darkmode" checked={youtube_status=='Y'? true : false} onChange={()=>{youtube_status=='Y'? setyoutube_status('N'):setyoutube_status('Y')}} />
                                                        <label className="form-check-label" for="mySwitch"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input className="form-control" id="first_name" type="text" placeholder="Enter your Youtube ID" value={youtube} onChange={(e)=>setyoutube(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <div className="icon_social">
                                                <i className="fa fa-twitter" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <label for="">Twitter</label>
                                                </div>
                                                <div className="">
                                                    <div className="form-switch">
                                                        <input className="form-check-input" type="checkbox" id="mySwitch" name="darkmode" checked={twitter_status=='Y'? true : false} onChange={()=>{twitter_status=='Y'? settwitter_status('N'):settwitter_status('Y')}} />
                                                        <label className="form-check-label" for="mySwitch"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input className="form-control" id="first_name" type="text" placeholder="Enter your Twitter ID" value={twitter} onChange={(e)=>settwitter(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {Subloading==false?
                        <div className="d-grid col-md-2 mt-2">
                            <button onClick={handleSubmit} className="btn btn-primary mt-3">Update Settings</button>
                        </div>
                        :
                        <div className="d-grid col-md-2 mt-2">
                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                        </div>
                        }
                    </div>
                }
                </div>

            </div>
    )
}
export default SocialSetting;