import axios from 'axios';
import { useState } from "react";

export default function AuthUser(){
    const getToken=()=>{
        const tokenString=localStorage.getItem('token');
        const userToken=JSON.parse(tokenString);
        return userToken;
    }
    const getUser=()=>{
        const userString=localStorage.getItem('user');
        const userData=JSON.parse(userString);
        return userData;
    }
    const getDefaultCurrency=()=>{
        return "$";
    }
    const getDays=()=>{
        return "30";
    }

    const[token,setToken]=useState(getToken());
    const[user,setUser]=useState(getUser());

    const saveToken=(user,token)=>{
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', JSON.stringify(token));

        setToken(token);
        setUser(user);
        return 1;
    }
    const logout=()=>{
        localStorage.clear();
        return 1;
    }
    let headers1={
        "Content-Type":"application/json",
        "Accept":"application/json",
        "Authorization": `Bearer ${getToken()}` 
    }
    let headers2={
        "Content-Type":"multipart/form-data",
        "Accept":"application/json",
        "Authorization": `Bearer ${getToken()}` 
    }
    return{
        setToken:saveToken,
        token,
        user,
        getToken,
        headers1,
        headers2,
        logout,
        getDefaultCurrency,
        getDays
    }
}