import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import FinanceLeftPanel from "../include/FinanceLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function AgentReport(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [selectedfromdate, setSelectedfromdate] = useState('');
    const [selectedtodate, setSelectedtodate] = useState(getTodayDateString());
    const [status,setStatus] = useState('');
    const [agentlist, setAgentlist] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    
    useEffect(()=>{
        Getagentlist();
    },[]);

    const Getagentlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'reportagentlist',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setAgentlist(data);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      
    const exportToExcel = () => {
        // if(selectedAgent !='' && selectedAgent !=undefined)
        // {
            axios({
                method: 'post',
                url: Global.baseUrl+'agentreport',
                headers:headers1,
                data: {
                    Fromdate:selectedfromdate,
                    Todate:selectedtodate,
                    agentid:selectedAgent,
                    status:status,
                }
                })
                .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success("Excel Download Successfully");
                    // Create a new workbook
                    const wb = XLSX.utils.book_new();
                
                    // Convert data to a worksheet
                    const ws = XLSX.utils.json_to_sheet(response.data.value);
                
                    // Append the worksheet to the workbook
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                
                    // Generate a buffer for the workbook
                    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                
                    // Create a Blob object from the buffer
                    const blob = new Blob([wbout], { type: 'application/octet-stream' });
                
                    // Save the file using file-saver
                    saveAs(blob, 'Agentreport.xlsx');
                }
                else{
                    toast.warn(response.data.message);
                }
                })
                .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
                });
        // }
        // else
        // {
        //     toast.warn("Please Select Agent");
        // }
      };

    return(
        <>
        <FinanceLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Agent Reports</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Agent Reports</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">From Date: <span className="text-danger"></span></label></div>
                        <input type="date" className="form-control" id="text" value={selectedfromdate} placeholder="Enter city name" onChange={(e)=>setSelectedfromdate(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">To Date: <span className="text-danger"></span></label></div>
                        <input type="date" className="form-control" id="text" value={selectedtodate} placeholder="Enter city name" onChange={(e)=>setSelectedtodate(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">Select Agent: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setSelectedAgent(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            {agentlist.map((cust,i)=>{
                                return(
                                <option value={cust.uniqueid} key={i}>{cust.name}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">Select Status: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setStatus(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            <option value="B">Booked</option>
                            <option value="PP">Proceed For Pickup </option>
                            <option value="T">Pickup</option>
                            <option value="IT">In Transit</option>
                            <option value="D">Deliver</option>
                            <option value="T">Undeliverd</option>
                            <option value="DL">Reschedule</option>
                        </select>
                    </div>
                    <div className="py-3">
                        <button onClick={exportToExcel} className="btn btn-primary">Export to Excel</button>
                    </div>
                </div>
              </div>
            </div>
            <Footer/>
        </main>
        </>

    );
}
export default AgentReport;