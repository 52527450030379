import React, { useState, useEffect } from "react";
import Global from '../../APIConfig';
import  axios from "axios";
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { Bar,Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ListLoader from "../Loaders/ListLoader";
Chart.register(...registerables)

function ProductChart(){
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [ProductType, setProductType] = useState('');
    const [TypeVal, setTypeVal] = useState('');
    const [Colors, setColors] = useState('');
    useEffect(()=>{
        Get30DaysShipmentDetail();
    },[]);
    
    const Get30DaysShipmentDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysProductBooking',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setProductType(data.Types);
                setTypeVal(data.TypeVal);
                setColors(data.Colors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const state = {
        labels: ProductType,
        datasets: [
          {
            label: 'Product Type',
            backgroundColor: Colors,
            borderColor:Colors,
            borderWidth: 2,
            data:TypeVal,
          },
        ],
      }
    return (
        <div>
            {Loading==true ? <ListLoader/> :
          <Bar
            data={state}
            options={{
              title: {
                display: true,
                text: 'Class strength',
                fontSize: 20,
              },
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
          }
        </div>
      )
}

export default ProductChart;
  
