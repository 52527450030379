import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { Link, useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function EmailTemplate()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[Templates,setTemplates]=useState([]);
    const [Loading, setLoading] = useState(true);
    useEffect(()=>{
        FetchEmailTemplate();
    },[]);
    function FetchEmailTemplate(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchEmailTemplate',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setTemplates(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setLoading(false);
            // console.log(error);
          });
    }
    
    return(
        <div className="col-12 col-md">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                    <li className="breadcrumb-item">
                                        <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Settings</li>
                                </ol>
                            </nav>
                            <div className="d-flex justify-content-between">
                                <div className="">
                                    <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                    </button>
                                </div>
                                <div className="ps-2 ">
                                    <h2 className="h4">Settings</h2>
                                </div>
                            </div>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                        </div>
                    </div>
                    <div className="card" style={{minHeight:'150px'}}>
                    {Loading==true ? <ListLoader/> :
                        <div className="card-body">
                        <h6>Email Templates Settings</h6>
                            <div className="row">
                                <div className="col-12  mb-3">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                            <th>Sr. No.</th>
                                            <th>Subject</th>
                                            <th>Actions</th>
                                            </tr>
                                        </thead>
                                        {Templates.length>0 ?
                                        <tbody>
                                            {Templates.map((item, index)=>{
                                            return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.subject}</td>
                                                <td><Link target="_blank" to={`/EmailTemplateDetail/${item.id}`} className="btn btn-outline-primary btn-sm">Update</Link></td>
                                            </tr>
                                            );
                                            })}
                                        </tbody>
                                        :""
                                        }
                                        </table>
                                </div>
                            </div>
                        </div>
                    }
                    </div>

        </div>
    )
}
export default EmailTemplate;