import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

const Barcode = ({ value }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, value, {
        format: "CODE128",
        lineColor: "#000",
        width: 0.9,
        height: 40,
        displayValue: true,
        margin: 2,
      });
    }
  }, [value]);

  return (
    <svg ref={barcodeRef}></svg>
  );
};

export default Barcode;
