import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountSetting from "./AccountSetting";
import GeneralSetting from "./GeneralSetting";
import SystemSetting from "./SystemSetting";
import ShipmentSetting from "./ShipmentSetting";
import CarrierSetting from "./CarrierSetting";
import PaymentGetwaySetting from "./PaymentGetwaySetting";
import SmtpSetting from "./SmtpSetting";
import SocialSetting from "./SocialSetting";
import EmailTemplate from "./EmailTemplate";
import SmsTemplate from "./SmsTemplate";
import DatabaseBackup from "./DatabaseBackup";

function AllSettings()
{
    const[Name,setName]=useState('GeneralSetting');
    useEffect(()=>{
    },[]);
    
    return(
        <>
            <MasterLeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                <section className="left_sub_menu">
                <div className="row">
                    <div className="col-12 col-md-3 left_sub_menu_bx">
                    <h3><i className="bx bx-cog"></i> Settings</h3>
                        <ul className="nav  flex-column ps-4">
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='GeneralSetting')?'active':''}`} onClick={()=>{setName('GeneralSetting')}}>General Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='SystemSetting')?'active':''}`} onClick={()=>{setName('SystemSetting')}}>System Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='ShipmentSetting')?'active':''}`} onClick={()=>{setName('ShipmentSetting')}}>Shipment Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='AccountSetting')?'active':''}`} onClick={()=>{setName('AccountSetting')}}>Account Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='CarrierSetting')?'active':''}`} onClick={()=>{setName('CarrierSetting')}}>Third Party Carrier Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='PaymentGetwaySetting')?'active':''}`} onClick={()=>{setName('PaymentGetwaySetting')}}>Payment Gateway Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='SmtpSetting')?'active':''}`} onClick={()=>{setName('SmtpSetting')}}>SMTP Email Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='SocialSetting')?'active':''}`} onClick={()=>{setName('SocialSetting')}}>Social Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='EmailTemplate')?'active':''}`} onClick={()=>{setName('EmailTemplate')}}>Email Template Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='SmsTemplate')?'active':''}`} onClick={()=>{setName('SmsTemplate')}}>SMS Tempalte Settings</a>
                            </li>
                        </ul>
                        <h3><i className="bx bx-cog"></i> Other Settings</h3>
                        <ul className="nav  flex-column ps-4">
                            <li className="nav-item">
                                <a className={`nav-link ${(Name=='DatabaseBackup')?'active':''}`} onClick={()=>{setName('DatabaseBackup')}}>Database Backup</a>
                            </li>
                        </ul>
                    </div>
                    {Name=='GeneralSetting' && <GeneralSetting/>}
                    {Name=='SystemSetting' && <SystemSetting/>}
                    {Name=='ShipmentSetting' && <ShipmentSetting/>}
                    {Name=='AccountSetting' && <AccountSetting/>}
                    {Name=='CarrierSetting' && <CarrierSetting/>}
                    {Name=='PaymentGetwaySetting' && <PaymentGetwaySetting/>}
                    {Name=='SmtpSetting' && <SmtpSetting/>}
                    {Name=='SocialSetting' && <SocialSetting/>}
                    {Name=='EmailTemplate' && <EmailTemplate/>}
                    {Name=='SmsTemplate' && <SmsTemplate/>}
                    {Name=='DatabaseBackup' && <DatabaseBackup/>}
                </div>
            </section>
                <Footer/>
            </main>
        </>
    )
}
export default AllSettings;