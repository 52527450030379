import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function SmtpSetting()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[OnOff,setOnOff]=useState('');
    const[Host,setHost]=useState('');
    const[Port,setPort]=useState('');
    const[Secure,setSecure]=useState('');
    const[Username,setUsername]=useState('');
    const[Password,setPassword]=useState('');
    const[Mail,setMail]=useState('');
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchSMTPData();
    },[]);
    function FetchSMTPData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchSMTPData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setOnOff(data.smtp_onoff);
                setHost(data.smtp_host);
                setPort(data.smtp_port);
                setSecure(data.smtp_secure);
                setUsername(data.smtp_user_name);
                setPassword(data.smtp_password);
                setMail(data.sender_mail);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const updateElement = () => {
        let newValue='';
        if(OnOff=='Y'){
            newValue='N';
        }else{
            newValue='Y';
        }
        setOnOff(newValue);
    };
    const handleSubmit=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateSMTPData',
            headers:headers1,
            data: {
                "OnOff":OnOff,
                "Host":Host,
                "Port":Port,
                "Secure":Secure,
                "Username":Username,
                "Password":Password,
                "Mail":Mail
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setSubloading(false);
            // console.log(error);
          });
    };
    return(
        <div className="col-12 col-md">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Settings</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Settings</h2>
                        </div>
                    </div>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card" style={{minHeight:'150px'}}>
            {Loading==true ? <ListLoader/> :
                <div className="card-body">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-4">
                        <h6>SMTP Email Settings</h6>
                    </div>
                    <div className="col-12 col-md-1 text-end">
                        <div className="form-check form-switch  mb-2">
                            <input className="form-check-input" checked={OnOff=='Y'? true : false} onChange={updateElement} type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                            </div>
                    </div>
                </div>
                    <div className="row mt-3">
                        <div className="col-6  mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="" className="form-label">Host</label>
                                <small className="text-danger">Sender Hostname SMTP server</small>
                            </div>
                            <input type="text" className="form-control" value={Host} onChange={(e)=>setHost(e.target.value)} placeholder="Please enter Host details" />
                        </div>
                        <div className="col-6  mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="" className="form-label">Port</label>
                                <small className="text-danger">(Sets SMTP Port. Default Port is 25)</small>
                            </div>
                            <input type="number" className="form-control" value={Port} onChange={(e)=>setPort(e.target.value)} placeholder="Please enter Port details" />
                        </div>
                        <div className="col-6 me-6 mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="" className="form-label">Secure</label>
                                <small className="text-danger">auto, ssl, tls, none</small>
                            </div>
                            <select className="form-select" onChange={(e)=>setSecure(e.target.value)}>
                                <option value="none" selected={Secure=="none"? true:false}>None</option>
                                <option value="ssl" selected={Secure=="ssl"? true:false}>SSL</option>
                                <option value="tls" selected={Secure=="tls"? true:false}>TLS</option>
                                <option value="auto" selected={Secure=="auto"? true:false}>Auto</option>
                            </select>
                        </div>
                        <div className="col-6 mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="" className="form-label">User Name</label>
                                <small className="text-danger">Sets SMTP username</small>
                            </div>
                            <input type="text" className="form-control" value={Username} onChange={(e)=>setUsername(e.target.value)} placeholder="Please enter User Name details" />
                        </div>
                        <div className="col-6  mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="" className="form-label">Password</label>
                                <small className="text-danger">SMTP User Password</small>
                            </div>
                            <input type="text" className="form-control" value={Password} onChange={(e)=>setPassword(e.target.value)} placeholder="Please enter Password details" />
                        </div>
                        <div className="col-6  mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="" className="form-label">Sender mail id</label>
                                <small className="text-danger">Sets SMTP Sender mail id</small>
                            </div>
                            <input type="text" className="form-control" value={Mail} onChange={(e)=>setMail(e.target.value)} placeholder="Please enter Sender mail id details" />
                        </div>
                    </div>
                    {Subloading==false?
                    <div className="d-grid col-md-2 mt-2">
                        <button onClick={handleSubmit} className="btn btn-primary mt-3">Update Settings</button>
                    </div>
                    :
                    <div className="d-grid col-md-2 mt-2">
                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                    </div>
                    }
                </div>
            }
            </div>

        </div>
    )
}
export default SmtpSetting;