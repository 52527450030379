import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import ListLoader from "../Loaders/ListLoader";


function SystemSetting(){
    const navigate = useNavigate();
    const[AllCountry,setAllCountry]=useState([]);
    const[DefaultCountry,setDefaultCountry]=useState('');
    const[AllCurrency,setAllCurrency]=useState([]);
    const[DefaultCurrency,setDefaultCurrency]=useState('');
    const[TimeZone,setTimeZone]=useState([]);
    const[DefaultTimeZone,setDefaultTimeZone]=useState('');
    const[PerPage,setPerPage]=useState('');
    const[SystemSupport,setSystemSupport]=useState('');
    const[PowerdBy,setPowerdBy]=useState('');
    const[GoogleKey,setGoogleKey]=useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchSystemData();
        Countrylist();
        CurrencyList();
        getTimeZone();
    },[]);
    function FetchSystemData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchSystemData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                var key=response.data.Google_key;
                setGoogleKey(key);
                setDefaultCountry(data.default_country);
                setDefaultCurrency(data.default_currency_id);
                setDefaultTimeZone(data.default_time_zone);
                setPerPage(data.page_rows);
                setPowerdBy(data.powered_by)
                setSystemSupport(data.set_pin_or_city);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    function Countrylist(){
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setAllCountry(data);
            }
          })
    }
    function CurrencyList(){
        axios({
            method: 'post',
            url: Global.baseUrl+'CurrencyList',
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setAllCurrency(data);
            }
          })
          
    }
    function getTimeZone(){
        axios({
            method: 'post',
            url: Global.baseUrl+'getTimeZone',
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setTimeZone(data);
            }
          })
    }
    function handleSubmit(){
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateSystemData',
            headers:headers1,
            data: {
                'DefaultCountry':DefaultCountry,
                'DefaultCurrency':DefaultCurrency,
                'DefaultTimeZone':DefaultTimeZone,
                'PerPage':PerPage,
                'SystemSupport':SystemSupport,
                'PowerdBy':PowerdBy,
                'GoogleKey':GoogleKey,
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }
    return( 
            <div className="col-12 col-md">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                <div className="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div className="card-body">
                    <h6>System Settings</h6>
                        <div className="row">
                            
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Default Country</label>
                                <select className="form-select" onChange={(e)=>setDefaultCountry(e.target.value)}>
                                    {AllCountry.map((item)=>{   
                                            return (<option value={item.id} selected={item.id==DefaultCountry?'selected':''}>{item.country}</option>);   
                                        })   
                                    }
                                </select>
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Default Currency</label>
                                <select className="form-select" onChange={(e)=>setDefaultCurrency(e.target.value)}>
                                {AllCurrency.map((item)=>{   
                                            return (<option value={item.id} selected={item.default=='Y'?'selected':''}>{item.label} {(item.symbol)}</option>);   
                                        })   
                                    }
                                </select>
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Default Time Zone</label>
                                <select className="form-select" onChange={(e)=>setDefaultTimeZone(e.target.value)}>
                                {TimeZone.map((item)=>{   
                                            return (<option value={item.key} selected={item.key==DefaultTimeZone?'selected':''}>{item.val}</option>);   
                                        })   
                                    }
                                </select>
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">System Data Rows per Page</label>
                                <input type="number" className="form-control" id="" value={PerPage} onChange={(e)=>setPerPage(e.target.value)} placeholder="Please enter Page Rows details" />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">System data support By</label>
                                <select className="form-select"  onChange={(e)=>setSystemSupport(e.target.value)}>
                                    <option value="C" selected={SystemSupport=='C'?'selected':''}>City Name</option>
                                    <option value="P" selected={SystemSupport=='P'?'selected':''}>Postal/Pin/Zip Code</option>
                                </select>
                            </div>
                        </div>
                        <h6 className="mt-4">Other Settings</h6>
                        <div className="row">
                            <div className="col-6 mb-3">
                                <label for="" className="form-label">Footer Powered By Content</label>
                                <input type="text" value={PowerdBy} onChange={(e)=>setPowerdBy(e.target.value)} className="form-control" id="" placeholder="Please enter Footer Content details" />
                            </div>
                            <div className="col-6 mb-3">
                                <div className="d-flex justify-content-between">
                                    <label for="" className="form-label">Google API Key</label>
                                    <small><a href="https://i.imgur.com/NSXglDN.png" target="_blank">Checkout all require API list here</a> </small>
                                </div>
                                <input type="text" value={GoogleKey} onChange={(e)=>setGoogleKey(e.target.value)} className="form-control" id="" placeholder="Please enter Google API Key details" />
                                
                            </div>
                        </div>
                        {Subloading==false?
                        <div className="d-grid col-md-2 mt-2">
                            <button onClick={handleSubmit} className="btn btn-primary">Update Settings</button>
                        </div>
                        :
                        <div className="d-grid col-md-2 mt-2">
                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                        </div>
                        }
                    </div>
                }
                </div>

            </div>
    )
}
export default SystemSetting;