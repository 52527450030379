import React from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";

function Generatepickupschedulecustomer()
{
    return(
        <>
        <LeftPanel/>
            <main className='home-section'>
            <Header/>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="index.php">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Pick Run Sheet</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <a className="btn btn_nav btn-sm" href="prs_running.php">
                            <i className="bx bx-arrow-back "></i>
                            </a>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Schedule New Customer PRS</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col-12 col-md-6">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12 mb-3">
                                    <div><label for="email" className="form-label">Select Customer: <span className="text-danger">*</span></label></div>
                                    <input type="text" className="form-control" id="text" placeholder="Enter customer name" />
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div><label for="email" className="form-label">No. of Consignment/s: <span className="text-danger">*</span></label></div>
                                    <input type="text" className="form-control" id="text" placeholder="Enter consignment numbers" />
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div><label for="email" className="form-label">Total Weight: <span className="text-danger">*</span></label></div>
                                    <input type="text" className="form-control" id="text" placeholder="Enter total expected" />
                                </div>
                                <div className="col-12 col-md-12 mb-3">
                                    <div><label for="email" className="form-label">Destination Description: <span className="text-danger">*</span></label></div>
                                    <input type="text" className="form-control" id="text" placeholder="Enter location details" />
                                </div>
                                <div className="col-12 col-md-12 mb-3">
                                    <div><label for="email" className="form-label">Item Type: <span className="text-danger">*</span></label></div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked />
                                    <label className="form-check-label" for="inlineRadio1">Document</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                    <label className="form-check-label" for="inlineRadio2">Parcel</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div><label for="email" className="form-label">Date & Time for Pickup: <span className="text-danger">*</span></label></div>
                                    <input type="date" className="form-control" id="text" placeholder="Enter city name" />
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="d-flex justify-content-between">
                                        <div ><label for="email" className="form-label">Pickup Address: <span className="text-danger">*</span></label></div>
                                    </div>
                                    <select className="form-select">
                                    <option value="">-- Please Select --</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <a href="#" className="btn btn-primary">Generate Pickup Run Sheet</a>
            </div>
            <Footer/>
            </main>
        </>
    );
}
export default Generatepickupschedulecustomer;