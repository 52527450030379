import React, { useState, useEffect } from "react";
import Global from '../../APIConfig';
import  axios from "axios";
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { Bar,Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ListLoader from "../Loaders/ListLoader";
Chart.register(...registerables)

function SaleChart(){
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [Label, setLabel] = useState('');
    const [Values, setValues] = useState('');
    const [Loading, setLoading] = useState(true);
    const [todayshipmentSale, setTodayshipmentSale]=useState('');
    const [shipmentsLast30DaySale, setShipmentsLast30DaySale]=useState('');
    useEffect(()=>{
        Get30DaysShipment();
    },[]);
    
    const Get30DaysShipment=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysSale',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setLabel(data.label);
                setValues(data.value);
                setTodayshipmentSale(data.todayshipmentSale);
                setShipmentsLast30DaySale(data.shipmentsLast30DaySale);
            }
            else
            {
                setLabel();
                setValues();
                setTodayshipmentSale();
                setShipmentsLast30DaySale();
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const state = {
        labels: Label,
        datasets: [
          {
            label: 'Sale',
            backgroundColor: '#fd7e14',
            fill: false,
            lineTension: 0.5,
            borderColor: '#fd7e14',
            borderWidth: 2,
            data: Values,
          },
        ],
      }
    return (
        <div>
            {Loading==true ? <ListLoader/> :
            <>
            <div class="sales_card_st_bx">
            <div class="row">
                <div class="col-12 col-md">
                    <h5>Sales Information</h5>
                    <h1><small>$</small>{Number(todayshipmentSale).toFixed(2)} <small>Today</small></h1>
                    <h6><small>$</small>{Number(shipmentsLast30DaySale).toFixed(2)} sales in last 30 days</h6>
                </div>
                <div class="col-12 col-md-4 text-end">
                    <a href="">Sales Report</a>
                </div>
            </div>
        </div>
          <Line
            data={state}
            options={{
              title: {
                display: true,
                text: 'Class strength',
                fontSize: 20,
              },
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
          </>
          }
        </div>
      )
}

export default SaleChart;
  
