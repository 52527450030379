import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function ZoneEdit()
{
    const navigate = useNavigate();
    const { unique_id } = useParams();
    const [zonename, setZonename] = useState('');
    const [zonetype, setZonetype] = useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        GetZonedata();
    },[]);

    const GetZonedata=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetZonedataByuniqueid',
            headers:headers1,
            data: {
                unique_id: unique_id
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                setZonename(response.data.value.name);
                setZonetype(response.data.value.Zone_type);
            }
            else
            {
                setZonename('');
                setZonetype('');
            }
            setLoading(false);
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const handleSubmit=()=>{
        if(zonename =='')
        {
            toast.warn('Please Enter Zone Name');
        }
        else if(zonetype == '')
        {
            toast.warn('Please Select Zone Type');
        }
        else
        {
            setSubloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'EditZone',
                headers:headers1,
                data: {
                    unique_id: unique_id,
                    name: zonename,
                    zone_type: zonetype,

                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/zoneList'); 
                    }, 1000);
                }
                else
                {
                    if(response.data.message.name)
                    {
                        toast.warn(response.data.message.name[0]);
                    }
                    else if(response.data.message.zone_type)
                    {
                        toast.warn(response.data.message.zone_type[0]);
                    }
                    else{
                        toast.warn(response.data.message);
                    }
                    
                }
                setSubloading(false);
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setSubloading(false);
                // console.log(error);
              });
        }
    }

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading==true ? <MainLoader/> :
                <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Zone</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Update Zone</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Zone Name <small className="text-danger">*</small> </label>
                                        <input type="text" className="form-control" id="" placeholder="Please Enter Zone Name" value={zonename} onChange={(e)=>setZonename(e.target.value)} />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Zone Type <small className="text-danger">*</small></label>
                                        <select className="form-control" onChange={(e)=>setZonetype(e.target.value)}>
                                            <option value="" selected={zonetype == ''?"selected":""}>Please Select</option>
                                            <option value="Domestic" selected={zonetype == 'Domestic'?"selected":""}>Domestic</option>
                                            <option value="International" selected={zonetype == 'International'?"selected":""}>International</option>
                                        </select>
                                    </div>
                                     
                                </div>
                                {Subloading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Update Zone</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                            </div>
                        </div>
                    </div>
                    
                </div>
                </>}
                <Footer />
            </main>
        </>
        );
}
export default ZoneEdit;