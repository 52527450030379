import React from "react";

function BagPrint(){
return(
    <div className="container mt-3">
        <div className="row justify-content-between">
            <div className="col-12 col-md-3">
                <img src="https://i.imgur.com/xbOGrm9.png" className="img-fluid" />
            </div>
            <div className="col-12 col-md text-center">
                <h2>Pickup Run Sheet</h2>
                <div className="d-flex justify-content-center">
                    <div className="p-1">Pickup Date: 12/12/2023</div>
                    <div className="p-1">HUB Code: MUMB01</div>
                    <div className="p-1">Route Code: MUMB01</div>
                </div>
            </div>
            <div className="col-12 col-md-3 text-end">
                <img src="https://i.imgur.com/Sx6F4aC.png" className="img-fluid" />
                <h5>92DK33K33</h5>
            </div>
        </div>
        <div className="row mt-3 justify-content-between">
            <div className="col-12 col-md-4">
                Delivery Hub Name: Mumbai
            </div>
            <div className="col-12 col-md-4 text-end">
                Delivery Person Name: Vishal Sharma
            </div>
        </div>

        <table className="table table-bordered border-dark border-2">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Sender Name & Address</th>
                <th>AWB #</th>
                <th>Date</th>
                <th>Time</th>
                <th>NDR Reason</th>
                <th>Receiver Signature/Company Seal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                    <h6>Mahnandera Singh Rathore</h6>
                    <p>House #23, Second Floor, Lagpat Nagar, Karol Bagh</p>
                    <p>Mob: 9145678993</p>
                </td>
                <td className="text-center">
                    <img src="https://i.imgur.com/Sx6F4aC.png" className="img-fluid" />
                    <h5>123123123</h5>
                    <h6>Preapid/COD</h6>
                    <h4>$98.39</h4>
                </td>
                <td></td>
                <td></td>
                <td>
                    <p>&#9634; Not avaiable</p>
                    <p>&#9634; Door Locked</p>
                    <p>&#9634; Strike</p>
                    <p>&#9634; Road blocked</p>
                    <p>&#9634; Weather conditions</p>
                    <p>&#9634; Unpaid transpotation fees</p>
                </td>
                <td>
                    <table>
                        <tr>
                            <td>Name:</td>
                            <td>____________________________</td>
                        </tr>
                        <tr>
                            <td>Relation:</td>
                            <td>____________________________</td>
                        </tr>
                        <tr>
                            <td>Phone No:</td>
                            <td>____________________________</td>
                        </tr>
                    </table>
                </td>
              </tr>
            </tbody>
        </table>

        <hr />
        <div className="mt-3">
            <div className="row">
                <div className="col-12 col-md">
                    <h6>Vehicle Reg No.</h6>
                    <p>MH01-SB-2384</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Start KM.</h6>
                    <p>-</p>
                </div>
                <div className="col-12 col-md">
                    <h6>End KM.</h6>
                    <p>-</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Total Shipments</h6>
                    <p>2</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Total COD Collection</h6>
                    <p>$90.90</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Received from</h6>
                    <p>-</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Approver Signature</h6>
                    <p>-</p>
                </div>
            </div>
        </div>


    </div>
);
}

export default BagPrint;