import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate } from 'react-router-dom';

function DriverAdd(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [Countrylist, setCountrylist] = useState([]);
    const [Citylist, setCitylist] = useState([]);
    const [Hublist, setHublist] = useState([]);
    const [Name, setName] = useState('');
    const [Hub, setHub] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Code, setCode] = useState('');
    const [Country, setCountry] = useState('');
    const [City, setCity] = useState('');
    const [Password, setPassword] = useState('');
    const [vehical, setvehical] = useState('');
    const [image, setimage] = useState('');
    const [licence, setlicence] = useState('');
    const [loading, setloading] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState('');

    useEffect(()=>{
        GetCountrylist();
        GetdefaultCountry();
    },[]);
    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const GetdefaultCountry=()=>
    {
        axios({
            method: 'post',
            url: Global.baseUrl+'defaultcountryname',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDefaultCountry(data);
                setCountry(data);
                GetCityList(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const handleCountryChange = (val) => {
        setCountry(val);
        GetCityList(val);
    };
    const handleCityChange = (val) => {
        setCity(val);
        GetHubList(val);
    };
    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const handleSubmit=()=>{
        if(Name==""){
            toast.warning('Name is Required');
        }
        else if(Phone==""){
            toast.warning('Mobile No. is Required');
        }
        else if(Email==""){
            toast.warning('Email is Required');
        }
        else if(Code==""){
            toast.warning('Code is Required');
        }
        else if(Country==""){
            toast.warning('Please Select Country');
        }
        else if(City==""){
            toast.warning('Please Select City');
        }
        else if(Hub==""){
            toast.warning('Hub is Required');
        }
        else if(Password==""){
            toast.warning('Password is Required');
        }
        else if(vehical==""){
            toast.warning('Vehical Number is Required');
        }
        // else if(image==""){
        //     toast.warning('Image is Required');
        // }
        // else if(licence==""){
        //     toast.warning('Licence is Required');
        // }
        else{
            setloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'DriverAdd',
                headers:headers2,
                data: {
                    Name:Name,
                    Phone:Phone,
                    Email:Email,
                    Code:Code,
                    Country:Country,
                    City:City,
                    Hub:Hub,
                    Password:Password,
                    Vehical:vehical,
                    Image:image,
                    Licence:licence
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/DriverList');
                      }, 1000);
                }else{
                    toast.warning(response.data.message);
                }
                setloading(false);
              })
              .catch(function (error) {
                setloading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    // logout();
                    // navigate('/');
                    // window.location.reload();
                }
              });
        }
    }
    return(
        <>
            <UserLeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                <section className="left_sub_menu">
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                            <i className="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Driver</li>
                                    </ol>
                                </nav>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                        <i className="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div className="ps-2 ">
                                        <h2 className="h4">Add Driver</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                            <h6>Add Driver</h6>
                                <div className="row">
                                    <div className="col-12 mb-3 mt-4">
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Name: <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Please Enter Name" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Mobile No.: <span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" onChange={(e)=>setPhone(e.target.value)} placeholder="Please Enter Mobile No." />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Email: <span className="text-danger">*</span></label>
                                                <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Please Enter Email" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Code: <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>setCode(e.target.value)} placeholder="Please Enter Code" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">Country: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>handleCountryChange(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    {Countrylist.map((list)=>{
                                                        return (
                                                            <option value={list.country} selected={list.country == defaultCountry}>{list.country}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">City: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>handleCityChange(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    {Citylist.map((list)=>{
                                                        return (
                                                            <option value={list.value}>{list.label}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <div><label for="email" className="form-label">Hub: <span className="text-danger">*</span></label></div>
                                                <select className="form-select" onChange={(e)=>setHub(e.target.value)}>
                                                    <option value="">-- Please Select --</option>
                                                    {Hublist.map((list)=>{
                                                        return (
                                                            <option value={list.value}>{list.label}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Password: <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Please enter password" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Vehicle Number: <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>setvehical(e.target.value)} placeholder="Please enter Vehical Number" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Image: <span className="text-danger"></span></label>
                                                <input type="file" className="form-control" onChange={(e)=>setimage(e.target.files[0])} placeholder="Please Select Image" />
                                            </div>
                                            <div className="col-4 mb-3">
                                                <label for="" className="form-label">Licence: <span className="text-danger"></span></label>
                                                <input type="file" className="form-control" onChange={(e)=>setlicence(e.target.files[0])} placeholder="Please Select Licence" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Submit</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
                <Footer/>
            </main>
        </>
    )
}
export default DriverAdd;