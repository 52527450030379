import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function EmailTemplateDetail()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const { TempId } =useParams();
    const[Subject,setSubject]=useState('');
    const[Message,setMessage]=useState('');
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    useEffect(()=>{
        FetchEmailTemplateDetail();
    },[]);
    function FetchEmailTemplateDetail(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchEmailTemplateDetail',
            headers:headers1,
            data: {
                id:TempId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setSubject(data.subject);
                setMessage(data.msg);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const inputHandler = (event, editor) => {
        setMessage(editor.getData());
    };
    
    const handleSubmit=(index)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateEmailTemplateDetail',
            headers:headers1,
            data: {
                "id":TempId,
                "msg":Message,
                "subject":Subject
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setSubloading(false);
            // console.log(error);
          });
    };
    return(
        <>
            <MasterLeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                <section className="left_sub_menu">
                <div className="row">
                    <div class="col-12 col-md">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div class="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li class="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i class="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <button class="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                                <i class="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div class="ps-2 ">
                                <h2 class="h4">Email Template Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div class="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>

                <div class="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div class="card-body">

                        <div class="row">
                            <div class="col-12  mb-3">
                                <label for="" class="form-label">Email Subject </label>
                                <input type="text" class="form-control" value={Subject} onChange={(e)=>setSubject(e.target.value)} id="" placeholder="Please enter Email Subject "/>
                            </div>
                            <div class="col-12  mb-3">
                                <label for="" class="form-label">Email Content</label>
                                {/* <textarea class="form-control" rows="5" id="comment" name="text" placeholder="Enter Content"></textarea> */}
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data={Message}
                                    onChange={inputHandler}
                                />
                            </div>
                        </div>
                        {Subloading==false?
                        <div className="d-grid col-md-2 mt-2">
                            <button onClick={handleSubmit} className="btn btn-primary mt-3">Update Settings</button>
                        </div>
                        :
                        <div className="d-grid col-md-2 mt-2">
                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                        </div>
                        }
                        
                    </div>
                }
                </div>

            </div>
                </div>
            </section>
                <Footer/>
            </main>
        </>
    )
}
export default EmailTemplateDetail;