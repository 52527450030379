import React, {useState, useEffect} from "react";
import UserLeftPanel from "../include/UserLeftPanel";
import Header from "../include/header";
import Footer from "../include/footer";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function CustomerPricing()
{
    const navigate = useNavigate();
    const {sid} = useParams();
    const {type} = useParams();
    const {UniqueId} = useParams();
    const [serivedetail, setSerivedetail] = useState('');
    const [zonelist, setZonelist] = useState([]);
    const [weightrangelist, setWeightrangelist] = useState([]);
    const [selectedzone, setSelectedzone] = useState([]);
    const [pricelist, setPricelist] = useState([]);
    const [additionalpricelist, setAdditionalpricelist] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [BtnLoading, setBtnLoading] = useState(false);
    

    useEffect(()=>{
        GetServicedetail(sid);
        GetWeightrangelist(sid);
    },[]);

    const GetServicedetail=(id)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'ServicedetailByuniqueid',
            headers:headers1,
            data: {
                uniqueid:id
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setSerivedetail(data);
                GetZonelist(data.booking_type);
            }
            else
            {
                setSerivedetail();
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    const GetWeightrangelist=(id)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Weightrangelistbyuniqueid',
            headers:headers1,
            data: {
                uniqueid:id
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setWeightrangelist(data);
            }
            else
            {
                setWeightrangelist();
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    const GetZonelist=(zonetype)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Zonelistbybookingtype',
            headers:headers1,
            data: {
                zonetype: zonetype
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setZonelist(data);
                setSelectedzone(data[0].id);
                GetZonepricelist(sid,type,data[0].id)

            }
            else
            {
                setZonelist();
                GetZonepricelist('','','')
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }


    const GetZonepricelist=(id,zonetype,formzone)=>{
        setSubloading(true);
        setSelectedzone(formzone);
        axios({
            method: 'post',
            url: Global.baseUrl+'Serviceprice',
            headers:headers1,
            data: {
                uniqueid: id,
                zonetype: zonetype,
                formzone: formzone,
                cust_id:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var normalprice=response.data.normalprice;
                var additionalprice=response.data.additionalprice;
                setPricelist(normalprice)
                setAdditionalpricelist(additionalprice)
            }
            else
            {
                setPricelist();
                setAdditionalpricelist();
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    const updatePrice = (index, newValue) => {
        const newList = [...pricelist];
        newList[index]['price'] = newValue;
        setPricelist(newList);
    };

    const updateadditionalPrice = (index, newValue) => {
        const newList = [...additionalpricelist];
        newList[index]['addi_range_price'] = newValue;
        setAdditionalpricelist(newList);
    };

    const submitprice=()=>{
        setBtnLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Servicepriceinsertorupdate',
            headers:headers1,
            data: {
                pricelist: pricelist,
                additionalpricelist: additionalpricelist,
                zone_id_form: selectedzone,
                serviceType: serivedetail.booking_type,
                cust_id:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
               toast.success(response.data.message);
               setTimeout(() => {
                navigate(`/CustomerServices/All/${UniqueId}`); 
            }, 1000);
            }
            else
            {
                toast.warn(response.data.message);
            }
            setBtnLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setBtnLoading(false);
            // console.log(error);
          });
          GetZonepricelist(sid,type,selectedzone)
    }

    
    
    return (
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Price Master</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={()=>navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">{serivedetail.services_name} <small className="text-muted">UID: {sid}</small></h2>
                        </div>
                    </div>
                </div>
                
                <div className="mb-2 mb-md-0">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <small className="text-muted">Type</small>
                            <p><span className="fw-normal"><span className="badge bg-warning">{serivedetail.product_type}</span></span></p>
                        </div>
                        <div className="col-12 col-md-6">
                            <small className="text-muted">Mode</small>
                            <p><span className="fw-normal"><span className="badge bg-primary">{serivedetail.booking_type}</span></span></p>
                        </div>
                    </div>
                </div>
            </div>


            

            <section className="data_table">

                <div className="row">
                    <div className="col-12 col-md">
                        <div className="custom_nav_data_table">
                            <ul className="nav nav-tabs">
                                {zonelist != undefined ?
                                <>
                                    {zonelist.map((zl,i)=>{
                                        return(
                                            <li className="nav-item" key={i}>
                                            <button className={selectedzone == zl.id? "nav-link active":"nav-link"} value={zl.id} onClick={(e)=>GetZonepricelist(sid,type,e.target.value)}>{zl.name}</button>
                                            </li>

                                        );
                                    })}
                                </>
                                :
                                ""
                                }
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="card card-body border-0 shadow ">
                    <div className="table-container" style={{minHeight:'100px'}}>
                {Subloading==true ? <ListLoader/>:
                        <table className="table table-hover table-bordered price_table">
                            <thead>
                                <tr>						
                                    <th className="border-gray-200">Weight Range <small>(KG)</small></th>
                                    {zonelist != undefined ?
                                    <>
                                    {zonelist.map((zl,i)=>{
                                        return(
                                            <th className="border-gray-200" key={i}><small>To</small> {zl.name}</th>
                                        );
                                    })}
                                    </>
                                    :
                                    ""
                                    }
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {weightrangelist != undefined ?
                                <>
                                    {weightrangelist.map((wl,index)=>{
                                        return(
                                        <tr key={index}>
                                            <td className=""><span className="fw-normal text-capitalize">{wl.start_range} ~ {wl.end_range}</span></td>
                                            {pricelist != undefined ?
                                                <>
                                                {pricelist.map((pl,i)=>{
                                                    if(pl.start_range == wl.start_range && pl.end_range == wl.end_range){
                                                    return(
                                                            <td key={i}><input type="number" className="form-control" placeholder="Enter Price" value={pl.price} onChange={(e)=>updatePrice(i,e.target.value)}/></td>
                                                            );
                                                        }
                                                })}
                                                </>
                                                :
                                                ""
                                                }
                                            
                                        </tr> 
                                
                                        );
                                    })}
                                </> 
                                : ""} 
                                {additionalpricelist != undefined ?
                                <tr>
                                <td className=""><span className="fw-normal text-capitalize">Addition {serivedetail.additional_weight_range}KG</span></td>
                                    
                                    {additionalpricelist.map((al,i)=>{
                                        return(
                                            <td key={i}><input type="number" className="form-control" id={al.zoneid} placeholder="Enter Price" value={al.addi_range_price} onChange={(e)=>updateadditionalPrice(i,e.target.value)} /></td>
                                        );
                                    })}
                                </tr> 
                                :
                                ""
                                }
                            </tbody>
                        </table>
                }
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-2">
                            <div className="d-grid">
                            </div>
                        </div>
                        {BtnLoading==false?
                        <div className="d-grid col-md-2 mt-2">
                            <button className="btn btn-primary btn-block" onClick={()=>submitprice()}>Update Pricing</button>
                        </div>
                        :
                        <div className="d-grid col-md-2 mt-2">
                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                        </div>
                        }
                    </div>
                </div>
            </section>
            </>}
            <Footer/>
        </main>
    </>
    );

}
export default CustomerPricing;