import React, {useState, useEffect} from "react";
import Header from '../include/header';
import Footer from '../include/footer';
import LeftPanel from '../include/leftpanel';
import Global from "../../APIConfig";
import  axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Select from 'react-select';
import { Button } from "react-bootstrap";

function CustomerScheduledPickupList(){
    let navigate = useNavigate();
    let {sp_no} = useParams(); 
    let {cust_id} = useParams(); 
    const [list, setList] = useState([]);
    const [Loading, setLoading] = useState(true); 
    const [SubLoading, setSubLoading] = useState(false); 
    const{headers1,headers2,logout}=AuthUser();
    const [checkedItems, setCheckedItems] = useState([]);
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [hublist, setHublist] = useState([]);
    const [routecodelist, setRoutecodelist] = useState([]);
    const [messengerlist, setMessengerlist] = useState([]);
    const [pickuplist, setPickuplist] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRouteCode, setSelectedRouteCode] = useState('');
    const [selectedMessenger, setSelectedMessenger] = useState('');
    const [selectedHub, setSelectedHub] = useState('');
    const [defaulcountryname, setDefaulcountryname] = useState('');
    const [BtnLoading, setBtnLoading] = useState(false);
    const [Btn1Loading, setBtn1Loading] = useState(false);
    const [userdata, setUserdata] = useState('');

    useEffect(()=>{
        GetCountrylist();
        GetShipmentList(sp_no,cust_id);
        const items = JSON.parse(localStorage.getItem('user'));
         setUserdata(items);
    },[]);

    const GetShipmentList=(sp_no,cust_id)=>{
        setSubLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'customerSchedulepickuplist',
            headers:headers1,
            data: {
                sp_id:sp_no,
                cust_id:cust_id
                
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setList(data);
                setLoading(false);
                setSubLoading(false);
            }
            else
            {
                setList();
                setLoading(false);
                setSubLoading(false);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const handleChange = (event) => {
        const slip_no = event.target.value;
        if(!checkedItems.includes(slip_no)){
            setCheckedItems([...checkedItems,slip_no]);
        }else{
            let arr=checkedItems.filter((item)=>item!==slip_no);
            setCheckedItems(arr);
        }
        
      };

      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = list.map(item => item.slip_no);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
   
    const isChecked = (slip_no) => checkedItems.includes(slip_no);

    const handleCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedCountry(event.target.value);
        GetCityList(countryname);
    };

    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            // console.log(error);
            setLoading(false);
          });
    }

    // Handle city selection
    const handleCityChange = (event) => {
        const cityid = event.value;
        setSelectedCity(event.value);
        GetHubList(cityid);
        GetRouteCodeList(cityid);
        GetMessengerList(cityid);
    };

    // Handle Hub selection
    const handleHubChange = (event) => {
        setSelectedHub(event.value);
    };

    // Handle Hub selection
    const handleRouteCodeChange = (event) => {
        setSelectedRouteCode(event.value);
    };

    // Handle Hub selection
    const handleMessengerChange = (event) => {
        setSelectedMessenger(event.value);
    };

    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetRouteCodeList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Routecodelist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setRoutecodelist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetMessengerList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Messengerlist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setMessengerlist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const UpdateSchedulepickup=()=>{
            axios({
                method: 'post',
                url: Global.baseUrl+'UpdateSchedulepickup',
                headers:headers1,
                data: {
                    sp_no:sp_no
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                   toast.success(response.data.message);
                }
                else
                {
                    toast.warn(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
    }

    const Submitpickup=()=>
        {
             if(selectedCountry =='')
             {
                 toast.warn('Please Select Country!');
             }
             else if(selectedCity =='')
             {
                 toast.warn('Please Select City!');
             }
             else if(selectedHub == '')
             {
                 toast.warn('Please Select Hub!');
             }
             else if(selectedRouteCode == '')
             {
                 toast.warn('Please Select Route Code!');
             }
             else if(selectedMessenger == '')
             {
                 toast.warn('Please Select Messenger!');
             }
             else if(checkedItems =='')
             {
                 toast.warn('Please Select At Least One Shipment!');
             }
             else
             {
                 setBtnLoading(true);
                 axios({
                     method: 'post',
                     url: Global.baseUrl+'GeneratePickup',
                     headers:headers1,
                     data: {
                         userid: userdata.id,
                         usertype: userdata.usertype,
                         slip_no: checkedItems,
                         messengerid: selectedMessenger,
                         routecode: selectedRouteCode,
                         cityid: selectedCity,
                         hub_id: selectedHub 
                     }
                   })
                   .then(function (response) {
                     if(response.data.status == 200)
                     {
                        UpdateSchedulepickup();
                         toast.success('Pickup Generate SuccessFully');
                         setTimeout(() => {
                             navigate('/PickupList/Running');
                           }, 1000);
                     }
                     else{
                         toast.success(response.data.error);
                     }
                     setBtnLoading(false);
                   })
                   .catch(function (error) {
                     setBtnLoading(false);
                     if(error.response.status==401){
                         toast.error(error.response.statusText);
                         logout();
                         navigate('/');
                         window.location.reload();
                     }
                     // console.log(error);
                   });
             }
         }

return(
    <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Schedule Shipments</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">All Schedule Shipments</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                    
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list,i)=>{
                            return (
                            <option value={list.country} selected={defaulcountryname == list.country} key={i}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select City: <span className="text-danger">*</span></label></div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleCityChange}
                        options={citylist} 
                        placeholder="Enter city name"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select HUB: <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/HubAdd`} tabIndex={-1} className="text-muted">Add New HUB</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleHubChange}
                        options={hublist} 
                        placeholder="Select Hub"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select City Route Code: <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/RouteAdd`} tabIndex={-1} className="text-muted">Add New Route Code</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleRouteCodeChange}
                        options={routecodelist} 
                        placeholder="Select Route Code"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select Messenger (Driver): <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/DriverAdd`} tabIndex={-1} className="text-muted">Add New Driver</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleMessengerChange}
                        options={messengerlist} 
                        placeholder="Select Messanger"
                        />
                    </div>
                    <div className="col-md-4"></div>
                </div>
              </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange} checked={checkedItems.length === list.length} />
                                </th>						
                                <th className="border-gray-200">AWB #</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Product/Type</th>
                                <th className="border-gray-200">Description</th>
                                <th className="border-gray-200">Type</th>
                                <th className="border-gray-200">qty</th>
                                <th className="border-gray-200">weight</th>
                                <th className="border-gray-200">DIMENSION</th>
                                <th className="border-gray-200">Sender Info</th>
                            </tr>
                        </thead>
                        {SubLoading==true? <tbody><tr><td colSpan={9}><ListLoader/></td></tr></tbody> : 
                            <tbody>
                                {list != undefined ? 
                                    <>
                                    {list.map((ship,index)=>{
                                        return(
                                            <tr key={index}>
                                            <td style={{"width": "20px"}}>
                                                <input className="form-check-input" type="checkbox" value={ship.slip_no} checked={isChecked(ship.slip_no)} onChange={handleChange} />
                                            </td>
                                            <td>
                                                <div>
                                                    <Link target="_blank" to={`/ShipmentDetails/${ship.slip_no}`} className="fw-bold">{ship.slip_no}</Link>
                                                   <CopyToClipboard text={ship.slip_no} onCopy={()=>{toast.success(ship.slip_no+' Copied To Clipboard')}}>
                                                       <i className="bx bx-copy"></i>
                                                   </CopyToClipboard>
                                                </div>
                                            </td>
                                            <td><span className="fw-normal text-capitalize"><small>{ship.shipment.entrydate}</small></span></td>
                                            <td><span className="fw-normal text-capitalize">{ship.shipment.nrd}</span></td>
                                            <td>
                                                <span className="fw-normal text-capitalize">{ship.shipment.status_describtion}</span>
                                                <div><small className="fw-normal text-capitalize">Value: ${ship.shipment.total_amt}</small></div>
                                            </td>
                                            <td>
                                                <span className="badge bg-warning text-dark">{ship.shipment.booking_mode}</span>
                                                {ship.booking_mode == 'COD' &&
                                                <div><span className="fw-normal text-capitalize">${ship.shipment.total_cod_amt}</span></div>
                                                }
                                            </td>
                                            <td>
                                                <span className="fw-normal text-capitalize">{ship.shipment.pieces}</span>
                                            </td>
                                            <td>
                                                <span className="fw-normal text-capitalize">{ship.shipment.volumetric_weight} KG</span>
                                            </td>
                                            <td>
                                                <span className="fw-normal text-capitalize">{ship.shipment.height}x{ship.shipment.width}x{ship.shipment.length}</span>
                                            </td>
                                            <td>
                                                <div><span className="fw-normal text-capitalize">{ship.shipment.sender_company_name}</span></div>
                                                <span className="fw-normal text-capitalize">{ship.shipment.sender_name}</span>
                                                <div>{ship.shipment.sender_address}, {ship.shipment.city}, {ship.shipment.country}</div>
                                            </td>
                                        </tr>
                                    );
                                })}   
                                    </>
                                :
                                    <tr>
                                        <td colSpan="9" className="text-center">Record Not Found</td>
                                    </tr>
                                }                    
                            </tbody>
                        }
                    </table>
                    {BtnLoading==false?
                        <div className="d-grid col-md-2 mt-2">
                            <Button onClick={Submitpickup} className="btn btn-primary">Generate Pickup Run Sheet</Button>
                        </div>
                        :
                        <div className="d-grid col-md-2 mt-2">
                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                        </div>
                        }
                </div>
            </section>

            {/* <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item">
                            <a className="page-link" href="#">Previous</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">1</a>
                        </li>
                        <li className="page-item active">
                            <a className="page-link" href="#">2</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">3</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">4</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">5</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div> */}
        
            
            </>
            }
            <Footer/>
        </main>
    </>
);
}
export default CustomerScheduledPickupList;