import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/leftpanel';
import { useNavigate } from 'react-router-dom';
function OperationalDashBoard() {
    let navigate = useNavigate();

    useEffect(() => {
        
      }, []);
      
  return (
    <>
        <LeftPanel/>
        <main className='home-section'>
        <Header/>
        <div className="overlay"></div>
        <div className='col-12 mt-4'><h2>Operational Dashboard</h2></div>
            <div class="row dash_state_box mt-4">
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Shipmentlist/All">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Shipment</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Shipmentlist/B">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Undelivered Shipment</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-package"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Shipmentlist/D">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Delivered Shipment</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-package"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Shipmentlist/C">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Cancel Shipment</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-package"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/PickupList/Generatepickup">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add PRS</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/PickupList/Running">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Runinng PRS</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/PickupList/Completed">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Complete PRS</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-check-double"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/BagList/AddBag">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Bag</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/BagList/Running">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Runinng Bag</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/BagList/Completed">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Complete Bag</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-check-double"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/BagManifestList/AddBagManifest">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Bag Manifest</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/BagManifestList/Running">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Runinng Bag Manifests</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/BagManifestList/Completed">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Complete Bag Manifests</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-check-double"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ShipmentManifestList/AddShipmentManifest">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Shipment Manifest</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ShipmentManifestList/Running">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Runinng Shipment Manifests</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/ShipmentManifestList/Completed">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Complete Shipment Manifests</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-check-double"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/DrsList/AddDrs">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add DRS</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/DrsList/Running">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Runinng DRS</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/DrsList/Completed">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Complete DRS</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-check-double"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/WarehouseScanner/Pickup">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Scan Pickup Shipments</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-collection"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/WarehouseScanner/Manifested_Bag">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Scan Manifested Bags</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-cuboid"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/WarehouseScanner/Manifested_Shipment">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Scan Manifeseted Shipments</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx bx-cube-alt"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/WarehouseScanner/Delivery">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Scan Delivery Shipments</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-run"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <Footer/>
            </main>
    </>
  );
}

export default OperationalDashBoard;