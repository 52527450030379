import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate,useParams,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLoader from "../Loaders/MainLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function BagManifestDetails(){
    let navigate = useNavigate();
    const { uniqueid } =useParams();
    const [manifestbagdetail, setManifestbagdetail] = useState([]);
    const [bags, setBags] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true); 
    
    useEffect(()=>{
        GetManifestBagDetail();
    },[]);
    const GetManifestBagDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetManifestBagDetail',
            headers:headers1,
            data: {
                uniqueid: uniqueid
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var manifestbagdata=response.data.value;
                var bagdata = response.data.value.Totalbags;
                setManifestbagdetail(manifestbagdata);
                setBags(bagdata);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Manifest Management</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Manifest #{uniqueid}</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="data_table">
                    {manifestbagdetail.arrived =='N'?
                        <span className="status running"> <i className="bx bx-time"></i> Running</span>
                        :
                        <span class="status completed"> <i class="bx bx-check-double"></i> Completed</span>
                        }
                    </div>
                    <div className="btn-group ms-2 ms-lg-3">
                        <button type="button" className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" aria-label="Print Bag" data-bs-original-title="Print PRS"><i className="bx bx-printer"></i> </button>
                        <button type="button" className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" aria-label="Update Status" data-bs-original-title="Download Barcode Lable"><i className="bx bx-barcode"></i> </button>
                    </div>
                </div>
            </div>

            <div className="card mb-3 shipment_detail_sho">
                <div className="card-body">
                    <h5>Manifest Details</h5>
                    <hr />
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <small className="text-muted">Date</small>
                            <p><strong>{manifestbagdetail.mdate}</strong></p>
                        </div>
                        <div className="col-12 col-md-4">
                            <small className="text-muted">Source</small>
                            <p><strong>{manifestbagdetail.bagsendercity}, {manifestbagdetail.bagsendercountry}</strong></p>
                        </div>
                        <div className="col-12 col-md-4">
                            <small className="text-muted">Destination</small>
                            <p><strong>{manifestbagdetail.bagreceivercity}, {manifestbagdetail.bagreceivercountry}</strong></p>
                        </div>
                        <div className="col-12 col-md-4">
                            <small className="text-muted">Total Bags</small>
                            <p><strong>{manifestbagdetail.TotalManifestbags}</strong></p>
                        </div>
                        <div className="col-12 col-md-4">
                            <small className="text-muted">Total Weight</small>
                            <p><strong>{manifestbagdetail.TotalManifestWeight}kg</strong></p>
                        </div>
                        <div className="col-12 col-md-4">
                            <small className="text-muted">Total Shipments</small>
                            <p><strong>{manifestbagdetail.TotalManifestshipment}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            
            

            <div className="row g-1">
                <div className="col-12 col-md-12">
                    <section className="data_table">
                        <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                            <table className="table table-hover">
                                <thead>
                                    <tr>				
                                        <th className="border-gray-200">Sr no.</th>
                                        <th className="border-gray-200">AWB#</th>
                                        <th className="border-gray-200">Bag#</th>
                                        <th className="border-gray-200">Origin</th>
                                        <th className="border-gray-200">Destination</th>
                                        <th className="border-gray-200">Sender</th>
                                        <th className="border-gray-200">Receiver</th>
                                        <th className="border-gray-200">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {bags.map((bag,index)=>{
                                    return(
                                        <tr key={index}>
                                        <td><span className="fw-normal text-capitalize">{index+1}</span></td>
                                        <td>
                                            <div>
                                                <Link target="_blank" to={`/ShipmentDetails/${bag.slip_no}`} className="fw-bold">{bag.slip_no}</Link>
                                                <CopyToClipboard text={bag.slip_no} onCopy={()=>{toast.success(bag.slip_no+' Copied To Clipboard')}}>
                                                    <i className="bx bx-copy"></i>
                                                </CopyToClipboard>
                                            </div>
                                            <small>{bag.mdate}</small>
                                        </td>
                                        <td>
                                            <div>
                                                <Link target="_blank" to={`/BagDetails/${bag.bag_id}`} className="fw-bold">{bag.bag_id}</Link>
                                                <CopyToClipboard text={bag.bag_id} onCopy={()=>{toast.success(bag.bag_id+' Copied To Clipboard')}}>
                                                    <i className="bx bx-copy"></i>
                                                </CopyToClipboard>
                                            </div>
                                        </td>
                                        <td><span className="fw-normal text-capitalize">{bag.sendercity}, {bag.sendercountry}</span></td>
                                        <td><span className="fw-normal text-capitalize">{bag.receivercity}, {bag.receivercountry}</span></td>
                                        <td><span className="fw-normal text-capitalize">{bag.sender_name}</span></td>
                                        <td><span className="fw-normal text-capitalize">{bag.reciever_name}</span></td>
                                        <td>{bag.manifested =='N' ?
                                        <span className="status running"> <i className="bx bx-time"></i> Running</span>
                                        :
                                            <span className="status completed"> <i className="bx bx-check-double"></i> Completed</span>
                                        }
                                        </td>
                                    </tr> 
                                    );
                                })}                   
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default BagManifestDetails;