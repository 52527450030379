import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";

function PincodeAdd() {
    const navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    

    useEffect(() => {
        GetCountrylist();
    }, []);

    const GetCountrylist = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'Countrylist',
            data: {
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setCountrylist(data);
                }
                setLoading(false);
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
                // console.log(error);
              });
    }

    const GetCityList = (val) => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'Citylist',
            data: {
                name: val
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setCitylist(data);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
    }

    const handleCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedCountry(event.target.value);
        GetCityList(countryname);
    };
   
    const handleSubmit = () => {
        if (selectedCountry == '') {
            toast.warn('Please Select Country');
        }
        else if (selectedCity == '') {
            toast.warn('Please Select City');
        }
        else if (pincode == '') {
            toast.warn('Please Enter Pin code');
        }
        else {
            setSubloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl + 'PincodeAdd',
                headers:headers1,
                data: {
                    pincode: pincode,
                    ctiyname: selectedCity,

                }
            })
                .then(function (response) {
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate(`/Pincodelist`);
                          }, 1000);
                    }
                    else {
                        if (response.data.message.pincode) {
                            toast.warn(response.data.message.pincode[0]);
                        }
                        else if (response.data.message.ctiyname) {
                            toast.warn(response.data.message.ctiyname[0]);
                        }
                        else {
                            toast.warn(response.data.message);
                        }

                    }
                    setSubloading(false);
                })
                .catch(function (error) {
                    if(error.response.status==401){
                        toast.error(error.response.statusText);
                        logout();
                        navigate('/');
                        window.location.reload();
                    }
                    setSubloading(false);
                    // console.log(error);
                  });
        }
    }

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading==true ? <MainLoader/> :
                <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Pincode</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Create Pincode</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="email" className="form-label">Select Country: <span className="text-danger">*</span></label></div>
                                        <select className="form-select" onChange={handleCountryChange}>
                                            <option value="">-- Please Select --</option>
                                            {countrylist.map((list) => {
                                                return (
                                                    <option value={list.country}>{list.country}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="email" className="form-label">Select City: <span className="text-danger">*</span></label></div>
                                        <select className="form-select" onChange={(e)=>setSelectedCity(e.target.value)}>
                                            <option value="">-- Please Select --</option>
                                            {citylist.map((list) => {
                                                return (
                                                    <option value={list.value}>{list.label}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="" className="form-label">Pincode code <small className="text-danger">*</small> </label>
                                            <input type="text" className="form-control" id="" placeholder="Please Enter Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                {Subloading==false?
                                <div className="d-grid col-md-2 mt-2">
                                    <button className="btn btn-primary" onClick={() => handleSubmit()}>Create Pincode</button>
                                </div>
                                :
                                <div className="d-grid col-md-2 mt-2">
                                    <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                </>}
                <Footer />
            </main>
        </>
    );
}
export default PincodeAdd;