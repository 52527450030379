import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link,useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import CopyToClipboard from 'react-copy-to-clipboard';


function AddBagManifest(){
    const navigate = useNavigate();
    const [defaultCountry, setDefaultCountry] = useState('');
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [hublist, setHublist] = useState([]);
    const [selectedFromCountry, setSelectedFromCountry] = useState('');
    const [selectedFromCity, setSelectedFromCity] = useState('');
    const [selectedToCountry, setSelectedToCountry] = useState('');
    const [selectedToCity, setSelectedToCity] = useState('');
    const [baglist, setBaglist] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedHub, setSelectedHub] = useState('');
    const [selectedOption, setSelectedOption] = useState('Domestic');
    const [selecteddate, setSelecteddate] = useState(getTodayDateString());
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);
    const [Btn1Loading, setBtn1Loading] = useState(false);
    const [transport, setTransport] = useState(false);
    const [transportname, setTransportname] = useState('');
    const [transportcharge, setTransportcharge] = useState('');
    const [transportvehicleno, setTransportvehicleno] = useState('');
    const [transportdrivername, setTransportdrivername] = useState('');
    const [transportdrivermobileno, setTransportdrivermobileno] = useState('');
    const [flightname, setFlightname] = useState('');
    const [flightno, setFlightno] = useState('');
    const [userdata, setUserdata] = useState('');
    
    useEffect(()=>{
        GetCountrylist();
        GetdefaultCountry();
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
         setUserdata(items);
        }
    },[]);

    const GetdefaultCountry=()=>
    {
        axios({
            method: 'post',
            url: Global.baseUrl+'defaultcountryname',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDefaultCountry(data);
                GetCityList(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }
    
    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            // console.log(error);
            setLoading(false);
          });
    }
    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }

   
     // Handle Hub selection
     const handleHubChange = (event) => {
        setSelectedHub(event.value);
    };

    const GenrateBagManifest=()=>{
        if(checkedItems =='')
        {
            toast.warn('Please Select At Least One Bag!');
        }
        else
        {
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'AddBagManifest',
                headers:headers1,
                data: {
                    usertype:userdata.usertype,
                    userid:userdata.id,
                    bag_id: checkedItems,
                    mfrom: selectedFromCity,
                    mto: selectedToCity,
                    mto_hub:selectedHub, 
                    mdate: selecteddate,
                    manifest_type:selectedOption,
                    transportname:transportname,
                    transportcharge:transportcharge,
                    transportvehicleno:transportvehicleno,
                    transportdrivername:transportdrivername,
                    transportdrivermobileno:transportdrivermobileno,
                    flightname:flightname,
                    flightno:flightno
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/BagManifestList/Running');
                      }, 1000);
                }
                else
                {
                    toast.error(response.data.message);
                }
                setBtnLoading(false);
            })
            .catch(function (error) {
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    setTimeout(() => {
                        navigate('/');
                        window.location.reload();
                      }, 1000);
                }
                // console.log(error);
              });
        }
    }

    // Function to handle Radio change
    const handleOptionChange = (event) => {
        const val = event.target.value;
        setSelectedOption(val);
        if(val == 'Domestic')
        {
            GetdefaultCountry();
        }
        else
        {
            setCitylist([]);
            setHublist([]);
        }
        setSelectedToCountry('');
        setSelectedFromCountry('');
        setSelectedFromCity('');
        setSelectedToCity('');
        setSelectedHub('');
      };

       // Handle From country selection
    const handleFromCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedFromCountry(event.target.value);
        GetCityList(countryname);
    };
     // Handle From city selection
     const handleFromCityChange = (event) => {
        setSelectedFromCity(event.value);
        
    };
      // Handle To country selection
      const handleToCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedToCountry(event.target.value);
        GetCityList(countryname);
    };
     // Handle To city selection
     const handleToCityChange = (event) => {
        const cityid = event.value;
        setSelectedToCity(event.value);
        GetHubList(cityid);
        // GetManifestBaglist()
    };

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }

    const GetBags=()=>{
        if(selectedFromCountry =='' && selectedOption =='International' && userdata.usertype !='M')
        {
            toast.warn('Please Select Form Country!')
        }
        else if(selectedFromCity =='')
        {
            toast.warn('Please Select Form City!')
        }
        else if(selectedToCountry =='' && selectedOption =='International')
        {
            toast.warn('Please Select To Country!')
        }
        else if(selectedToCity =='')
        {
            toast.warn('Please Select To City!')
        }
        else if(selectedHub =='')
        {
            toast.warn('Please Select Hub!')
        }
        else if(selecteddate =='')
        {
            toast.warn('Please Select Bag Date!')
        }
        else
        {
            setBtn1Loading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'GetManifestBaglist',
                headers:headers1,
                data: {
                    from: selectedFromCity,
                    to: selectedToCity,
                    usertype:userdata.usertype,
                    userid:userdata.id
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data.value;
                    setTransport(true);
                    setBaglist(data);
                }
                else
                {
                    setBaglist([]);
                }
                setBtn1Loading(false);
            })
            .catch(function (error) {
                setBtn1Loading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    const handleChange = (event) => {
        const uniqueid = event.target.value;
        if(!checkedItems.includes(uniqueid)){
            setCheckedItems([...checkedItems,uniqueid]);
        }else{
            let arr=checkedItems.filter((item)=>item!==uniqueid);
            setCheckedItems(arr);
        }
        
      };

      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = baglist.map(item => item.uniqueid);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
    const handlescan=(e)=>{
        const uniqueid = e.target.value;
        let tempShipment = baglist.map((ship) => {
            if (ship.uniqueid === uniqueid) {
                if (!checkedItems.includes(uniqueid)) {
                    setCheckedItems([...checkedItems, uniqueid]);
                }
            }
        });
    }
    
    const isChecked = (uniqueid) => checkedItems.includes(uniqueid);

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ?<MainLoader/>:
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <Link to={`/Admin-Dash`}>
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Manifest Management</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Generate Bag Manifest</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                        <div><label for="email" className="form-label">Mode: <span className="text-danger">*</span></label></div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Domestic" checked={selectedOption === 'Domestic'} onChange={handleOptionChange} />
                          <label className="form-check-label" for="inlineRadio1">Domestic</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="International" checked={selectedOption === 'International'} onChange={handleOptionChange} />
                          <label className="form-check-label" for="inlineRadio2">International</label>
                        </div>
                        {/* <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="HyperLocal" checked={selectedOption === 'HyperLocal'} onChange={handleOptionChange} />
                          <label className="form-check-label" for="inlineRadio3">Hyper Local</label>
                        </div> */}
                    </div>
                    {selectedOption !='Domestic' && userdata.usertype !='M' ?
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">From Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleFromCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list,ai)=>{
                            return (
                            <option value={list.country} selected={list.country == defaultCountry} key={ai}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    :
                    ""
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">From City: <span className="text-danger">*</span></label></div>
                        {userdata.usertype !='M' ?
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleFromCityChange}
                        options={citylist} 
                        placeholder="Enter city name"
                        />
                        :
                        <select className="form-select" onChange={(e)=>handleFromCityChange(e.target)}>
                            <option value="">-- Please Select --</option>
                            <option value={userdata.branch_location}>{userdata.cityname}</option>
                        </select>
                        }
                    </div>
                    {selectedOption !='Domestic' &&
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">To Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleToCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list,i)=>{
                            return (
                            <option value={list.country} selected={list.country == defaultCountry} key={i}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">To City: <span className="text-danger">*</span></label></div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleToCityChange}
                        options={citylist} 
                        placeholder="Enter city name"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select HUB: <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/HubAdd`} tabIndex={-1} className="text-muted">Add New HUB</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleHubChange}
                        options={hublist} 
                        placeholder="Select Hub"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Date: <span className="text-danger">*</span></label></div>
                        <input type="date" className="form-control" id="text" value={selecteddate} placeholder="Enter city name" onChange={(e)=>setSelecteddate(e.target.value)} />
                    </div>
                    <div className="col-md-12"></div>
                    {Btn1Loading==false?
                    <div className="d-grid col-md-2 mt-2">
                        <button onClick={()=>GetBags()} className="btn btn-primary">View Bags</button>
                    </div>
                    :
                    <div className="d-grid col-md-2 mt-2">
                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                    </div>
                    }
                </div>
              </div>
            </div>

            { transport == true &&
            <div className="card mb-3">
                <div className="card-body">
                  <label for="email" className="form-label">Scan Shipment #:</label>
                  <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                          <i className="bx bx-search"></i>
                      </span>
                      <input type="text" className="form-control" onChange={handlescan} placeholder="Scan Shipment Number" aria-label="Search" />
                  </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <label for="email" className="form-label">Transporter Detail #:</label>
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="Enter Transporter" aria-label="Transport Charge" value={transportname} onChange={(e)=>setTransportname(e.target.value)} />
                        </div>
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="Transport Charge" aria-label="Transport Charge" value={transportcharge} onChange={(e)=>setTransportcharge(e.target.value)} />
                        </div>
                        <div className="col-4 mt-3">
                            <input type="text" className="form-control" placeholder="Enter Vehicle No." aria-label="Enter Vehicle No." value={transportvehicleno} onChange={(e)=>setTransportvehicleno(e.target.value)} />
                        </div>
                        <div className="col-4 mt-3">
                            <input type="text" className="form-control" placeholder="Enter Driver Name" aria-label="Enter Driver Name" value={transportdrivername} onChange={(e)=>setTransportdrivername(e.target.value)} />
                        </div>
                        <div className="col-4 mt-3">
                            <input type="text" className="form-control" placeholder="Enter Driver Mobile No." aria-label="Enter Driver Mobile No." value={transportdrivermobileno} onChange={(e)=>setTransportdrivermobileno(e.target.value)} />
                        </div>
                        
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <label for="email" className="form-label">Flight Detail #:</label>
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="Enter Flight Company Name." aria-label="Enter Flight Company Name." value={flightname} onChange={(e)=>setFlightname(e.target.value)} />
                        </div>
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="Enter Flight No." aria-label="Enter Flight No." value={flightno} onChange={(e)=>setFlightno(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            }

            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                             <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                {baglist !=undefined ?
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={checkedItems.length === baglist.length}/>
                                :
                                ''
                                }
                                </th>						
                                <th className="border-gray-200">Bag #</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Origin</th>
                                <th className="border-gray-200">Destination</th>
                                <th className="border-gray-200">No. Of Shipments</th>
                                <th className="border-gray-200">Total Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                        {baglist.length>0 ?
                            <>
                           {baglist.map((bag,index) =>{
                                return(
                                <tr key={index}>
                                    <td style={{"width": "20px"}}>
                                        <input className="form-check-input" type="checkbox" id={index} value={bag.uniqueid} checked={isChecked(bag.uniqueid)} onChange={handleChange} />
                                    </td>
                                    <td>
                                        <div>
                                            <Link to={`/BagDetails/${bag.uniqueid}`} className="fw-bold">{bag.uniqueid}</Link>
                                            <CopyToClipboard text={bag.uniqueid} onCopy={()=>{toast.success(bag.uniqueid+' Copied To Clipboard')}}>
                                                <i className="bx bx-copy"></i>
                                            </CopyToClipboard>
                                        </div>
                                    </td>
                                    <td><span className="fw-normal text-capitalize"><small>{bag.mdate}</small></span></td>
                                    <td><span className="fw-normal text-capitalize">{bag.sendercity}, {bag.sendercountry}</span></td>
                                    <td>
                                        <span className="fw-normal text-capitalize">{bag.receivercity}, {bag.receivercountry}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal text-capitalize">{bag.total_awb_no}</span>
                                    </td>
                                    <td>
                                        <span className="fw-normal text-capitalize">{bag.ShipmentTotalweight} KG</span>
                                    </td>
                                </tr>
                                );
                            })} 
                        </> 
                        :
                        <tr>
                            <td colSpan="7" className="text-center text-danger">Record Not Found</td>
                        </tr>
                            }                      
                        </tbody>
                    </table>
                    
                </div>
            </section> 

            <div className="col-md-12"></div>
                {BtnLoading==false?
                <div className="d-grid col-md-2 mt-2">
                    <button onClick={()=>GenrateBagManifest()} className="btn btn-primary">Generate Manifest</button>
                </div>
                :
                <div className="d-grid col-md-2 mt-2">
                    <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                </div>
                }
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default AddBagManifest;