import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";
import QRCode from 'qrcode.react';

function ShipmentLabel() {
    let navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      };
    const query = useQuery();
    const slipno = query.get('slipno');
    const [list, setList] = useState([]);
    const { headers1, headers2, logout } = AuthUser();
    useEffect(() => {
        GetLabel();
    }, []);
    const GetLabel = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'ShipmentLabel',
            headers: headers1,
            data: {
                slip_no: slipno
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setList(data);
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
    }

    return (
        <>
            {list != undefined ? 
            <>
            {list.map((ship,index)=>{
                return(
                    <div className="container">
                        <div className="pagebreak" key={index}>
                            <div className="new_lable">
                                <div className="lable_head">{ship.systemcompanyname}</div>
                                <div className="">
                                    <div className="col-md-12 lable_shipment">
                                        <div className="row">
                                            <div className="col-md-7" style={{"border-right": "1px solid #cfcfcf", "margin-right": "0px"}}>
                                                <div className="row">
                                                    <div className="col-md-12 labelcol"><strong>To</strong> : </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.reciever_name}</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.reciever_phone}</span></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12"><span className="small">{ship.reciever_address}</span></div>
                                                    <div className="col-md-12"><span className="small">{ship.receiverstate},{ship.receivercountry},{ship.reciever_zip}</span></div>
                                                    <div className="col-md-12"><span className="small">{ship.receiver_company_name}</span></div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-md-12"><strong>Shipped From</strong> : </div>
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.sender_name}</span></div>
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.sender_phone}</span> </div>
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.sender_address}</span> </div>
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.senderstate},{ship.sendercountry},{ship.sender_zip}</span></div>
                                                    <div className="col-md-12 labelcol"><span className="small">{ship.sender_company_name}</span> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lable_shipment">
                                    <div className="row">
                                        <div className="col-md-6"> <strong>From :</strong> &ensp;&ensp; <span>{ship.sendercity}</span> </div>
                                        <div className="col-md-6"> <strong>To :</strong> &ensp;&ensp;<span>{ship.receivercity}</span> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 labelcol" style={{"padding-left": "14px;"}}> <strong>Size:</strong> <span>{ship.height}cm x {ship.width}cm x {ship.length}cm </span> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 labelcol" style={{"padding-left": "14px;"}}> <strong>Weight:</strong><span>{ship.volumetric_weight} {ship.weightType}</span> </div>
                                    </div>
                                </div>
                                <div className="lable_shipment">
                                    <div className="row">
                                        <div className="col-md-12 labelcol" style={{"padding-left": "14px;"}}> <strong>Product Type :</strong> <span>{ship.nrd}</span> </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"> <strong>Value</strong> :<span>{ship.declared_charge}</span></div>
                                        <div className="col-md-6"> <strong>Type</strong> :<span>{ship.customer_booking_mode}</span></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12padd_right" style={{"padding-left": "14px;"}}> <strong>Description</strong> :<span>{ship.status_describtion}</span></div>
                                    </div>
                                    <div className="lable_shipment">
                                        <div className="row">
                                            <div className="col-md-6"> <strong>Issued by</strong> : <span>Admin</span> </div>
                                            <div className="col-md-6"> <strong>Date</strong> : <span>{ship.entrydate}</span> </div>
                                        </div>
                                    </div>
                                    <div className="lable_shipment">
                                        <div className="row">
                                            <div className="col-md-8"> <strong>Tracking No</strong> : <span>{ship.slip_no}</span> </div>
                                        </div>
                                    </div>
                                    <div className="lable_head"> <strong>Service Type</strong> : <span>{ship.services_name}</span></div>
                                    <div className="lable_shipment">
                                        <div className="row">
                                            <div className="col-md-8 bar_code"> <Barcode value={ship.slip_no} /> </div>
                                            <div className="col-md-4"> <QRCode value={ship.slip_no} size={75} style={{"height":"70px","width":"65px","margin":"5px"}}/> </div>
                                        </div>
                                    </div>
                                    <div className="lable_shipment">
                                            <div className="col-md-12"><strong>No. Of Boxes</strong> : 1</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            </>
            :
            ""
            }
        </>
    );
}
export default ShipmentLabel;