import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";

function StateEdit() {
    const navigate = useNavigate();
    const {id} = useParams();
    const{headers1,headers2,logout}=AuthUser();
    const [countryname, setCountryname] = useState('');
    const [statename, setStatename] = useState('');
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);
    
    useEffect(() => {
        GetCountry(id);
    }, []);

    const GetCountry=(id)=>{
        axios({
            method: 'post',
            url: Global.baseUrl + 'LocationDetail',
            headers:headers1,
            data: {
                id:id
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    const data = response.data.value; 
                    setCountryname(data.country);
                    setStatename(data.state);
                }
                else 
                {
                    setCountryname('');
                    setStatename('');
                }
                setLoading(false);
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
                // console.log(error);
              });
    }

   
    const handleSubmit = () => {
        if (countryname == '') {
            toast.warn('Please Enter Country Name');
        }
        else if (statename == '') {
            toast.warn('Please Enter State Name');
        }
        else {
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl + 'StateEdit',
                headers:headers2,
                data: {
                    id:id,
                    countryname:countryname,
                    statename:statename
                }
            })
                .then(function (response) {
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate(`/Statelist/${countryname}`);
                          }, 1000);
                    }
                    else {
                        if (response.data.message.countryname) {
                            toast.warn(response.data.message.countryname[0]);
                        }
                        else if (response.data.message.statename) {
                            toast.warn(response.data.message.statename[0]);
                        }
                        else {
                            toast.warn(response.data.message);
                        }

                    }
                    setBtnLoading(false);
                })
                .catch(function (error) {
                    if(error.response.status==401){
                        toast.error(error.response.statusText);
                        logout();
                        navigate('/');
                        window.location.reload();
                    }
                    setBtnLoading(false);
                    // console.log(error);
                  });
        }
    }

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading==true?<MainLoader/>:<>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">State</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Update State</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="email" className="form-label">Country Name: <span className="text-danger">*</span></label></div>
                                        <input type="text" className="form-control" value={countryname} placeholder="Enter Country Name" disabled/>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="email" className="form-label">State Name: <span className="text-danger">*</span></label></div>
                                        <input type="text" onChange={(e)=>setStatename(e.target.value)} className="form-control" value={statename} placeholder="Enter State Name"/>
                                    </div>
                                </div>
                                {BtnLoading==false?
                                <div className="d-grid col-md-2 mt-2">
                                    <button className="btn btn-primary" onClick={() => handleSubmit()}>Update State</button>
                                </div>
                                :
                                <div className="d-grid col-md-2 mt-2">
                                    <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </>}
                <Footer />
            </main>
        </>
    );
}
export default StateEdit;