import React from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";

function Bulkshipmentimport()
{
    return(
        <>
            <LeftPanel/>
            <main className='home-section'>
                <Header/>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="#">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Shipments</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <a className="btn btn_nav btn-sm" href="shipments.php">
                                <i className="bx bx-arrow-back "></i>
                                </a>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Bulk Shipment Import</h2>
                            </div>
                        </div>
                    </div>
                
                    <div className="btn-toolbar mb-2 mb-md-0">
                        
                    </div>
                </div>

                <div className="mb-3">
                    <div className="alert alert-info" role="alert">
                        <h6>Notes:</h6>
                        <ul>
                            <li>Default booking mode will be credit customer.</li>
                            <li>Default number of pic is only 1</li>
                            <li>Not allow to add multiple shipment under one master AWB.</li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md bulk_import fileinput">
                        <div className="card">
                        <div className="card-body">
                            <label className="custom-file-upload">
                                <input type="file" />
                                <i className="fa fa-cloud-upload"></i> Browse and Upload
                            </label>
                            <p>Please download and use sample template file Only csv file format is acceptable</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card border-0 shadow-sm p-3">
                            <div className="card-body">
                            <h3><i className="fa fa-lightbulb-o" aria-hidden="true"></i></h3>
                            <ul>
                                <li>Download the sample file and replace its data with your order data.</li>
                                <li>Make sure all mandatory fields are filled. Save the file and upload it back.</li>
                            </ul>

                            <div className="d-grid">
                                <a href="https://SkyShipcdn.fniix.com/sample-template/bulk-order-template.csv" target="_blank" className="btn btn-primary btn-block">Download Sample File</a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card" >
                                <div className="card-body">
                                    <h5 className="card-title">Available Services (Domestic)</h5>
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Service</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Parcel</td>
                                            <td>
                                                <ul>
                                                    <li>Overnight Service</li>
                                                    <li>Express Service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Document</td>
                                            <td>
                                                <ul>
                                                    <li>Overnight Service</li>
                                                    <li>Express Service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card" >
                                <div className="card-body">
                                    <h5 className="card-title">Available Services (International)</h5>
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Service</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Parcel</td>
                                            <td>
                                                <ul>
                                                    <li>Overnight Service</li>
                                                    <li>Express Service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Document</td>
                                            <td>
                                                <ul>
                                                    <li>Overnight Service</li>
                                                    <li>Express Service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                <div className="card-body">
                    <h6>File Fields</h6>
                    <table className="table table-striped table-bordered table-hover">
                        <tbody><tr>
                        <td title="weight of parcel">(1) Sender unique_id (Customer Id)</td>
                        <td title="Type of Product Ex: Document, Parcel, pallate, Other">(2) Receiver Name </td>
                        <td title="define mode either COD or Paid">(3) Receiver Address</td>
                        </tr>
                        <tr>
                        <td title="sender City">(4) Receiver Zip</td>
                        <td title="Receiver City">(5) Receiver Mobile</td>
                        <td title="total number of piece">(6) Receiver Destination</td>
                        </tr>
                        <tr>
                        <td title="volumetric weight">(7) Receiver Email</td>
                        <td title="Pickup Time (mandatory Time)">(8) Product Type (Product type code)</td>
                        <td title="Pickup Date (mandatory Date)">(9) Service Name</td>
                        </tr>
                        <tr>
                        <td title="Status Ex: Booked or Picked up etc">(10) Weight</td>
                        <td title="define delivery date when your shipment is reached">(11) Height</td>
                        <td title="Name of sender">(12) Width</td>
                        </tr>
                        <tr>
                        <td title="Zipcode of sender">(13) Length</td>
                        <td title="Mobile of sender">(14) Parcel Value</td>
                        <td title="Email of sender">(15) COD Amount</td>
                        </tr>
                        <tr>
                        <td title="Address of sender">(16) Insurance Charge (Y, N)</td>
                        <td title="Name of Receiver">(17) COD Charge (Y, N):</td>
                        <td title="Zipcode of Receiver">(18) Custom Cleareance (Y, N):</td>
                        </tr>
                        <tr>
                        <td title="Address of Receiver">(19) Shipment Status (B)</td>
                        <td title="Address of Receiver">(20) Booking Type (0 for Domestic and 1 for International)</td>
                        <td title="Address of Receiver">(21) Origin</td>
                        </tr>
                        <tr>
                        <td title="Address of Receiver">(22) Destination</td>
                        <td title="Address of Receiver">(23) Bound Type</td>
                        <td title="Address of Receiver">(24) Commodity</td>
                        </tr>
                        </tbody></table>
                </div>
                </div>
                <Footer/>
            </main>
        </>
    )
}
export default Bulkshipmentimport;