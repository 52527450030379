import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { Link, useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import ListLoader from "../Loaders/ListLoader";

function PickupStatusUpdate(){
    let navigate = useNavigate();
    const { drsid } =useParams();
    const [pickuplist, setPickuplist] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true); 
    const [BtnLoading, setBtnLoading] = useState(false);
    const [userdata, setUserdata] = useState('');
    
    useEffect(()=>{
        GetPickupList();
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
         setUserdata(items);
        }
    },[]);

    const GetPickupList=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'PickuplistBydrsuniqueid',
            headers:headers1,
            data: {
                drs_unique_id: drsid
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var prsdata=response.data.value;
                setPickuplist(prsdata);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const Submitpick = ()=>{
        if (pickuplist.some(prs => prs.isChecked)) 
        {   
        setBtnLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Submitpickup',
            headers:headers1,
            data: {
                id:userdata.id,
                PickUpData:pickuplist
            }
            })
            .then(function (response) {
                if(response.data.status == 200){
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/PickupList/Running');
                      }, 1000);
                }
                else
                {
                    toast.warn(response.data.message);
                }
                setBtnLoading(false);
            })
            .catch(function (error) {
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
            } else {
                toast.warn("Please Select Atleast One Shipment!");
            }
    }

    const handleChange=(e)=>{
        const { value, checked } = e.target;
        if(value === 'allSelect')
        {
            let tempShipment = pickuplist.map((ship) => 
                { return {...ship, isChecked: checked};
            });
            setPickuplist(tempShipment);
        }
        else
        {
            let tempShipment = pickuplist.map((ship) => 
            ship.slip_no === value ?
            {...ship, isChecked: checked}: ship );
            setPickuplist(tempShipment);
        }
    }
    // const updateElement = (index) => {
    //     const newList = [...pickuplist];
    //     let newValue="";
    //     if(newList[index]['checked']=='Y'){
    //         newValue='N';
    //     }else{
    //         newValue='Y';
    //     }
    //     newList[index]['checked'] = newValue;
    //     setPickuplist(newList);
    // };
    const updateStatus = (index, newValue) => {
        const newList = [...pickuplist];
        newList[index]['pickup_status'] = newValue;
        setPickuplist(newList);
    };
    const updateReson = (index, newValue) => {
        const newList = [...pickuplist];
        newList[index]['resion'] = newValue;
        setPickuplist(newList);
    };
    const updateDate = (index, newValue) => {
        const newList = [...pickuplist];
        newList[index]['delay_date'] = newValue;
        setPickuplist(newList);
    };
    const updateReciever = (index, newValue) => {
        const newList = [...pickuplist];
        newList[index]['recieved_from'] = newValue;
        setPickuplist(newList);
    };

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Pick Run Sheet</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">PRS #{drsid}</h2>
                        </div>
                    </div>
                </div>
                
                {/* <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="table-settings shipment_list" id="">
                        <div className="row align-items-start g-2" >
                            <div className="col col-md ">
                                <div className="input-group ">
                                    <select className="form-select">
                                      <option>Any</option>
                                      <option>AWB #</option>
                                    </select>
                                    <input type="text" className="form-control w-50" placeholder="Search " />
                                    <span className="input-group-text">
                                        <i className="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                    <input className="form-check-input" type="checkbox" onChange={handleChange} value="allSelect" checked={pickuplist !=undefined ? pickuplist.filter((prs)=> prs?.isChecked !== true).length < 1: ''} />
                                </th>						
                                <th className="border-gray-200">Select To Pickup Report</th>
                                <th className="border-gray-200">AWB#</th>
                                <th className="border-gray-200">Product Type</th>
                                <th className="border-gray-200">Address</th>
                                <th className="border-gray-200">Mode</th>
                                <th className="border-gray-200">Sender</th>
                                <th className="border-gray-200">Receiver</th>
                                <th className="border-gray-200">Received from</th>
                            </tr>
                        </thead>
                        {Loading==true?<ListLoader/>:
                        <tbody>
                        {pickuplist.length>0 ?
                            <>
                            {pickuplist.map((prs, index)=>{ 
                                return (
                                        <tr key={index} style={prs.delivered =='Y'? {"pointer-events":"none","opacity": '0.7'}:{}}>
                                            <td style={{"width": "20px"}}>
                                                <input className="form-check-input" type="checkbox" value={prs.slip_no} checked={prs?.isChecked || false} onChange={handleChange}/>
                                            </td>
                                            <td className="">
                                                <div className="card">
                                                <div className="card-body">
                                                    <div className="form-check">
                                                        <input type="radio" name={`status_${index}`} checked={prs.pickup_status=='T' ? true : false} value="T" onChange={(e)=>updateStatus(index,e.target.value)}  className="form-check-input" id="" />Pickup Done
                                                        <label className="form-check-label" for="radio1"></label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name={`status_${index}`} checked={prs.pickup_status=='PRS'? true : false} value="PRS" onChange={(e)=>updateStatus(index,e.target.value)}  id=""  />Reschedule Pickup
                                                        <label className="form-check-label" for="radio2"></label>
                                                    </div>
                                                    <hr />
                                                    {prs.pickup_status=='PRS' &&
                                                        <div>
                                                            <div>
                                                                <label for="email" className="form-label">Reschedule Reason:</label>
                                                                <select className="form-select form-select-sm" onChange={(e)=>updateReson(index,e.target.value)}>
                                                                    <option value="Weather Condition">Weather Condition</option>
                                                                    <option value="Office Out Of Work">Office Out Of Work</option>
                                                                    <option value="Strick">Strick</option>
                                                                    <option value="Road Block">Road Block</option>
                                                                    <option value="UnPaid Transport Fees">UnPaid Transport Fees</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label for="email" className="form-label">Reschedule Date:</label>
                                                                <input type="date" className="form-control" value={prs.delay_date} onChange={(e)=>updateDate(index,e.target.value)} id="text" placeholder="Enter details namet" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                </div>
                                            </td>
                                            <td><span className="fw-normal text-capitalize"><Link target="_blank" to={`/ShipmentDetails/${prs.shipment_id}`}>{prs.shipment_id}</Link></span></td>
                                            <td><span className="fw-normal text-capitalize">{prs.nrd}</span></td>
                                            <td><span className="fw-normal text-capitalize">{prs.sender_address}, {prs.city}, {prs.country}</span></td>
                                            <td><span className="fw-normal text-capitalize">
                                                {prs.booking_mode === 'COD' ?
                                                    <span>
                                                        <span className="badge bg-secondary">{prs.booking_mode}</span>
                                                        <br />
                                                        {prs.total_cod_amt}
                                                    </span>
                                                    :
                                                    <span className="badge bg-primary">{prs.booking_mode}</span>
                                                }
                                                </span>
                                            </td>
                                            <td><span className="fw-normal text-capitalize">{prs.sender_name}</span></td>
                                            <td><span className="fw-normal text-capitalize">{prs.reciever_name}</span></td>
                                            <td>
                                            {prs.pickup_status=='T' &&
                                                <span className="fw-normal text-capitalize">
                                                <input type="text" className="form-control" value={prs.recieved_from} onChange={(e)=>updateReciever(index,e.target.value)} id="text" placeholder="Enter receiver name" />
                                            </span>
                                            }
                                            </td>
                                        </tr>
                                    );
                            }) }   
                        </>
                        :
                        <tr>
                            <td className="text-center text-danger">Record Not Found</td>
                        </tr>
                            }                    
                        </tbody>}
                    </table>
                    
                </div>
            </section>

                    {BtnLoading==false?
                    <div className="d-grid col-md-2 mt-2">
                        <button onClick={Submitpick} className="btn btn-primary">Update PRS</button>
                    </div>
                    :
                    <div className="d-grid col-md-2 mt-2">
                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                    </div>
                    }
            <Footer/>
        </main>
    </>
    );
}
export default PickupStatusUpdate;