import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/UserLeftPanel';
import { useNavigate } from 'react-router-dom';
function CustomerDashBoard() {
    let navigate = useNavigate();

    useEffect(() => {
        
      }, []);
      
  return (
    <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <div className="overlay"></div>
            <div className='col-12 mt-4'><h2>Customer Dashboard</h2></div>
            <div class="row dash_state_box mt-4">
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/CustomerAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Customer</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/CustomerList/All">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Customers</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/CustomerList/C">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Credit Customers</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/CustomerList/CN">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Non Credit Customers</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/CustomerList/U">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Contract Customers</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/HubAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Hub</h2>
                            <div class="card-body text-center">
                            <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/HubList">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Hubs</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-buildings"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/AgentList/AgentAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Agents</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/AgentList/All">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Agents</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user-pin"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/AgentList/C">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Credit Agents</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user-pin"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/AgentList/NC">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Non Credit Agents</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user-pin"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/AgentList/U">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Contract Agents</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-user-pin"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/StaffAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Staff</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/StaffList">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Staff</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/Privillages">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Staff Privillages</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/DriverAdd">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">Add Driver</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><svg class="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg></p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-xl-2">
                    <a href="/DriverList">
                        <div class="card mb-3 dash_state_box" style={{"max-width": "18rem"}}>
                            <h2 class="text-center mt-4">All Drivers</h2>
                            <div class="card-body text-center">
                                <p class="icon-shape icon-shape-default rounded me-4 me-sm-0"><i class="bx bx-time"></i></p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <Footer/>
        </main>
    </>
  );
}

export default CustomerDashBoard;