import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";


function Privillages(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [list, setList] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [SubLoading, setSubLoading] = useState(false);
    const [SearchVal, setSearchVal] = useState('');
    const [type, settype] = useState('');
    const [privillages, setprivillages] = useState([]);
    const [staff, setstaff] = useState([]);
    const [selectedStaff, setselectedStaff] = useState('');
    const [privillagecat, setprivillagecat] = useState([]);
    const [privillageArray, setprivillageArray] = useState([]);
    useEffect(()=>{
        getStaffList();
        getPrivilagecat();
    },[]);

    const getPrivilagecat=(val)=>{
        setSubLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchPrivillagecat',
            headers:headers1,
            data: {
                cat:val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setprivillagecat(data);
            }
            else
            {
                setprivillagecat([]);
            }
            setSubLoading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubLoading(false);
            setLoading(false);
            // console.log(error);
          });
    }

    const getPrivilage=(val)=>{
        setSubLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchPrivillage',
            headers:headers1,
            data: {
                cat:val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setprivillages(data);
            }
            else
            {
                setprivillages([]);
            }
            setSubLoading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubLoading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    const getStaffList=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchStaff',
            headers:headers1,
            data: {
                SearchType:"",
                SearchVal:""
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setstaff(data);
            }
            else
            {
                setstaff();
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getStaffPrivillages=(id)=>{
        setSubLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchStaffPrivillage',
            headers:headers1,
            data: {
                id:id,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setprivillageArray(data.split(',').map(Number));
            }
            else
            {
                setprivillageArray([]);
            }
            setSubLoading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubLoading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    const handlePrivillage=(value)=>{
        if(selectedStaff==""){
            toast.warning('Please Select Staff To Access Privillage');
        }else{
            var newArray=""
            if (privillageArray.includes(value)) {
                    newArray = privillageArray.filter(e => e != value)
                setprivillageArray(newArray)
            } else {
                    newArray = [...privillageArray, value]
                setprivillageArray(newArray)
            }
            axios({
                method: 'post',
                url: Global.baseUrl+'UpdateStaffPrivillage',
                headers:headers1,
                data: {
                    staffId:selectedStaff,
                    privillages:newArray
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                }
                else
                {
                    toast.warning(response.data.message);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    // logout();
                    // navigate('/');
                    // window.location.reload();
                }
                // console.log(error);
            });
        }

    }
    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>:
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Privillages</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button">
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Set Staff Privillages</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md"></div>
                <div class="col-12 col-md-12">
                    <div class="table-settings shipment_list" id="">
                        <div class="row align-items-start g-2" >
                            <div class="col col-md ">
                                <label className="form-label">Select Staff :</label>
                                <div class="input-group ">
                                <select className="form-select" onChange={(e)=>{setselectedStaff(e.target.value);getStaffPrivillages(e.target.value)}}>
                                      <option value=''>--Please Select--</option>
                                      {staff!=undefined && staff.length>0 && staff.map((item)=>{
                                        return(
                                            <option value={item.unique_id}>{item.name}</option>
                                        );
                                    })} 

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="table-settings shipment_list" id="">
                        <div class="row align-items-start g-2" >
                            <div class="col col-md ">
                                <label className="form-label">Select Privillage Type :</label>
                                <div class="input-group ">
                                <select className="form-select" onChange={(e)=>{settype(e.target.value);getPrivilage(e.target.value)}}>
                                        <option value=''>--Please Select--</option>
                                        {privillagecat.map((pricat,i)=>{
                                            return(
                                            <option value={pricat.cat} key={i}>{pricat.cat}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="data_table">
                <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="border-gray-200">Sr No. #</th>
                                <th class="border-gray-200">Privillage Type</th>
                                <th class="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {SubLoading==true && <ListLoader/>}
                        {privillages!=undefined && privillages.length>0 && SubLoading==false ?
                        <tbody>
                            {privillages.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                <a href="#" class="fw-bold">{index+1}</a>
                                            </div>
                                        </td>
                                        <td><span class="fw-normal text-capitalize">{item.privilege_name}</span></td>
                                        <td>
                                            <div class="form-check form-switch  mb-2">
                                                <input className="form-check-input" onChange={()=>handlePrivillage(item.id)} checked={privillageArray!=undefined && privillageArray.length>0 && privillageArray.includes(item.id)} type="checkbox" />
                                            </div>
                                        </td>
                                    </tr>
                                    );
                                })} 
                               
                        </tbody>
                        :
                        <tbody><tr><td colSpan={8} className="text-center text-danger"> Record Not Found </td></tr></tbody>
                        }
                    </table>
                    
                </div>
            </section>
            </>
            }
            <Footer/>
        </main>
        </>

    );
}
export default Privillages;