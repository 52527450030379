import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import Select from 'react-select';
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function AddDrs(){
    let navigate = useNavigate();
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [hublist, setHublist] = useState([]);
    const [routecodelist, setRoutecodelist] = useState([]);
    const [messengerlist, setMessengerlist] = useState([]);
    const [drslist, setDRSlist] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRouteCode, setSelectedRouteCode] = useState('');
    const [selectedMessenger, setSelectedMessenger] = useState('');
    const [selectedHub, setSelectedHub] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);
    const [Btn1Loading, setBtn1Loading] = useState(false);
    const [userdata, setUserdata] = useState('');


    useEffect(()=>{
        GetCountrylist();
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
         setUserdata(items);
        }
    },[]);

    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            // console.log(error);
            setLoading(false);
          });
    }

    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }

    // Handle country selection
    const handleCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedCountry(event.target.value);
        GetCityList(countryname);
    };

    // Handle city selection
    const handleCityChange = (event) => {
        const cityid = event.value;
        setSelectedCity(event.value);
        GetHubList(cityid);
        GetRouteCodeList(cityid);
        GetMessengerList(cityid);
    };

    // Handle Hub selection
    const handleHubChange = (event) => {
        setSelectedHub(event.value);
    };

    // Handle Hub selection
    const handleRouteCodeChange = (event) => {
        setSelectedRouteCode(event.value);
    };

    // Handle Hub selection
    const handleMessengerChange = (event) => {
        setSelectedMessenger(event.value);
    };

    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }

    const GetRouteCodeList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Routecodelist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setRoutecodelist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }

    const GetMessengerList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Messengerlist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setMessengerlist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
    }

    const GetDRSList=(val)=>{
        if(selectedCountry =='' && userdata.usertype !='M')
        {
            toast.warn('Please Select Country!');
        }
        else if(selectedCity =='')
        {
            toast.warn('Please Select City!');
        }
        else if(selectedHub == '')
        {
            toast.warn('Please Select Hub!');
        }
        else if(selectedRouteCode == '')
        {
            toast.warn('Please Select Route Code!');
        }
        else if(selectedMessenger == '')
        {
            toast.warn('Please Select Messenger!');
        }
        else
        {
            setBtn1Loading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'DRSlistBycityid',
                headers:headers1,
                data: {
                    usertype:userdata.usertype,
                    barachlocation:userdata.branch_location,
                    city_id: val
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data.value;
                    setDRSlist(data);
                }
                else
                {
                    setDRSlist([]);
                }
                setBtn1Loading(false);
            })
            .catch(function (error) {
                setBtn1Loading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }
   const Submitdrs=()=>
   {
        if(selectedCountry =='' && userdata.usertype !='M')
        {
            toast.warn('Please Select Country!');
        }
        else if(selectedCity =='')
        {
            toast.warn('Please Select City!');
        }
        else if(selectedHub == '')
        {
            toast.warn('Please Select Hub!');
        }
        else if(selectedRouteCode == '')
        {
            toast.warn('Please Select Route Code!');
        }
        else if(selectedMessenger == '')
        {
            toast.warn('Please Select Messenger!');
        }
        else if(checkedItems =='')
        {
            toast.warn('Please Select At Least One Shipment!');
        }
        else
        {
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'GenerateDRS',
                headers:headers1,
                data: {
                    userid: userdata.id,
                    usertype: userdata.usertype,
                    slip_no: checkedItems,
                    messengerid: selectedMessenger,
                    routecode: selectedRouteCode,
                    cityid: selectedCity,
                    hub_id: selectedHub 
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    // navigate('/RunningPickupList');
                    setTimeout(() => {
                        navigate('/DrsList/Running');
                      }, 1000);
                }
                else{
                    toast.error(response.data.error);
                }
                setBtnLoading(false);
              })
              .catch(function (error) {
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    const handleChange = (event) => {
        const slipno = event.target.value;
        if(!checkedItems.includes(slipno)){
            setCheckedItems([...checkedItems,slipno]);
        }else{
            let arr=checkedItems.filter((item)=>item!==slipno);
            setCheckedItems(arr);
        }
        
      };

      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = drslist.map(item => item.slip_no);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
    const handlescan=(e)=>{
        const slip_no = e.target.value;
        let tempShipment = drslist.map((ship) => {
            if (ship.slip_no === slip_no) {
                if (!checkedItems.includes(slip_no)) {
                    setCheckedItems([...checkedItems, slip_no]);
                }
            }
        });
    }
    
    const isChecked = (slip_no) => checkedItems.includes(slip_no);

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            {Loading==true ?<MainLoader/>:
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Delivery Run Sheet</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Generate Delivery Run Sheet (DRS)</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                {userdata.usertype !='M' &&
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list)=>{
                            return (
                            <option value={list.country}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select City: <span className="text-danger">*</span></label></div>
                        {userdata.usertype !='M' ?
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleCityChange}
                        options={citylist} 
                        placeholder="Enter city name"
                        />
                        :
                        <select className="form-select" onChange={(e)=>handleCityChange(e.target)}>
                            <option value="">-- Please Select --</option>
                            <option value={userdata.branch_location}>{userdata.cityname}</option>
                        </select>
                        }
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select HUB: <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/HubAdd`} tabIndex={-1} className="text-muted">Add New HUB</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleHubChange}
                        options={hublist} 
                        placeholder="Select Hub"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select City Route Code: <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/RouteAdd`} tabIndex={-1} className="text-muted">Add New Route Code</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleRouteCodeChange}
                        options={routecodelist} 
                        placeholder="Select Route Code"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="d-flex justify-content-between">
                            <div ><label for="email" className="form-label">Select Messenger (Driver): <span className="text-danger">*</span></label> </div>
                            <div><small className=""><Link target="_blank" to={`/DriverAdd`} tabIndex={-1} className="text-muted">Add New Driver</Link></small></div>
                        </div>
                        <Select
                        // defaultValue={selectedOption} 
                        onChange={handleMessengerChange}
                        options={messengerlist} 
                        placeholder="Select Messanger"
                        />
                    </div>
                    {userdata.usertype =='M' &&
                    <div className="col-md-4"></div>
                    }
                    <div className="col-md-4"></div>
                    {Btn1Loading==false?
                    <div className="d-grid col-md-2 mt-2">
                        <button onClick={()=>GetDRSList(selectedCity)} className="btn btn-primary">View Shipment</button>
                    </div>
                    :
                    <div className="d-grid col-md-2 mt-2">
                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                    </div>
                    }
                </div>
              </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                  <label for="email" className="form-label">Scan AWB #:</label>
                  <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                          <i className="bx bx-search"></i>
                      </span>
                      <input type="text" className="form-control" onChange={handlescan} placeholder="Scan AWB Number" aria-label="Search" />
                  </div>
                </div>
              </div>

            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                {drslist !=undefined ?
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={checkedItems.length === drslist.length}/>
                                :
                                ''
                                }
                                </th>						
                                <th className="border-gray-200">AWB #</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Product/Type</th>
                                <th className="border-gray-200">Description</th>
                                <th className="border-gray-200">Type</th>
                                <th className="border-gray-200">qty</th>
                                <th className="border-gray-200">weight</th>
                                <th className="border-gray-200">DIMENSION</th>
                                <th className="border-gray-200">Sender Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drslist.length>0 ?
                             <>
                             {drslist.map((pic,index) =>{
                                 return(
                             <tr key={index}>
                                 <td style={{"width": "20px"}}>
                                    <input className="form-check-input" type="checkbox" id={index} value={pic.slip_no} checked={isChecked(pic.slip_no)} onChange={handleChange} />
                                 </td>
                                 <td>
                                     <div>
                                        <Link target="_blank" to={`/ShipmentDetails/${pic.slip_no}`} className="fw-bold">{pic.slip_no}</Link>
                                        <CopyToClipboard text={pic.slip_no} onCopy={()=>{toast.success(pic.slip_no+' Copied To Clipboard')}}>
                                            <i className="bx bx-copy"></i>
                                        </CopyToClipboard>
                                     </div>
                                 </td>
                                 <td><span className="fw-normal text-capitalize"><small>{pic.entrydate}</small></span></td>
                                 <td><span className="fw-normal text-capitalize">{pic.nrd}</span></td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{pic.status_describtion}</span>
                                     <div><small className="fw-normal text-capitalize">Value: ${pic.total_amt}</small></div>
                                 </td>
                                 <td>
                                     <span className="badge bg-warning text-dark">{pic.booking_mode}</span>
                                     {pic.booking_mode == 'COD' &&
                                     <div><span className="fw-normal text-capitalize">${pic.total_cod_amt}</span></div>
                                     }
                                 </td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{pic.pieces}</span>
                                 </td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{pic.volumetric_weight} KG</span>
                                 </td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{pic.height}x{pic.width}x{pic.length}</span>
                                 </td>
                                 <td>
                                     <div><span className="fw-normal text-capitalize">{pic.sender_company_name}</span></div>
                                     <span className="fw-normal text-capitalize">{pic.sender_name}</span>
                                     <div>{pic.sender_address}, {pic.city}, {pic.country}</div>
                                 </td>
                             </tr>
                                );
                             })} 
                         </>       
                        :
                        <tr>
                            <td colSpan="10" className="text-center text-danger">Record Not Found</td>
                        </tr>
                            }         
                        </tbody>
                    </table>
                    
                </div>
            </section> 

            {BtnLoading==false?
            <div className="d-grid col-md-2 mt-2">
                <Button onClick={Submitdrs} className="btn btn-primary">Generate Delivery Run Sheet</Button>
            </div>
            :
            <div className="d-grid col-md-2 mt-2">
                <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
            </div>
            }
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default AddDrs;