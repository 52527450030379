import React, {useState, useEffect} from "react";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Header from "../include/header";
import Footer from "../include/footer";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function ServiceOtherPricing()
{
    const navigate = useNavigate();
    const {sid} = useParams();
    const [serivedetail, setSerivedetail] = useState('');
    const [otherPricenamelist, setOtherPricenamelist] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [BtnLoading, setBtnLoading] = useState(false);
    

    useEffect(()=>{
        GetServicedetail(sid);
        Getotherpricelist(sid);
    },[]);

    const GetServicedetail=(id)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'ServicedetailByuniqueid',
            headers:headers1,
            data: {
                uniqueid:id
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setSerivedetail(data);
            }
            else
            {
                setSerivedetail();
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const Getotherpricelist=(id)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'ServiceOtherCharges',
            headers:headers1,
            data: {
                uniqueid:id
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var price=response.data.value;
                setOtherPricenamelist(price)
            }
            else
            {
                setOtherPricenamelist();
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    const updatePrice = (index, newValue) => {
        const newList = [...otherPricenamelist];
        newList[index]['price'] = newValue;
        setOtherPricenamelist(newList);
    };

    const updateotherPrice = (index, newValue) => {
        const newList = [...otherPricenamelist];
        newList[index]['other_price'] = newValue;
        setOtherPricenamelist(newList);
    };

    
    const submitprice=()=>{
        setBtnLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Serviceotherpriceinsertorupdate',
            headers:headers1,
            data: {
                otherPrice: otherPricenamelist
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
               toast.success(response.data.message);
               setTimeout(() => {
                    navigate('/ServiceList/All'); 
                }, 1000);
            }
            else
            {
                toast.warn(response.data.message);
            }
            setBtnLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setBtnLoading(false);
            // console.log(error);
          });
          Getotherpricelist(sid)
    }

    
    
    return (
        <>
        <MasterLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Price Master</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={()=>navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">{serivedetail.services_name} <small className="text-muted">UID: {sid}</small></h2>
                        </div>
                    </div>
                </div>
                
                <div className="mb-2 mb-md-0">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <small className="text-muted">Type</small>
                            <p><span className="fw-normal"><span className="badge bg-warning">{serivedetail.product_type}</span></span></p>
                        </div>
                        <div className="col-12 col-md-6">
                            <small className="text-muted">Mode</small>
                            <p><span className="fw-normal"><span className="badge bg-primary">{serivedetail.booking_type}</span></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow ">
                    <div className="table-container" style={{minHeight:'100px'}}>
                    {Subloading==true ? <ListLoader/> :
                        <table className="table table-hover table-bordered price_table">
                            <thead>
                                <tr>						
                                    <th className="border-gray-200">Other Charge</th>
                                    <th className="border-gray-200"><small>Price</small> </th>
                                    <th className="border-gray-200"><small>Price</small> </th>
                                        
                                </tr>
                            </thead>
                            <tbody>
                            
                                {otherPricenamelist != undefined ?
                                <>
                                    {otherPricenamelist.map((op,i)=>{
                                        return(
                                        <tr key={i}>
                                            <td className=""><span className="fw-normal text-capitalize">{op.charge_name}</span></td>
                                            <td><input type="number" className="form-control" placeholder="Enter Price" value={op.price} onChange={(e)=>updatePrice(i,e.target.value)}/></td>
                                            {op.charge_id == 5 || op.charge_id == 8 ?
                                            <td><input type="number" className="form-control" placeholder="Enter Price" value={op.other_price} onChange={(e)=>updateotherPrice(i,e.target.value)}/></td>
                                            :
                                            ""
                                            }
                                        </tr>
                                        );
                                    })}
                                </> 
                                : ""} 
                            </tbody>
                        </table>
                        }
                    </div>
                    
                    
                    
                </div>
            </section>
            <div className="row justify-content-end mt-3">
                {BtnLoading==false?
                <div className="d-grid col-md-2 mt-2">
                    <button className="btn btn-primary btn-block" onClick={()=>submitprice()}>Update Pricing</button>
                </div>
                :
                <div className="d-grid col-md-2 mt-2">
                    <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                </div>
                }
            </div>
            </>}
            <Footer/>
        </main>
    </>
    );

}
export default ServiceOtherPricing;