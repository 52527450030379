import React, { useState, useEffect } from "react";
import Global from '../../APIConfig';
import  axios from "axios";
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { Bar,Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ListLoader from "../Loaders/ListLoader";
Chart.register(...registerables)

function PickAndDelChart(){
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [label, setlabel] = useState('');
    const [pickup, setpickup] = useState('');
    const [deliverd, setdeliverd] = useState('');
    
    useEffect(()=>{
        Get30DaysShipmentDetail();
    },[]);
    
    const Get30DaysShipmentDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysPickAndDel',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setlabel(data.label);
                setpickup(data.pickup);
                setdeliverd(data.deliverd);
                
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const state = {
        labels: label,
        datasets: [
          {
            label: 'Pick Up',
            backgroundColor: '#fd7e14',
            borderColor:'#fd7e14',
            borderWidth: 2,
            data:pickup,
          },
          {
            label: 'Deliverd',
            backgroundColor: '#3f37c9',
            borderColor:'#3f37c9',
            borderWidth: 2,
            data:deliverd,
          },
        ],
      }
    return (
        <div>
            {Loading==true ? <ListLoader/> :
          <Line
            data={state}
            options={{
              title: {
                display: true,
                text: 'Class strength',
                fontSize: 20,
              },
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
          }
        </div>
      )
}

export default PickAndDelChart;
  
