import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function AccountSetting()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[PanNo,setPanNo]=useState('');
    const[VatNo,setVatNo]=useState('');
    const[DefaultTax,setDefaultTax]=useState('');
    const[DefaultTaxpre,setDefaultTaxpre]=useState('');
    const[MasterInvoiceNo,setMasterInvoiceNo]=useState('');
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchAccountData();
    },[]);
    function FetchAccountData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchAccountData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setPanNo(data.pan_num);
                setVatNo(data.gst_num);
                setDefaultTax(data.tax_type);
                setDefaultTaxpre(data.default_service_tax);
                setMasterInvoiceNo(data.default_invoice_number);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    function handleSubmit(){
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateAccountData',
            headers:headers1,
            data: {
                "PanNo":PanNo,
                "VatNo":VatNo,
                "DefaultTax":DefaultTax,
                "DefaultTaxpre":DefaultTaxpre,
                "MasterInvoiceNo":MasterInvoiceNo
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    return(
        <div className="col-12 col-md">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>

                <div className="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div className="card-body">
                    <h6>Account Settings</h6>
                        <div className="row">
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">PAN/Business Number</label>
                                <input type="text" value={PanNo} onChange={(e)=>setPanNo(e.target.value)} className="form-control" id="" placeholder="Please enter PAN/Business Number details" />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">VAT NUMBER</label>
                                <input type="text" value={VatNo} onChange={(e)=>setVatNo(e.target.value)} className="form-control" id="" placeholder="Please enter VAT certification number details" />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Default Tax Type eg. VAT, GST, Gov. Levy</label>
                                <input type="text" value={DefaultTax} onChange={(e)=>setDefaultTax(e.target.value)} className="form-control" id="" placeholder="Please enter Set Default Tax Type details" />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Default Tax In %</label>
                                <input type="text" value={DefaultTaxpre} onChange={(e)=>setDefaultTaxpre(e.target.value)} className="form-control" id="" placeholder="Please enter Set Default Tax In % details" />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Master Invoice Number</label>
                                <input type="text" value={MasterInvoiceNo} onChange={(e)=>setMasterInvoiceNo(e.target.value)} className="form-control" id="" placeholder="Please enter Set Master Invoice Number details" />
                            </div>
                        </div>
                        {Subloading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button onClick={handleSubmit} className="btn btn-primary">Update Settings</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                        
                    </div>
                }
                </div>
            </div>
    )
}
export default AccountSetting;