import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import FinanceLeftPanel from "../include/FinanceLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import Select from 'react-select';
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ShipmentReport(){
    let navigate = useNavigate();
    const [countrylist, setCountrylist] = useState([]);
    const [cityfromlist, setCityfromlist] = useState([]);
    const [citytolist, setCitytolist] = useState([]);
    const [shipmentlist, setShipmentlist] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [selectedfromCountry, setSelectedfromCountry] = useState('');
    const [selectedfromCity, setSelectedfromCity] = useState('');
    const [selectedtoCountry, setSelectedtoCountry] = useState('');
    const [selectedtoCity, setSelectedtoCity] = useState('');
    const [selectedfromdate, setSelectedfromdate] = useState('');
    const [selectedtodate, setSelectedtodate] = useState(getTodayDateString());
    const [selectedbookinmode,setSelectedbookinmode] = useState('');
    const [status,setStatus] = useState('');
    const [customerlist, setCustomerlist] = useState([]);
    const [selectedcustomer, setSelectedcustomer] = useState('');

    useEffect(()=>{
        GetCountrylist();
        Getcustomerlist();
    },[]);

    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }

    const GetCityfromList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            headers:headers1,
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCityfromlist(data);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }

    const Getcustomerlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'reportcustomerlist',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCustomerlist(data);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }

    const GetCitytoList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            headers:headers1,
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitytolist(data);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      
    
   const Submitdata=()=>
   {
        axios({
            method: 'post',
            url: Global.baseUrl+'shipmentlistbydata',
            headers:headers1,
            data: {
                Fromdate:selectedfromdate,
                Todate:selectedtodate,
                customerid:selectedcustomer,
                bookingmode:selectedbookinmode,
                status:status,
                fromcountry:selectedfromCountry,
                tocountry:selectedtoCountry,
                Origin:selectedfromCity,
                Destination:selectedtoCity,
            }
            })
            .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success(response.data.message);
                const data = response.data.value;
                setShipmentlist(data);
            }
            else{
                toast.warn(response.data.message);
                setShipmentlist([]);
            }
            })
            .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            });
    }

    const exportToExcel = () => {
        axios({
            method: 'post',
            url: Global.baseUrl+'shipmentlistbydata',
            headers:headers1,
            data: {
                Fromdate:selectedfromdate,
                Todate:selectedtodate,
                customerid:selectedcustomer,
                bookingmode:selectedbookinmode,
                status:status,
                fromcountry:selectedfromCountry,
                tocountry:selectedtoCountry,
                Origin:selectedfromCity,
                Destination:selectedtoCity,
            }
            })
            .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success("Excel Download Successfully");
                // Create a new workbook
                const wb = XLSX.utils.book_new();
            
                // Convert data to a worksheet
                const ws = XLSX.utils.json_to_sheet(response.data.excelvalue);
            
                // Append the worksheet to the workbook
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            
                // Generate a buffer for the workbook
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            
                // Create a Blob object from the buffer
                const blob = new Blob([wbout], { type: 'application/octet-stream' });
            
                // Save the file using file-saver
                saveAs(blob, 'Shipmentdata.xlsx');
            }
            else{
                toast.warn(response.data.message);
            }
            })
            .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            });
      };

    return(
        <>
        <FinanceLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Shipment Reports</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Shipment Reports</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">From Date: <span className="text-danger"></span></label></div>
                        <input type="date" className="form-control" id="text" value={selectedfromdate} placeholder="Enter city name" onChange={(e)=>setSelectedfromdate(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">To Date: <span className="text-danger"></span></label></div>
                        <input type="date" className="form-control" id="text" value={selectedtodate} placeholder="Enter city name" onChange={(e)=>setSelectedtodate(e.target.value)}/>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Customer: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setSelectedcustomer(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            {customerlist.map((cust,i)=>{
                                return(
                                <option value={cust.uniqueid} key={i}>{cust.name}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Booking Mode: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setSelectedbookinmode(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            <option value="CASH">CASH</option>
                            <option value="CREDIT">CREDIT</option>
                            <option value="COD">COD</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Select Status: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setStatus(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            <option value="B">Booked</option>
                            <option value="PP">Proceed For Pickup </option>
                            <option value="T">Pickup</option>
                            <option value="IT">In Transit</option>
                            <option value="D">Deliver</option>
                            <option value="T">Undeliverd</option>
                            <option value="DL">Reschedule</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">Select From Country: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>GetCityfromList(e.target.value)}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list)=>{
                            return (
                            <option value={list.country}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">Select From City: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setSelectedfromCity(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            {cityfromlist.map((list)=>{
                                return (
                                <option value={list.value}>{list.label}</option>
                                );
                                })}
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">Select To Country: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>GetCitytoList(e.target.value)}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list)=>{
                            return (
                            <option value={list.country}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div><label for="email" className="form-label">Select To City: <span className="text-danger"></span></label></div>
                        <select className="form-select" onChange={(e)=>setSelectedtoCity(e.target.value)}>
                            <option value="">-- Please Select --</option>
                            {citytolist.map((list)=>{
                                return (
                                <option value={list.value}>{list.label}</option>
                                );
                                })}
                        </select>
                    </div>
                    <div className="py-3">
                    <button onClick={()=>Submitdata()} className="btn btn-primary">Fetch Reports</button>
                    &nbsp;
                    <button onClick={exportToExcel} className="btn btn-primary">Export to Excel</button>
                    </div>
                </div>
              </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">AWB #</th>
                                <th className="border-gray-200">Origin</th>
                                <th className="border-gray-200">Destination</th>
                                <th className="border-gray-200">Booking Mode</th>
                                <th className="border-gray-200">Product/Type</th>
                                <th className="border-gray-200">Sender</th>
                                <th className="border-gray-200">Receiver</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shipmentlist.length>0 ?
                             <>
                             {shipmentlist.map((ship,index) =>{
                                 return(
                             <tr key={index}>
                                 <td>
                                     <div>
                                         <a href="/ShipmentDetails" className="fw-bold">{ship.slip_no}</a>
                                         <a href="" data-bs-toggle="tooltip" title="Copy"><i className="bx bx-copy"></i></a>
                                     </div>
                                 </td>
                                 <td><span className="fw-normal text-capitalize"><small>{ship.sender_address}</small></span><br />
                                    <span className="fw-normal text-capitalize"><small>{ship.sendercountry},{ship.sendercity},{ship.sender_zip}</small></span></td>
                                 <td><span className="fw-normal text-capitalize"><small>{ship.reciever_address}</small></span><br />
                                    <span className="fw-normal text-capitalize">{ship.receivercountry},{ship.receivercity},{ship.reciever_zip}</span></td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{ship.shipment_type}</span>
                                 </td>
                                 <td>
                                    <span>{ship.nrd}</span><br />
                                     <span className="badge bg-warning text-dark">{ship.customer_booking_mode}</span>
                                     {ship.booking_mode == 'COD' &&
                                     <div><span className="fw-normal text-capitalize">${ship.total_cod_amt}</span></div>
                                     }
                                 </td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{ship.sender_name}</span><br />
                                     <span className="fw-normal text-capitalize">{ship.sender_company_name}</span>
                                 </td>
                                 <td>
                                     <span className="fw-normal text-capitalize">{ship.reciever_name}</span><br />
                                     <span className="fw-normal text-capitalize">{ship.receiver_company_name}</span>
                                 </td>
                                 <td><span dangerouslySetInnerHTML={{__html:ship.shipmentstatus}}></span></td>
                                 <td>
                                     <span className="fw-normal text-capitalize">Price : {ship.total_amt}</span><br />
                                     <span className="fw-normal text-capitalize">Product qty : {ship.pieces}</span><br />
                                     <span className="fw-normal text-capitalize">Dimension : {ship.height}cm x{ship.width}cm x{ship.length}cm</span><br />
                                     <span className="fw-normal text-capitalize">Date : {ship.entrydate}</span><br />
                                 </td>
                             </tr>
                                );
                             })} 
                         </>       
                        :
                        <tr>
                            <td className="text-danger">Record Not Found</td>
                        </tr>
                            }         
                        </tbody>
                    </table>
                    
                </div>
            </section> 

            <Footer/>
        </main>
        </>

    );
}
export default ShipmentReport;