import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function PaymentGetwaySetting()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[Getways,setGetways]=useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    useEffect(()=>{
        FetchPaymentGetwayData();
    },[]);
    function FetchPaymentGetwayData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchPaymentGetwayData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setGetways(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const handleLogo = (index, newValue) => {
        const newList = [...Getways];
        newList[index]['logo'] = newValue;
        setGetways(newList);
    };
    const handleInput = (index, newValue,name) => {
        const newList = [...Getways];
        newList[index][name] = newValue;
        setGetways(newList);
    };
    const handleSubmit=(index)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdatePaymentGetwayData',
            headers:headers2,
            data: {
                "id":Getways[index].id,
                "Name":Getways[index].Name,
                "logo":Getways[index].logo,
                "status":Getways[index].status,
                "Live_merchant_email":Getways[index].Live_merchant_email,
                "Live_client_id":Getways[index].Live_client_id,
                "Live_secret_key":Getways[index].Live_secret_key,
                "Sandbox_merchant_email":Getways[index].Sandbox_merchant_email,
                "Sandbox_client_id":Getways[index].Sandbox_client_id,
                "Sandbox_secret_key":Getways[index].Sandbox_secret_key
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setSubloading(false);
            // console.log(error);
          });
    };
    return(
            <div className="col-12 col-md">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                <div className="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div className="card-body">
                    <h6>Payment Gateawy Settings</h6>
                        <div className="row">
                            <div className="col-12 payment_gateway_info_box mb-3">
                            {Getways.length>0 ?
                                <div className="accordion" id="payment_accordion">
                                    {Getways.map((item, index)=>{
                                        return(
                                            <div className="accordion-item" key={index}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Razorpay_logo.svg/800px-Razorpay_logo.svg.png" className="img-fluid" />
                                                    <span className="badge bg-success ms-3">{item.status}</span>
                                                    </button>
                                                </h2>
                                                <div id={`collapse${index}`} className="accordion-collapse collapse show" aria-labelledby={`heading${index}`} data-bs-parent="#payment_accordion">
                                                    <div className="accordion-body">
                                                        <div className="row">
                                                            <div className="col-12 col-md-2">
                                                                <label for="email">Logo</label>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <input type="file" onChange={(e)=>handleLogo(index,e.target.files[0])} className="form-control" id="text" placeholder="Enter details name" />
                                                            </div>
                                                            <div className="col-12 col-md-2">
                                                                <label for="email">Name</label>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <input type="text" value={item.name} onChange={(e)=>handleInput(index,e.target.value,'name')} className="form-control" id="text" placeholder="Enter name" />
                                                            </div>
                                                            <div className="col-12 col-md-2 mt-3">
                                                                <label for="email">Active Mode:</label>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-3">
                                                                <select className="form-select form-select-sm" onChange={(e)=>handleInput(index,e.target.value,'status')}>
                                                                    <option selected={item.status=='Live'?true:false} value="Live">Live</option>
                                                                    <option selected={item.status=='Sandbox'?true:false} value="Sandbox">Sandbox</option>
                                                                    <option selected={item.status=='Off'?true:false} value="Off">Off</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-12 col-md-6">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        Live Mode
                                                                    </div>
                                                                <div className="card-body">
                                                                    <div className="mb-3">
                                                                        <label for="email">Merchant_email</label>
                                                                        <input className="form-control" value={item.Live_merchant_email} onChange={(e)=>handleInput(index,e.target.value,'Live_merchant_email')} type="text" placeholder="Enter your Merchant_email" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label for="email">Client_id</label>
                                                                        <input className="form-control" value={item.Live_client_id} onChange={(e)=>handleInput(index,e.target.value,'Live_client_id')} type="text" placeholder="Enter your Client_id" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label for="email">Secrect_key</label>
                                                                        <input className="form-control" value={item.Live_secret_key} onChange={(e)=>handleInput(index,e.target.value,'Live_secret_key')} type="text" placeholder="Enter your Secrect_key" />
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        Sandbox Mode
                                                                    </div>
                                                                <div className="card-body">
                                                                    <div className="mb-3">
                                                                        <label for="email">Merchant_email</label>
                                                                        <input className="form-control" value={item.Sandbox_merchant_email} onChange={(e)=>handleInput(index,e.target.value,'Sandbox_merchant_email')} type="text" placeholder="Enter your Merchant_email" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label for="email">Client_id</label>
                                                                        <input className="form-control" value={item.Sandbox_client_id} onChange={(e)=>handleInput(index,e.target.value,'Sandbox_client_id')} type="text" placeholder="Enter your Client_id" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label for="email">Secrect_key</label>
                                                                        <input className="form-control" value={item.Sandbox_secret_key} onChange={(e)=>handleInput(index,e.target.value,'Sandbox_secret_key')} type="text" placeholder="Enter your Secrect_key" />
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {Subloading==false?
                                                        <div className="d-grid col-md-2 mt-2">
                                                            <button onClick={()=>handleSubmit(index)} className="btn btn-primary mt-3">Update Settings</button>
                                                        </div>
                                                        :
                                                        <div className="d-grid col-md-2 mt-2">
                                                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            :''
                            }
                            </div>
                        </div>
                    </div>
                }
                </div>

            </div>
    )
}
export default PaymentGetwaySetting;