import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";

function RouteEdit() {
    const navigate = useNavigate();
    const {id} = useParams();
    const{headers1,headers2,logout}=AuthUser();
    const [countrylist, setCountrylist] = useState([]);
    const [citylist, setCitylist] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [routeCode, setRouteCode] = useState('');
    const [routeName, setRouteName] = useState('');
    const [routetype, setRoutetype] = useState('');
    const [routedetail, setRoutedetail] = useState('');
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        GetRoutedetail(id);
        GetCountrylist();
    }, []);

    const GetRoutedetail = (id) => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'RouteDetail',
            headers:headers1,
            data: {
                id:id
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setRoutedetail(data);
                    GetCityList(data.countryname);
                    setRouteCode(data.routecode);
                    setRouteName(data.route);
                    setRoutetype(data.route_type);
                    setSelectedCountry(data.country_id);
                    setSelectedCity(data.city_id)
                }
                else
                {
                    setRoutedetail('');
                    GetCityList('');
                    setRouteCode('');
                    setRouteName('');
                    setRoutetype('');
                    setSelectedCountry('');
                    setSelectedCity('');
                }
                setLoading(false);
            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false);
              });
    }

    const GetCountrylist = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'Countrylist',
            data: {
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setCountrylist(data);
                }
            })
    }

    const GetCityList = (val) => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'Citylist',
            data: {
                name: val
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setCitylist(data);
                }
            })
    }

    const handleCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedCountry(event.target.value);
        GetCityList(countryname);
    };
   
    const handleSubmit = () => {
        setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl + 'EditRoute',
                headers:headers1,
                data: {
                    id:id,
                    routecode: routeCode,
                    route: routeName,
                    route_type:routetype,
                    countryname:selectedCountry,
                    city_id:selectedCity

                }
            })
                .then(function (response) {
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            navigate('/Routelist');
                          }, 1000);
                    }
                    else {
                        if (response.data.message.routecode) {
                            toast.warn(response.data.message.routecode[0]);
                        }
                        else if (response.data.message.route) {
                            toast.warn(response.data.message.route[0]);
                        }
                        else if (response.data.message.route_type) {
                            toast.warn(response.data.message.route_type[0]);
                        }
                        else if (response.data.message.country_id) {
                            toast.warn(response.data.message.country_id[0]);
                        }
                        else if (response.data.message.city_id) {
                            toast.warn(response.data.message.city_id[0]);
                        }
                        else {
                            toast.warn(response.data.message);
                        }

                    }
                    setBtnLoading(false);
                })
                .catch(function (error) {
                    if(error.response.status==401){
                        toast.error(error.response.statusText);
                        logout();
                        navigate('/');
                        window.location.reload();
                    }
                    setBtnLoading(false);
                    // console.log(error);
                  });
    }

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading==true?<MainLoader/>:<>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Route</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Update Route</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="email" className="form-label">Select Country: <span className="text-danger">*</span></label></div>
                                        <select className="form-select" onChange={handleCountryChange}>
                                            <option value="">-- Please Select --</option>
                                            {countrylist.map((list) => {
                                                return (
                                                    <option value={list.country} selected={routedetail.countryname == list.country?"selected":""}>{list.country}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="email" className="form-label">Select City: <span className="text-danger">*</span></label></div>
                                        <select className="form-select" onChange={(e)=>setSelectedCity(e.target.value)}>
                                            <option value="">-- Please Select --</option>
                                            {citylist.map((list) => {
                                                return (
                                                    <option value={list.value}  selected={routedetail.city_id == list.value?"selected":""}>{list.label}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="" className="form-label">Route code <small className="text-danger">*</small> </label>
                                            <input type="text" className="form-control" id="" placeholder="Please Enter Zone Name" value={routeCode} onChange={(e) => setRouteCode(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="" className="form-label">Route <small className="text-danger">*</small></label>
                                            <input type="text" className="form-control" id="" placeholder="Please Enter Zone Name" value={routeName} onChange={(e) => setRouteName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div><label for="" className="form-label">Route Type <small className="text-danger">*</small></label>
                                        <select className="form-control" onChange={(e)=>setRoutetype(e.target.value)}>
                                            <option value="">Please Select</option>
                                            <option value="Domestic" selected={routedetail.route_type == "Domestic" && "selected"}>Domestic</option>
                                            <option value="International" selected={routedetail.city_id == "International" && "selected"}>International</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                {BtnLoading==false?
                                <div className="d-grid col-md-2 mt-2">
                                    <button className="btn btn-primary" onClick={() => handleSubmit()}>Create Route</button>
                                </div>
                                :
                                <div className="d-grid col-md-2 mt-2">
                                    <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                </>}
                <Footer />
            </main>
        </>
    );
}
export default RouteEdit;