import React,{useState,useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function Pincodelist()
{
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    useEffect(()=>{
        GetPincodeList('')
    },[]);
    
    const GetPincodeList=(val)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Pincodelist',
            headers:headers1,
            data: {
                pincode: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
            }
            else
            {
                setList();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

const handledeletelocation=(id)=>{
    if (window.confirm('Do You  want to delete ?')) {
        setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'PincodeDelete',
        headers:headers1,
        data: {
            pincode: id
        }
    })
    .then(function (response) {
        if(response.data.status == 200)
        {
            toast.success(response.data.message);
        }
        else
        {
            toast.warn(response.data.message);
        }
        setSubloading(false);
        GetPincodeList('');
    })
    .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        // console.log(error);
      });
    }
    else
    {
        GetPincodeList('');
    }
}
const handleservicestatus=(id,val)=>{
    if (window.confirm('Do you want to proceed?')) {
    setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'PickupStatusupdate',
        headers:headers1,
        data: {
            id:id,
            status: val
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            GetPincodeList('');
            toast.success(response.data.message);
        }
        else
        {
            toast.warn(response.data.message);
        }
        setSubloading(false);
      })   
      .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        // console.log(error);
      });
    }
    else
    {
        GetPincodeList('');
    }
}

const handledeliverytatus=(id,val)=>{
    if (window.confirm('Do you want to proceed?')) {
    setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'DeliveredStatusupdate',
        headers:headers1,
        data: {
            id:id,
            status: val
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            GetPincodeList('');
            toast.success(response.data.message);
        }
        else
        {
            toast.warn(response.data.message);
        }
        setSubloading(false);
      })   
      .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        // console.log(error);
      });
    }
    else
    {
        GetPincodeList('');
    }
}

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading==true ? <MainLoader/> :
                <>
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Pincode</li>
                        </ol>
                    </nav>
                    <div class="d-flex">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Pincode List</h2>
                        </div>
                    </div>
                </div>
                
                <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="dropdown">
                        <button class="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div class="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <Link class="dropdown-item d-flex align-items-center" to={`/PincodeAdd`}>
                                <i class="bx bx-rocket text-primary-400 me-2 fs-5"></i>
                                Add New Pincode
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md">
                    <div class="custom_nav_data_table">
                       
                    </div>
                </div>
            
                <div class="col-12 col-md-4">
                    <div class="table-settings shipment_list" id="">
                        <div class="row align-items-start g-2" >
                            <div class="col col-md ">
                                <div class="input-group ">
                                    <input type="text" class="form-control w-50" placeholder="Search Pincode" onChange={(e)=>GetPincodeList(e.target.value)} />
                                    <span class="input-group-text">
                                        <i class="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <section class="data_table">
                <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table class="table table-hover">
                        <thead>
                            <tr>						
                                <th class="border-gray-200">#</th>
                                <th class="border-gray-200">City</th>
                                <th class="border-gray-200">Pincode</th>
                                <th class="border-gray-200">Pickup ON OFF</th>
                                <th class="border-gray-200">Delivery ON OFF</th>
                                <th class="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Subloading==true && <ListLoader/>}
                        {list != undefined && Subloading==false ?
                             <>
                             {list.map((pl,index)=>{
                                 return(
                                     <tr key={index}>
                                <td><span class="fw-normal text-capitalize">{index+1}</span></td>
                                <td><span class="fw-normal text-capitalize">{pl.city}</span></td>
                                <td><span class="fw-normal text-capitalize">{pl.pincode} </span></td>
                                <td>
                                    <div class="form-check form-switch  mb-2">
                                       <input class="form-check-input" type="checkbox" role="switch" checked={pl.pickup=='Y'? true : false} onChange={()=>{pl.pickup=='Y'? handleservicestatus(pl.id,'N'):handleservicestatus(pl.id,'Y')}} />
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check form-switch  mb-2">
                                       <input class="form-check-input" type="checkbox" role="switch" checked={pl.delivery=='Y'? true : false} onChange={()=>{pl.delivery=='Y'? handledeliverytatus(pl.id,'N'):handledeliverytatus(pl.id,'Y')}} />
                                    </div>
                                </td>
                                <td>
                                    <Link to={`/pincodeEdit/${pl.pincode}`} class="btn btn-gray-100">Edit Pincode</Link>
                                    <button class="btn btn-gray-100" onClick={()=>handledeletelocation(pl.pincode)}>Delete Pincode</button>
                                </td>
                            </tr> 
                            );
                        })}   
                    </>
                        :
                        <tr>
                           <td colSpan="4" className="text-center text-danger">Record Not Found</td>
                        </tr>
                       }                  
                        </tbody>
                    </table>
                    
                </div>
            </section>

            <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul class="pagination mb-0">
                        <li class="page-item">
                            <a class="page-link" href="#">Previous</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item active">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">4</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">5</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div>
            </>}
                <Footer />
            </main>
        </>
        );
}
export default Pincodelist;