import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function DatabaseBackup()
{
    let navigate = useNavigate();
    const[Backups,setBackups]=useState([]);
    const[AutoBackup,setAutoBackup]=useState('');
    const[Days,setDays]=useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    useEffect(()=>{
        FetchBackups();
        FetchAutoBackups();
    },[]);
    function FetchBackups(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchBackups',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setBackups(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    function FetchAutoBackups() {
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchAutoBackups',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setAutoBackup(data.auto_backup);
                setDays(data.database_backup);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            // console.log(error);
          });
    }
    function handleChange(){
        alert('change');
    }
    return(
        <div className="col-12 col-md">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                    <li className="breadcrumb-item">
                                        <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Settings</li>
                                </ol>
                            </nav>
                            <div className="d-flex justify-content-between">
                                <div className="">
                                    <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                    </button>
                                </div>
                                <div className="ps-2 ">
                                    <h2 className="h4">Settings</h2>
                                </div>
                            </div>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                        </div>
                    </div>

                    <div className="card" style={{minHeight:'150px'}}>
                    {Loading==true ? <ListLoader/> :
                        <div className="card-body">
                        
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-4">
                                <h6>Database Settings</h6>
                            </div>
                            <div className="col-12 col-md-4 text-end">
                                <a href="#" className="btn btn-primary">Create Backup Manually</a>
                            </div>
                        </div>
                            <div className="row">
                                <div className="col-3  mb-3">
                                    <label for="" className="form-label">Auto Backup</label>
                                </div>
                                <div className="col-9  mb-3">
                                    <div className="form-check form-switch  mb-2">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={AutoBackup=='Y'?true:false} onChange={()=>{handleChange('')}} />
                                    </div>
                                </div>
                                <div className="col-3  mb-3">
                                    <label for="" className="form-label">Backup Days Frequency</label>
                                </div>
                                <div className="col-6  mb-3">
                                    <input type="number" className="form-control" value={Days} placeholder="Please enter frequency in days" onChange={()=>{handleChange('')}} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12  mb-3">
                                    <h6>Available backedup database</h6>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>file name</th>
                                            <th>Actions</th>
                                            </tr>
                                        </thead>
                                        {Backups.length>0 ?
                                        <tbody>
                                        {Backups.map((item, index)=>{
                                            return(
                                                    <tr>
                                                        <td>{index}</td>
                                                        <td>{item.time} {item.date}</td>
                                                        <td><a href="">{item.backup}</a></td>
                                                        <td>
                                                            <a href="#" className="btn btn-outline-primary btn-sm">Download</a>
                                                            {/* <a href="#" className="btn btn-outline-primary btn-sm">Import Back</a> */}
                                                        </td>
                                                    </tr>
                                                );
                                        })}
                                        </tbody>
                                        :""
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    </div>

        </div>
    )
}
export default DatabaseBackup;