import React, { useState, useEffect } from "react";
import Global from '../../APIConfig';
import  axios from "axios";
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { Bar,Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ListLoader from "../Loaders/ListLoader";
Chart.register(...registerables)

function ShipmentDetailChart(){
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [shipmentsLast30Days, setShipmentsLast30Days] = useState('');
    const [shipmentsLast7Days, setShipmentsLast7Days] = useState('');
    const [todayshipments, setTodayshipments] = useState('');
    const [totalshipment, setTotalshipment] = useState('');
    const [pickpending, setpickpending] = useState('');
    const [intransit, setintransit] = useState('');
    const [deliverd, setdeliverd] = useState('');
    const [ndr, setndr] = useState('');
    const [rto, setrto] = useState('');
    useEffect(()=>{
        Get30DaysShipmentDetail();
    },[]);
    
    const Get30DaysShipmentDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysShipmentDetail',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setShipmentsLast30Days(data.shipmentsLast30Days);
                setShipmentsLast7Days(data.shipmentsLast7Days);
                setTodayshipments(data.todayshipments);
                setTotalshipment(data.totalshipment);
                setpickpending(data.pickpending);
                setintransit(data.intransit);
                setdeliverd(data.deliverd);
                setndr(data.ndr);
                setrto(data.rto);
            }
            else
            {
                setShipmentsLast30Days(0);
                setShipmentsLast7Days(0);
                setTodayshipments(0);
                setTotalshipment(0);
                setpickpending(0);
                setintransit(0);
                setdeliverd(0);
                setndr(0);
                setrto(0);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const state = {
        labels: ['Total Shipments','Pickup Pending','In-Transit','Deliverd','NDR Pending','RTO'],
        datasets: [
          {
            label: 'Shipment Status',
            backgroundColor: [
                '#3f37c9',
                '#FBA918',
                '#2361ce',
                '#dc3545',
                '#fd7e14',
                '#6610f2'
              ],
            borderColor:[
                '#3f37c9',
                '#FBA918',
                '#2361ce',
                '#dc3545',
                '#fd7e14',
                '#6610f2'
              ],
            borderWidth: 2,
            data:[totalshipment,pickpending,intransit,deliverd,ndr,rto],
          },
        ],
      }
    return (
      <>
            {Loading==true ? <ListLoader/> :
            <>
            <div class="col-12 col-md shipment_state ">
            <h4 class="mt-5">Shipment Bookings</h4>
            <div class="row">
                <div class="col-4 col-md">
                    <h1>{todayshipments}</h1>
                    <p>Today</p>
                </div>
                <div class="col-4 col-md">
                    <h1>{shipmentsLast7Days}</h1>
                    <p>Last 7 days</p>
                </div>
                <div class="col-4 col-md">
                    <h1>{shipmentsLast30Days}</h1>
                    <p>Last 30 days</p>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-8">
          <Bar
            data={state}
            options={{
              title: {
                display: true,
                text: 'Class strength',
                fontSize: 20,
              },
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
          </div>
          </>
          }
          </>
      )
}

export default ShipmentDetailChart;
  
