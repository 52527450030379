import Spinner from 'react-bootstrap/Spinner';

function SpinnerLoader() {
  return (
    <div className='LoaderContainer'>
        <Spinner animation="border" variant="primary" size='lg'/>
    </div>
  );
}

export default SpinnerLoader;