import React from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import { useNavigate } from "react-router-dom";

function WarehouseScannerDetails(){
    const navigate = useNavigate();
    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-6 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Warehouse Inbound Scanner</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Warehouse Inbound Scanner</h2>
                        </div>
                    </div>
                </div>
                
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>


            

            <div class="row menus_v">
                <div class="col-12 col-md-3">
                    <a href="/WarehouseScannerPickup">
                        <div class="card">
                            <div class="card-body">
                                <h4><i class="bx bx-collection"></i></h4>
                                <h2>Scan Pickup Shipments</h2>
                                <p>Arrival of shipments picked up by drivers or messengers into the warehouse</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-3">
                    <a href="">
                        <div class="card">
                            <div class="card-body">
                                <h4><i class="bx bx-cuboid"></i></h4>
                                <h2>Scan Bags</h2>
                                <p>Arrival of bags manifested by one location to another location into the warehouse</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-3">
                    <a href="">
                        <div class="card">
                            <div class="card-body">
                                <h4><i class="bx bx-cube-alt"></i></h4>
                                <h2>Scan Manifest Shipments</h2>
                                <p>Arrival of shipments manifested from one location to another location into the warehouse</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-3">
                    <a href="">
                        <div class="card">
                            <div class="card-body">
                                <h4><i class="bx bx-run"></i></h4>
                                <h2>Scan Delivery Shipments</h2>
                                <p>RTO or Rescheduled shipments by drivers or messengers into the warehouse</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <Footer/>
        </main>
        </>

    );
}
export default WarehouseScannerDetails;