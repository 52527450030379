import { useState, useEffect } from 'react';
import '../../ConsignmentNote.css';
import axios from "axios";
import Global from "../../APIConfig";
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";

function ConsignmentNote() {
  let navigate = useNavigate();
  const {slipno} = useParams();
  const [senderaccountno, setSenderaccountno] = useState('');
  const [sendercompanyname, setSendercompanyname] = useState('');
  const [sendercustomername, setSendercustomername] = useState('');
  const [sendermobilenumber, setSendermobilenumber] = useState('');
  const [senderemail, setSenderemail] = useState('');
  const [senderAddress, setSenderAddress] = useState('');
  const [senderPostalcode, setSenderPostalcode] = useState('');
  const [sendercountryname, setSendercountryname] = useState('');
  const [senderstate, setSenderstate] = useState('');
  const [sendercityName, setSendercityName] = useState('');
  const [receivercompanyname, setReceivercompanyname] = useState('');
  const [receivercustomername, setReceivercustomername] = useState('');
  const [receivermobilenumber, setReceivermobilenumber] = useState('');
  const [receiveremail, setReceiveremail] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [receiverPostalcode, setReceiverPostalcode] = useState('');
  const [receivercountryname, setReceivercountryname] = useState('');
  const [receiverstate, setReceiverstate] = useState('');
  const [receivercityName, setReceivercityName] = useState('');
  const [insurance, setInsurance] = useState('No');
  const {headers1,headers2,logout}=AuthUser();
  const [totalamt, setTotalamt] = useState('');
  const [gDefaultCurrency,setGDefaultCurrency] = useState('');
  const [shipmenttype,setShipmenttype] = useState('');
  const [shipmentservice,setShipmentservice] = useState([]);
  const [serviceid, setServiceid] = useState('');
  const [printlogo,setPrintlogo] = useState('');
    useEffect(() => {
      GetShipmentdata();
    }, []);
    const GetShipmentdata=()=>{
      axios({
          method: 'post',
          url: Global.baseUrl+'Shipmentdatabyslipno',
          headers:headers1,
          data: {
              slip_no: slipno
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
              var data=response.data.value;
              GetShipmentservice(data.shipment_type);
              setSenderaccountno(data.cust_id);
              setSendercompanyname(data.sender_company_name);
              setSendercustomername(data.sender_name);
              setSendermobilenumber(data.sender_phone);
              setSenderemail(data.sender_email);
              setSenderAddress(data.sender_address);
              setSenderPostalcode(data.sender_zip);
              setSendercountryname(data.sendercountry);
              setSendercityName(data.sendercity);
              setReceivercompanyname(data.receiver_company_name);
              setReceivercustomername(data.reciever_name);
              setReceivermobilenumber(data.reciever_phone);
              setReceiveremail(data.reciever_email);
              setReceiverAddress(data.reciever_address);
              setReceiverPostalcode(data.reciever_zip);
              setReceivercountryname(data.receivercountry);
              setReceivercityName(data.receivercity);
              setTotalamt(data.total_amt);
              setGDefaultCurrency(data.gDefaultCurrency);
              setShipmenttype(data.shipment_type);
              setServiceid(data.service_id);
              setPrintlogo(data.printlogo);
              setSenderstate(data.senderstate);
              setReceiverstate(data.receiverstate);
              if(data.insurence_charge > 0)
              {
                  setInsurance('Yes');
              }
              else
              {
                  setInsurance('No');
              }
          }
        })
        .catch(function (error) {
          if(error.response.status==401){
              toast.error(error.response.statusText);
              logout();
              navigate('/');
              window.location.reload();
          }
          // console.log(error);
      });
    }

    const GetShipmentservice=(val)=>{
      axios({
          method: 'post',
          url: Global.baseUrl+'Shipmentservice',
          headers:headers1,
          data: {
            booking_type:val
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
              var data=response.data.value;
              setShipmentservice(data);
          }
        })
        .catch(function (error) {
          // console.log(error);
          // setLoading(false);
        });
  }
    return (
      <div className="container-fluid mt-3">
        <section className="mb-3 section_l">
          <div className="">
            <div className="row">
              <div className="col-6 col-md-6">
                <div className="row mb-2">
                    <div className="col-12 col-md-6">
                      <h4>1. Sender's Account Number</h4>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="box">
                        {senderaccountno}
                      </div>
                    </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12 col-md-12">
                    <h4>2. Customer Reference</h4>
                    <div className="box">
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <h4>3. From (Collection Address)</h4>
                  </div>
                  <div className="col-12 col-md-12">
                    Company Name: {sendercompanyname}
                  </div>
                  <div className="col-12 col-md-12">
                    Address: {senderAddress}
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        City: {sendercityName}
                      </div>
                      <div className="col-12 col-md-6">
                        Postal/Zip Code: {senderPostalcode}
                      </div>
                      <div className="col-12 col-md-6">
                        Province/Region: {senderstate}
                      </div>
                      <div className="col-12 col-md-6">
                        Country: {sendercountryname}
                      </div>
                      <div className="col-12 col-md-6">
                        Contact Name: {sendercustomername}
                      </div>
                      <div className="col-12 col-md-6">
                        Tel No.: {sendermobilenumber}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-12">
                    <h4>4. To (Receiver)</h4>
                  </div>
                  <div className="col-12 col-md-12">
                    Company Name: {receivercompanyname}
                  </div>
                  <div className="col-12 col-md-12">
                    Address: {receiverAddress}
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        City: {receivercityName}
                      </div>
                      <div className="col-12 col-md-6">
                        Postal/Zip Code: {receiverPostalcode}
                      </div>
                      <div className="col-12 col-md-6">
                        Province/Region: {receiverstate}
                      </div>
                      <div className="col-12 col-md-6">
                        Country: {receivercountryname}
                      </div>
                      <div className="col-12 col-md-6">
                        Contact Name: {receivercustomername}
                      </div>
                      <div className="col-12 col-md-6">
                        Tel No.: {receivermobilenumber}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-12">
                    <h4>5. To (Receiver)</h4>
                  </div>
                  <div className="col-12 col-md-12">
                    Company Name:
                  </div>
                  <div className="col-12 col-md-12">
                    Address:
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        City:
                      </div>
                      <div className="col-12 col-md-6">
                        Postal/Zip Code:
                      </div>
                      <div className="col-12 col-md-6">
                        Province/Region:
                      </div>
                      <div className="col-12 col-md-6">
                        Country:
                      </div>
                      <div className="col-12 col-md-6">
                        Contact Name:
                      </div>
                      <div className="col-12 col-md-6">
                        Tel No.:
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-12">
                    <h4>6. Dangerous goods</h4>
                    <div className="row">
                        <div className="col-12 col-md-9">
                          <strong>Does this shipment contain any dangerous goods?</strong>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="row g-2">
                              <div className="col-3">
                                Yes
                              </div>
                              <div className="col-3">
                                <div className="box_check"></div>
                              </div>
                              <div className="col-3">
                                No
                              </div>
                              <div className="col-3">
                                <div className="box_check"></div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <small>CARRIAGE OF THE CONSIGNMENT IS SUBJECT TO THE TERMS AND CONDITIONS ON THE GTC WEBSITE</small>
                <div className="row">
                    <div className="col-6 col-md-6">
                      <strong>Your Signature </strong>
                    </div>
                    <div className="col-6 col-md-6 border-start border-dark">
                      <strong>Received By</strong>
                    </div>
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div className="logo text-end">
                  <img src={`${Global.logoUrl}${printlogo}`} className="img-fluid" />
                </div>
                <div className="box quote_number mb-3">
                  <Barcode value={slipno} />
                  <br />
                  <small>Please quotethisNumber if you have an enquiry.</small>
                </div>
                <div className="row mb-5">
                  <div className="col-8 col-md-8">
                    <h4>7a. Services</h4>
                    <table className="table table-borderless table-sm">
                        <thead>
                          
                        </thead>
                        <tbody>
                          {shipmentservice.map((ser,i)=>{
                            return(
                            <tr key={i}>
                              <td>
                                <h2>{ser.services_name}</h2>
                              </td>
                              <td>
                                <div className="box_check">
                                  {ser.unique_id == serviceid &&
                                    <div className="check"></div>
                                  }
                                </div>
                              </td>
                              
                            </tr>
                            );
                          })}
                        </tbody>
                    </table>
                  </div>
                  <div className="col-4 col-md-4">
                    <h4>7b. Options</h4>
                    <div className="d-flex justify-content-between my-4">
                        <div className="">Insurance</div>
                        {insurance ==='No' ?
                        <div className="box_check"></div>
                        :
                        <div className="box_check"><div className="check"></div></div>
                        }
                    </div>
                    <div>
                      <small>Currency</small>
                      <div className="box">
                        {gDefaultCurrency}
                      </div>
                    </div>
                    <div>
                      <small>Value</small>
                      <div className="box">
                        {totalamt}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h4>8. Special Delivery Instructions</h4>
                  <div className="box_bottom"></div>
                </div>
                <div className="">
                  <h4>9. Goods descriptions</h4>
                  <table className="table table-bordered border-dark data_table table-sm">
                    <thead>
                        <tr>
                            <th rowspan="2">General descriptions</th>
                            <th rowspan="2" className="fs-sm">Number <br/>of<br/>Items</th>
                            <th colspan="2">Weight</th>
                            <th colspan="3">Dimension</th>
                        </tr>
                        <tr>
                            <th className="fs-sm">Killos</th>
                            <th className="fs-sm">Grams</th>
                            <th className="fs-sm">Height</th>
                            <th className="fs-sm">Width</th>
                            <th className="fs-sm">Length</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <th>&nbsp;</th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                      </tr>
                      <tr>
                          <th>&nbsp;</th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                      </tr>
                      <tr>
                          <th>&nbsp;</th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                      </tr>
                      <tr>
                          <th>&nbsp;</th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                      </tr>
                      <tr>
                          <th>
                            <div className="d-flex justify-content-between">
                                <div className="">
                                  OPS verfiy
                                </div>
                                <div><small>Total</small></div>
                            </div>
                          </th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td colspan="3"><small>Volume</small></td>
                      </tr>
                      <tr>
                          <th>&nbsp;</th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td colspan="3"><small>Volume Weight</small></td>
                      </tr>
                    </tbody>
                  </table>    
                </div>
                <div className="">
                  <h4>10. Dutiable Shipment Details</h4>
                  <small>Receiver's WAT/TVA/BTW/MWST No.</small>
                  <div className="box"></div>
                </div>
              </div>
            </div>      
          </div>
        </section>     
      </div>
    );
  };
export default ConsignmentNote;
