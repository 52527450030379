import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function AddBag(){
    const navigate = useNavigate();
    const [defaultCountry, setDefaultCountry] = useState('');
    const [citylist, setCitylist] = useState([]);
    const [selectedCityfrom, setselectedCityfrom] = useState('');
    const [selectedCityto, setSelectedCityto] = useState('');
    const [selecteddate, setSelecteddate] = useState(getTodayDateString());
    const [shipemtlist, setShipemtlist] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Domestic');
    const [selectedToCountry, setSelectedToCountry] = useState('');
    const [selectedFromCountry, setSelectedFromCountry] = useState('');
    const [countrylist, setCountrylist] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);
    const [Btn1Loading, setBtn1Loading] = useState(false);
    const [userdata, setUserdata] = useState('');
    
    useEffect(()=>{
        GetCountrylist();
        GetdefaultCountry();
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
         setUserdata(items);
        }
    },[]);
    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const GetdefaultCountry=()=>
    {
        axios({
            method: 'post',
            url: Global.baseUrl+'defaultcountryname',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDefaultCountry(data);
;               setSelectedToCountry(data);
                setSelectedFromCountry(data);
                GetCityList(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
        });
    }

    // Handle To City selection
    const handletocityChange = (event) => {
        setSelectedCityto(event.value);
    };

    // Handle Form City selection
    const handleformcityChange = (event) => {
        setselectedCityfrom(event.value);
    };

    // Function to handle Radio change
    const handleOptionChange = (event) => {
        const val = event.target.value;
        setSelectedOption(val);
        if(val == 'Domestic')
        {
            GetdefaultCountry();
        }
        else
        {
            GetdefaultCountry();
            setCitylist([]);
        }
        // setSelectedToCountry('');
        // setSelectedFromCountry('');
        setselectedCityfrom('');
        setSelectedCityto('');
        
      };

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const GetShipment=()=>{
        if(selectedFromCountry =='' && selectedOption =='International')
        {
            toast.warn('Please Select Form Country!')
        }
        else if(selectedCityfrom =='')
        {
            toast.warn('Please Select Form City!')
        }
        else if(selectedToCountry =='' && selectedOption =='International')
        {
            toast.warn('Please Select To Country!')
        }
        else if(selectedCityto =='')
        {
            toast.warn('Please Select To City!')
        }
        else if(selecteddate =='')
        {
            toast.warn('Please Select Bag Date!')
        }
        else
        {
            setBtn1Loading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'GetBagShipments',
                headers:headers1,
                data: {
                    type: selectedOption,
                    form: selectedCityfrom,
                    usertype:userdata.usertype,
                    userid:userdata.id
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data.value;
                    setShipemtlist(data);
                }
                setBtn1Loading(false);
            })
            .catch(function (error) {
                setBtn1Loading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    const GenrateBag=()=>{
        if(checkedItems =='')
        {
            toast.warn('Please Select At Least One Shipment!');
        }
        else
        {
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'BagAdd',
                headers:headers1,
                data: {
                    usertype:userdata.usertype,
                    userid:userdata.id,
                    slip_no: shipemtlist,
                    form: selectedCityfrom,
                    to: selectedCityto,
                    bagdate: selecteddate,
                    hubid:userdata.id
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/BagList/Running');
                      }, 1000);
                }
                else
                {
                    toast.error(response.data.message);
                }
                setBtnLoading(false);
            })
            .catch(function (error) {
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

     // Handle To country selection
     const handleToCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedToCountry(event.target.value);
        GetCityList(countryname);
        setSelectedCityto('');
    };

    // Handle From country selection
    const handleFromCountryChange = (event) => {
        const countryname = event.target.value;
        setSelectedFromCountry(event.target.value);
        GetCityList(countryname);
        setselectedCityfrom('');
        
    };

    const handleChange = (event) => {
        const slipno = event.target.value;
        if(!checkedItems.includes(slipno)){
            setCheckedItems([...checkedItems,slipno]);
        }else{
            let arr=checkedItems.filter((item)=>item!==slipno);
            setCheckedItems(arr);
        }
        
      };
      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = shipemtlist.map(item => item.slip_no);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
    const handlescan=(e)=>{
        const slipno = e.target.value;
        let tempShipment = shipemtlist.map((ship) => {
            if (ship.slip_no === slipno) {
                if (!checkedItems.includes(slipno)) {
                    setCheckedItems([...checkedItems, slipno]);
                }
            }
        });
    }
    
    const isChecked = (slip_no) => checkedItems.includes(slip_no);
    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>
                :
                <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Bag Management</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Generate Bag</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                <div className="col-12 col-md-12 mb-3">
                        <div><label for="email" className="form-label">Mode: <span className="text-danger">*</span></label></div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Domestic" checked={selectedOption === 'Domestic'} onChange={handleOptionChange} />
                          <label className="form-check-label" for="inlineRadio1">Domestic</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="International" checked={selectedOption === 'International'} onChange={handleOptionChange} />
                          <label className="form-check-label" for="inlineRadio2">International</label>
                        </div>
                        {/* <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="HyperLocal" checked={selectedOption === 'HyperLocal'} onChange={handleOptionChange} />
                          <label className="form-check-label" for="inlineRadio3">Hyper Local</label>
                        </div> */}
                    </div>
                    {selectedOption !='Domestic' && userdata.usertype !='M' ?
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">From Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleFromCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list,i)=>{
                            return (
                            <option value={list.country} selected={list.country == defaultCountry} key={i}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    :
                    ""
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Form City: <span className="text-danger">*</span></label></div>
                        {userdata.usertype !='M' ?
                        <Select
                        onChange={handleformcityChange}
                        options={citylist} 
                        placeholder="Enter city name"
                        />
                        :
                        <select className="form-select" onChange={(e)=>handleformcityChange(e.target)}>
                            <option value="">-- Please Select --</option>
                            <option value={userdata.branch_location}>{userdata.cityname}</option>
                        </select>
                        }
                    </div>
                    {selectedOption !='Domestic' &&
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">To Country: <span className="text-danger">*</span></label></div>
                        <select className="form-select" onChange={handleToCountryChange}>
                          <option value="">-- Please Select --</option>
                          {countrylist.map((list,ia)=>{
                            return (
                            <option value={list.country} selected={defaultCountry == list.country} key={ia}>{list.country}</option>
                            );
                            })}
                        </select>
                    </div>
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">To City: <span className="text-danger">*</span></label></div>
                        <Select
                        onChange={handletocityChange}
                        options={citylist} 
                        placeholder="Enter city name"
                        />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div><label for="email" className="form-label">Bag Date: <span className="text-danger">*</span></label></div>
                        <input type="date" className="form-control" id="text" value={selecteddate} placeholder="Enter city name" onChange={(e)=>setSelecteddate(e.target.value)}/>
                    </div>
                    <div className="col-md-12"></div>
                    {Btn1Loading==false?
                    <div className="d-grid col-md-2 mt-2">
                        <button onClick={()=>GetShipment()} className="btn btn-primary">View Shipment</button>
                    </div>
                    :
                    <div className="d-grid col-md-2 mt-2">
                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                    </div>
                    }
                </div>
              </div>
            </div>


            
            
            

            <div className="card mb-3">
                <div className="card-body">
                  <label for="email" className="form-label">Scan AWB #:</label>
                  <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                          <i className="bx bx-search"></i>
                      </span>
                      <input type="text" className="form-control" onChange={handlescan} placeholder="Scan AWB Number" aria-label="Search" />
                  </div>
                </div>
              </div>

            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                {shipemtlist !=undefined ?
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={checkedItems.length === shipemtlist.length}/>
                                :
                                ''
                                }
                                </th>						
                                <th className="border-gray-200">AWB #</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Product/Type</th>
                                <th className="border-gray-200">Description</th>
                                <th className="border-gray-200">Type</th>
                                <th className="border-gray-200">qty</th>
                                <th className="border-gray-200">weight</th>
                                <th className="border-gray-200">DIMENSION</th>
                                <th className="border-gray-200">Sender Info</th>
                            </tr>
                        </thead>
                        <tbody>
                        {shipemtlist.length>0 ?
                            <>
                            {shipemtlist.map((ship,index) =>{
                                return(
                            <tr key={index}>
                                <td style={{"width": "20px"}}>
                                    <input className="form-check-input" checked={isChecked(ship.slip_no)} onChange={handleChange} type="checkbox" id={index} value={ship.slip_no} />
                                </td>
                                <td>
                                    <div>
                                        <a href="/ShipmentDetails" className="fw-bold">{ship.slip_no}</a>
                                        <CopyToClipboard text={ship.slip_no} onCopy={()=>{toast.success(ship.slip_no+' Copied To Clipboard')}}>
                                            <i className="bx bx-copy"></i>
                                        </CopyToClipboard>
                                    </div>
                                </td>
                                <td><span className="fw-normal text-capitalize"><small>{ship.entrydate}</small></span></td>
                                <td><span className="fw-normal text-capitalize">{ship.nrd} / {ship.shipment_type}</span></td>
                                <td>
                                    <span className="fw-normal text-capitalize">{ship.status_describtion}</span>
                                    <div><small className="fw-normal text-capitalize">Value: ${ship.total_amt}</small></div>
                                </td>
                                <td>
                                    <span className="badge bg-warning text-dark">{ship.booking_mode}</span>
                                    {ship.booking_mode == 'COD' &&
                                    <div><span className="fw-normal text-capitalize">${ship.total_cod_amt}</span></div>
                                    }
                                </td>
                                <td>
                                    <span className="fw-normal text-capitalize">{ship.pieces}</span>
                                </td>
                                <td>
                                    <span className="fw-normal text-capitalize">{ship.volumetric_weight} KG</span>
                                </td>
                                <td>
                                    <span className="fw-normal text-capitalize">{ship.height}x{ship.width}x{ship.length}</span>
                                </td>
                                <td>
                                    <div><span className="fw-normal text-capitalize">{ship.sender_company_name}</span></div>
                                    <span className="fw-normal text-capitalize">{ship.sender_name}</span>
                                    <div>{ship.sender_address}, {ship.city}, {ship.country}</div>
                                </td>
                            </tr>
                            );
                            })} 
                        </> 
                        :
                        <tr>
                            <td colSpan="10" className="text-center text-danger">Record Not Found</td>
                        </tr>
                            }                        
                        </tbody>
                    </table>
                    
                </div>
            </section> 
            <div className="col-md-12"></div>
            {BtnLoading==false?
            <div className="d-grid col-md-2 mt-2">
                <button onClick={()=>GenrateBag()} className="btn btn-primary">Generate Bag</button>
            </div>
            :
            <div className="d-grid col-md-2 mt-2">
                <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
            </div>
            }
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default AddBag;