import { useState, useEffect } from 'react';
import '../../ConsignmentNote.css';
import axios from "axios";
import Global from "../../APIConfig";
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";

function DrsPrint(){
    let navigate = useNavigate();
  const {slipno} = useParams();
  const [total_amt, setTotal_amt] = useState('');
  const [sendercustomername, setSendercustomername] = useState('');
  const [sendermobilenumber, setSendermobilenumber] = useState('');
  const [total_cod_amt, setTotal_cod_amt] = useState('');
  const [senderAddress, setSenderAddress] = useState('');
  const [senderPostalcode, setSenderPostalcode] = useState('');
  const [sendercountryname, setSendercountryname] = useState('');
  const [senderState, setSenderState] = useState('');
  const [sendercityName, setSendercityName] = useState('');
  const [drsdate, setDrsdate] = useState('');
  const [routecode, setRoutecode] = useState('No');
  const {headers1,headers2,logout}=AuthUser();
  const [gDefaultCurrency,setGDefaultCurrency] = useState('');
  const [hubname,setHubname] = useState('');
  const [messenger_name,setMessenger_name] = useState([]);
  const [vehicle_number, setVehicle_number] = useState('');
  const [printlogo,setPrintlogo] = useState('');
  const [booking_mode, setBooking_mode] = useState('');
  const [shipmentid, setShipmentid] = useState('');
    useEffect(() => {
      GetShipmentdata();
    }, []);
    const GetShipmentdata=()=>{
      axios({
          method: 'post',
          url: Global.baseUrl+'PrintDrs',
          headers:headers1,
          data: {
            drs_unique_id: slipno
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
              var data=response.data.value;
              setDrsdate(data.drs_date)
              setSendercustomername(data.sender_name);
              setSendermobilenumber(data.sender_phone);
              setSenderAddress(data.sender_address);
              setSenderPostalcode(data.sender_zip);
              setSendercountryname(data.country);
              setSenderState(data.state)
              setSendercityName(data.city);
              setGDefaultCurrency(data.gDefaultCurrency);
              setHubname(data.hub_name);
              setMessenger_name(data.messenger_name);
              setVehicle_number(data.vehicle_number);
              setPrintlogo(data.printlogo);
              setRoutecode(data.routecode);
              setTotal_cod_amt(data.total_cod_amt);
              setTotal_amt(data.total_amt);
              setBooking_mode(data.booking_mode);
              setShipmentid(data.slip_no);
          }
        })
        .catch(function (error) {
          if(error.response.status==401){
              toast.error(error.response.statusText);
              logout();
              navigate('/');
              window.location.reload();
          }
          // console.log(error);
      });
    }
return(
    <div className="container mt-3">
        <div className="row justify-content-between">
            <div className="col-12 col-md-3">
                <img src={`${Global.logoUrl}${printlogo}`} className="img-fluid" />
            </div>
            <div className="col-12 col-md text-center">
                <h2>Delivery Run Sheet</h2>
                <div className="d-flex justify-content-center">
                    <div className="p-1">Delivery Date: {drsdate}</div>
                    {/* <div className="p-1">HUB Code: MUMB01</div> */}
                    <div className="p-1">Route Code: {routecode}</div>
                </div>
            </div>
            <div className="col-12 col-md-3 text-end">
                <Barcode value={slipno} />
            </div>
        </div>
        <div className="row mt-3 justify-content-between">
            <div className="col-12 col-md-4">
                Delivery Hub Name: {hubname}
            </div>
            <div className="col-12 col-md-4 text-end">
                Delivery Person Name: {messenger_name}
            </div>
        </div>

        <table className="table table-bordered border-dark border-2">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Sender Name & Address</th>
                <th>AWB #</th>
                <th>Date</th>
                <th>Time</th>
                <th>NDR Reason</th>
                <th>Receiver Signature/Company Seal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                    <h6>{sendercustomername}</h6>
                    <p>{senderAddress}, {sendercityName}, {senderState}, {sendercountryname}</p>
                    <p>Mob: {sendermobilenumber}</p>
                </td>
                <td className="text-center">
                    <Barcode value={Number(shipmentid)} />
                    <h6>{booking_mode}</h6>
                    <h4>{gDefaultCurrency}{total_amt}</h4>
                </td>
                <td></td>
                <td></td>
                <td>
                    <p>&#9634; Not avaiable</p>
                    <p>&#9634; Door Locked</p>
                    <p>&#9634; Strike</p>
                    <p>&#9634; Road blocked</p>
                    <p>&#9634; Weather conditions</p>
                    <p>&#9634; Unpaid transpotation fees</p>
                </td>
                <td>
                    <table>
                        <tr>
                            <td>Name:</td>
                            <td>____________________________</td>
                        </tr>
                        <tr>
                            <td>Relation:</td>
                            <td>____________________________</td>
                        </tr>
                        <tr>
                            <td>Phone No:</td>
                            <td>____________________________</td>
                        </tr>
                    </table>
                </td>
              </tr>
            </tbody>
        </table>

        <hr />
        <div className="mt-3">
            <div className="row">
                <div className="col-12 col-md">
                    <h6>Vehicle Reg No.</h6>
                    <p>{vehicle_number}</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Start KM.</h6>
                    <p>-</p>
                </div>
                <div className="col-12 col-md">
                    <h6>End KM.</h6>
                    <p>-</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Total Shipments</h6>
                    <p>1</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Total COD Collection</h6>
                    <p>{gDefaultCurrency}{total_cod_amt}</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Received from</h6>
                    <p>-</p>
                </div>
                <div className="col-12 col-md">
                    <h6>Approver Signature</h6>
                    <p>-</p>
                </div>
            </div>
        </div>


    </div>
);
}

export default DrsPrint;