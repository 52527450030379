import React, { useState, useEffect } from "react";
import Global from '../../APIConfig';
import  axios from "axios";
import AuthUser from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { Bar,Line,Pie } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ListLoader from "../Loaders/ListLoader";
Chart.register(...registerables)

function TopCustChart(){
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [cust, setcust] = useState('');
    const [shipment, setshipment] = useState('');
    
    useEffect(()=>{
        Get30DaysShipmentDetail();
    },[]);
    
    const Get30DaysShipmentDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysTopCust',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setcust(data.cust);
                setshipment(data.shipments);
                
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }

    const state = {
        labels:cust,
        datasets: [
          {
            label: cust,
            backgroundColor: [
                '#3f37c9',
                '#FBA918',
                '#2361ce',
                '#dc3545',
                '#fd7e14',
                '#6610f2'
              ],
            borderColor:[
                '#3f37c9',
                '#FBA918',
                '#2361ce',
                '#dc3545',
                '#fd7e14',
                '#6610f2'
              ],
            borderWidth: 2,
            data:shipment,
          },
        ],
      }
    return (
        <div>
            {Loading==true ? <ListLoader/> :
          <Pie
            data={state}
            options={{
              title: {
                display: true,
                text: 'Class strength',
                fontSize: 20,
              },
              legend: {
                display: true,
                position: 'right',
              },
            }}
          />
          }
        </div>
      )
}

export default TopCustChart;
  
